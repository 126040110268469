import { lazy } from 'react';

import { Routes, Route } from 'react-router-dom';
const ProgramLayout = lazy(() => import('../../layouts/ProgramLayout'));
const ProgramInformation = lazy(() => import('../../modules/admin/program/ProgramInformation'));
const ProgramList = lazy(() => import('../../modules/admin/program/ProgramList'));
const ProgramDetails = lazy(() => import('../../modules/admin/program/ProgramDetails'));
const ProgramPricing = lazy(() => import('../../modules/admin/program/ProgramPricing'));
const ProgramAccessSettings = lazy(() => import('../../modules/admin/program/ProgramAccessSettings'));
const ProgramOverview = lazy(() => import('../../modules/admin/program/ProgramOverview'));
const Library = lazy(() => import('../../modules/admin/program/library'));
const CourseComponent = lazy(() => import('../../modules/admin/program/library/components/CourseComponent'));
const FormComponent = lazy(() => import('../../modules/admin/program/library/components/FormComponent'));
const EditFormComponent = lazy(() => import('../../modules/admin/program/library/components/FormComponent/EditFormComponent'));
const QuizComponent = lazy(() => import('modules/admin/program/library/components/QuizComponent'));
const EditQuizComponent = lazy(() => import('modules/admin/program/library/components/QuizComponent/EditQuizComponent'));
const AssignTutor = lazy(() => import('../../modules/admin/program/assignTutor/AssignTutor'));
const PortfolioEvidence = lazy(() => import('../../modules/admin/program/library/components/PortfolioEvidence'));
const EditPortfolioEvidence = lazy(() => import('../../modules/admin/program/library/components/PortfolioEvidence/EditPortfolioEvidence'));
const EditCourse = lazy(() => import('../../modules/admin/program/library/components/CourseComponent/EditCourse'));
const AddResourses = lazy(() => import('../../modules/admin/program/library/components/Resources/AddResourses'));
const EditResources = lazy(() => import('../../modules/admin/program/library/components/Resources/EditResources'));
const AddExam = lazy(() => import('../../modules/admin/program/library/components/ExternalExamComponent/AddExam'));
const EditExam = lazy(() => import('../../modules/admin/program/library/components/ExternalExamComponent/EditExam'));
const AddEvent = lazy(() => import('../../modules/admin/program/library/components/EventComponent/AddEvent'));
const EditEvent = lazy(() => import('../../modules/admin/program/library/components/EventComponent/EditEvent'));
const AddOffTheJob = lazy(() => import('../../modules/admin/program/library/components/OffTheJob'));
const AddReview = lazy(() => import('../../modules/admin/program/library/components/Review'));
const EditOTJ = lazy(() => import('../../modules/admin/program/library/components/OffTheJob/EditOTJ'));
const EditReview = lazy(() => import('../../modules/admin/program/library/components/Review/EditReview'));
const ProfileActivity = lazy(() => import('../../modules/admin/profile'));

// const Component = lazy(() => import('path'));
export default (
  <Routes>
    <Route
      path="/"
      element={<ProgramLayout />}
    >
      <Route
        index
        path="/"
        element={<ProgramList />}
      />
      <Route
        path="/:programId/tutor-list/view-profile/:tutorId"
        exact
        element={<ProfileActivity />}
      />
      <Route
        path="/:programId/overview"
        element={<ProgramOverview />}
      />
      <Route
        path="/program-information"
        element={<ProgramInformation />}
      />
      <Route
        path="/:programId/program-information"
        element={<ProgramInformation />}
      />
      <Route
        path="/program-details"
        element={<ProgramDetails />}
      />
      <Route
        path="/:programId/program-details"
        element={<ProgramDetails />}
      />

      <Route
        path="pricing"
        element={<ProgramPricing />}
      />
      <Route
        path="access-settings"
        element={<ProgramAccessSettings />}
      />
      <Route
        path="/:programId"
        element={<Library />}
      />
      <Route
        path="/:programId/course-component"
        element={<CourseComponent />}
      />
      <Route
        path="/:programId/section/:sectionId/course-component"
        element={<CourseComponent />}
      />
      <Route
        path="/:programId/section/:sectionId/:subSectionId/course-component"
        element={<CourseComponent />}
      />
      <Route
        path="/:programId/section/course-component"
        element={<CourseComponent />}
      />
      <Route
        path="/:programId/section/:sectionId/course-component/edit/:id"
        element={<EditCourse />}
      />
      <Route
        path="/:programId/course-component/edit/:id"
        element={<EditCourse />}
      />
      <Route
        path="/:programId/section/:sectionId/course-component/view/:id"
        element={<EditCourse />}
      />
      <Route
        path="/:programId/course-component/view/:id"
        element={<EditCourse />}
      />
      {/* Portfolio Evidence */}
      <Route
        path="/:programId/section/portfolio-evidence"
        element={<PortfolioEvidence />}
      />
      <Route
        path="/:programId/portfolio-evidence"
        element={<PortfolioEvidence />}
      />
      <Route
        path="/:programId/section/:sectionId/portfolio-evidence"
        element={<PortfolioEvidence />}
      />
      <Route
        path="/:programId/section/:sectionId/:subSectionId/portfolio-evidence"
        element={<PortfolioEvidence />}
      />
      <Route
        path="/:programId/section/:sectionId/portfolio-evidence/edit/:id"
        element={<EditPortfolioEvidence />}
      />
      <Route
        path="/:programId/portfolio-evidence/edit/:id"
        element={<EditPortfolioEvidence />}
      />
      <Route
        path="/:programId/section/:sectionId/portfolio-evidence/view/:id"
        element={<EditPortfolioEvidence />}
      />
      <Route
        path="/:programId/portfolio-evidence/view/:id"
        element={<EditPortfolioEvidence />}
      />
      {/* Form Component */}
      <Route
        path="/:programId/section/form"
        element={<FormComponent />}
      />
      <Route
        path="/:programId/form"
        element={<FormComponent />}
      />
      <Route
        path="/:programId/section/:sectionId/form"
        element={<FormComponent />}
      />
      <Route
        path="/:programId/section/:sectionId/:subSectionId/form"
        element={<FormComponent />}
      />
      <Route
        path="/:programId/section/:sectionId/form/edit/:id"
        element={<EditFormComponent />}
      />
      <Route
        path="/:programId/form/edit/:id"
        element={<EditFormComponent />}
      />
      <Route
        path="/:programId/section/:sectionId/form/view/:id"
        element={<EditFormComponent />}
      />
      <Route
        path="/:programId/form/view/:id"
        element={<EditFormComponent />}
      />

      {/* Quiz Component */}
      <Route
        path="/:programId/section/quiz"
        element={<QuizComponent />}
      />
      <Route
        path="/:programId/quiz"
        element={<QuizComponent />}
      />
      <Route
        path="/:programId/section/:sectionId/quiz"
        element={<QuizComponent />}
      />
      <Route
        path="/:programId/section/:sectionId/:subSectionId/quiz"
        element={<QuizComponent />}
      />
      <Route
        path="/:programId/section/:sectionId/quiz/edit/:id"
        element={<EditQuizComponent />}
      />
      <Route
        path="/:programId/quiz/edit/:id"
        element={<EditQuizComponent />}
      />
      <Route
        path="/:programId/section/:sectionId/quiz/view/:id"
        element={<EditQuizComponent />}
      />
      <Route
        path="/:programId/quiz/view/:id"
        element={<EditQuizComponent />}
      />

      {/* Resources Component */}
      <Route
        path="/:programId/add-resources"
        element={<AddResourses />}
      />
      <Route
        path="/:programId/section/:sectionId/add-resources"
        element={<AddResourses />}
      />
      <Route
        path="/:programId/section/:sectionId/:subSectionId/add-resources"
        element={<AddResourses />}
      />
      <Route
        path="/:programId/add-off-the-job"
        element={<AddOffTheJob />}
      />
      <Route
        path="/:programId/section/:sectionId/add-off-the-job"
        element={<AddOffTheJob />}
      />
      <Route
        path="/:programId/section/:sectionId/:subSectionId/add-off-the-job"
        element={<AddOffTheJob />}
      />

      {/* External Exam Component */}
      <Route
        path="/:programId/add-external-exam"
        element={<AddExam />}
      />
      <Route
        path="/:programId/section/:sectionId/add-external-exam"
        element={<AddExam />}
      />
      <Route
        path="/:programId/section/:sectionId/:subSectionId/add-external-exam"
        element={<AddExam />}
      />
      <Route
        path="/:programId/section/:sectionId/external-exam-component/edit/:id"
        element={<EditExam />}
      />
      <Route
        path="/:programId/external-exam-component/edit/:id"
        element={<EditExam />}
      />
      <Route
        path="/:programId/section/:sectionId/external-exam-component/view/:id"
        element={<EditExam />}
      />
      <Route
        path="/:programId/external-exam-component/view/:id"
        element={<EditExam />}
      />

      {/* Event Component */}
      <Route
        path="/:programId/add-event"
        element={<AddEvent />}
      />
      <Route
        path="/:programId/section/:sectionId/add-event"
        element={<AddEvent />}
      />
      <Route
        path="/:programId/section/:sectionId/:subSectionId/add-event"
        element={<AddEvent />}
      />
      <Route
        path="/:programId/section/:sectionId/event-component/edit/:id"
        element={<EditEvent />}
      />
      <Route
        path="/:programId/event-component/edit/:id"
        element={<EditEvent />}
      />
      <Route
        path="/:programId/section/:sectionId/event-component/view/:id"
        element={<EditEvent />}
      />
      <Route
        path="/:programId/event-component/view/:id"
        element={<EditEvent />}
      />

      {/* Review Component */}

      <Route
        path="/:programId/add-review"
        element={<AddReview />}
      />
      <Route
        path="/:programId/section/:sectionId/add-review"
        element={<AddReview />}
      />
      <Route
        path="/:programId/section/:sectionId/:subSectionId/add-review"
        element={<AddReview />}
      />
      <Route
        path="/:programId/section/:sectionId/resource-component/edit/:id"
        element={<EditResources />}
      />
      <Route
        path="/:programId/resource-component/edit/:id"
        element={<EditResources />}
      />
      <Route
        path="/:programId/section/:sectionId/resource-component/view/:id"
        element={<EditResources />}
      />
      <Route
        path="/:programId/resource-component/view/:id"
        element={<EditResources />}
      />
      <Route
        path="/:programId/section/:sectionId/otj-component/edit/:id"
        element={<EditOTJ />}
      />
      <Route
        path="/:programId/otj-component/edit/:id"
        element={<EditOTJ />}
      />
      <Route
        path="/:programId/section/:sectionId/otj-component/view/:id"
        element={<EditOTJ />}
      />
      <Route
        path="/:programId/otj-component/view/:id"
        element={<EditOTJ />}
      />

      <Route
        path="/:programId/section/:sectionId/review-component/edit/:id"
        element={<EditReview />}
      />
      <Route
        path="/:programId/review-component/edit/:id"
        element={<EditReview />}
      />
      <Route
        path="/:programId/section/:sectionId/review-component/view/:id"
        element={<EditReview />}
      />
      <Route
        path="/:programId/review-component/view/:id"
        element={<EditReview />}
      />

      <Route
        path="assign-tutor"
        element={<AssignTutor />}
      />
    </Route>
  </Routes>
);
/* <Route path="/:programId/course-component/edit/:id" element={<EditCourse />} /> */
