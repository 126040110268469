import React, { useState, useEffect } from 'react';
import Form from 'react-myoneui/lib/Form';
import { useSelector } from 'react-redux';
import Button from 'react-myoneui/lib/Button';
import {
  explainNoteIcon,
  questionIcon,
  formBuilderIcon,
  coursePlayIcon,
  offthejobIcon,
  assignmentDarkIcon,
  resourceIcon,
  workflowIcon,
  eventIcon,
  reviewNewIcon,
  quizNewIcon,
  externalIcon,
} from 'constants/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { editSectionWeightage } from 'api';
import QuickEditDropdown from './QuickEditDropdown';
import Dropdown from 'react-myoneui/lib/Dropdown';
import { translate } from 'lib';
import DragItem from '../../../../components/dragdrop/DragItem';
import UsefulNoteTooltips from '../../../../components/tooltips/UsefulNoteTooltips';
import ls from 'localstorage-slim';
import { OverlayTrigger } from 'react-myoneui';
import Popover from 'react-myoneui/lib/Popover';
import Log from 'lib/Log';
import { MessageBox } from 'components/MessageBox';
ls.config.encrypt = true;
const ComponentRow = (props) => {
  const { orgId } = useParams();
  const [organisationId, setOrganisationId] = useState(null);

  const {
    isFromSectionBank,
    title,
    label,
    asterisk,
    mandatory,
    id,
    grandParent,
    weightage,
    mainweightage,
    sectionId,
    section_type,
    weightageStatus,
    itemindex,
    onWeightage,
    onDeleteComponent,
    is_published,
    onHasComponentStatus,
    activeParentID,
    onActiveParentID,
    parentId,
    show,
    onReOrder,
    isValidRole,
    data,
    selectedSection,
    onClickSection,
  } = props;
  const { is_from_section_bank } = { ...data };
  const taxonomy = useSelector((state) => state?.taxonomy?.data);

  const [ownWeight, setOwnWeight] = useState(weightage);
  const [icon, setIcon] = useState();
  const [messageBoxStatus, setMessageBoxStatus] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setOrganisationId(ls.get('orgId') || orgId);

    //set icon same as in addDropdown
    switch (section_type) {
      case 'CourseComponent':
        setIcon(coursePlayIcon);
        break;
      case 'UnitComponent':
        setIcon(assignmentDarkIcon);
        break;
      case 'ResourceComponent':
        setIcon(resourceIcon);
        break;
      case 'FormComponent':
        setIcon(formBuilderIcon);
        break;
      case 'OTJComponent':
        setIcon(offthejobIcon);
        break;
      case 'ReviewComponent':
        setIcon(reviewNewIcon);
        break;
      case 'QuizComponent':
        setIcon(quizNewIcon);
        break;
      case 'ExternalExamComponent':
        setIcon(eventIcon);
        break;
      case 'EventComponent':
        setIcon(workflowIcon);
        break;
      case 'LtiComponent':
        setIcon(externalIcon);
        break;
      default:
        setIcon(questionIcon);
        break;
    }
    props.onHasComponentStatus(true);
    Log.info(is_published);
  }, []);

  const weightageHandler = (e) => {
    try {
      if (e.target.value <= 100 && isValidRole) {
        setOwnWeight(e.target.value);
        editSectionWeightage({
          id: id,
          weightage: e.target.value,
          orgId: organisationId,
        })
          .then((result) => {
            Log.info(result.data.data.weightage);
            onWeightage(id, e.target.value, label);
          })
          .catch((error) => {
            Log.error(error);
            setOwnWeight(weightage);
          });
      }
    } catch (err) {
      Log.error('Error : SubSectionRow/weightageHandler', err);
    }
  };

  const deleteComponentHandler = () => {
    try {
      Log.info('component-delete');
      onDeleteComponent(id, data);
    } catch (err) {
      Log.error('Error : ComponentRow/deleteComponentHandler', err);
    }
  };

  const displayEditComponent = () => {
    try {
      // console.log(section_type);
      if (section_type === 'CourseComponent') {
        isFromSectionBank ? navigate(`../unit-bank/section/${grandParent}/course-component/edit/${id}`) : navigate(`section/${grandParent}/course-component/edit/${id}`);
      } else if (section_type === 'UnitComponent') {
        isFromSectionBank ? navigate(`../unit-bank/section/${grandParent}/portfolio-evidence/edit/${id}`) : navigate(`section/${grandParent}/portfolio-evidence/edit/${id}`);
      } else if (section_type === 'ResourceComponent') {
        isFromSectionBank ? navigate(`../unit-bank/section/${grandParent}/resource-component/edit/${id}`) : navigate(`section/${grandParent}/resource-component/edit/${id}`);
      } else if (section_type === 'OTJComponent') {
        isFromSectionBank ? navigate(`../unit-bank/section/${grandParent}/otj-component/edit/${id}`) : navigate(`section/${grandParent}/otj-component/edit/${id}`);
      } else if (section_type === 'ReviewComponent') {
        isFromSectionBank ? navigate(`../unit-bank/section/${grandParent}/review-component/edit/${id}`) : navigate(`section/${grandParent}/review-component/edit/${id}`);
      } else if (section_type === 'FormComponent') {
        isFromSectionBank ? navigate(`../unit-bank/section/${grandParent}/form/edit/${id}`) : navigate(`section/${grandParent}/form/edit/${id}`);
      } else if (section_type === 'QuizComponent') {
        isFromSectionBank ? navigate(`../unit-bank/section/${grandParent}/quiz/edit/${id}`) : navigate(`section/${grandParent}/quiz/edit/${id}`);
      } else if (section_type === 'ExternalExamComponent') {
        isFromSectionBank ? navigate(`../unit-bank/section/${grandParent}/external-exam-component/edit/${id}`) : navigate(`section/${grandParent}/external-exam-component/edit/${id}`);
      } else if (section_type === 'EventComponent') {
        isFromSectionBank ? navigate(`../unit-bank/section/${grandParent}/event-component/edit/${id}`) : navigate(`section/${grandParent}/event-component/edit/${id}`);
      } else if (section_type === 'LtiComponent') {
        isFromSectionBank ? navigate(`../unit-bank/section/${grandParent}/external-component/edit/${id}`) : navigate(`section/${grandParent}/external-component/edit/${id}`);
      }
    } catch (err) {
      Log.error('Error : ComponentRow/displayEditComponent', err);
    }
  };

  const displayViewComponent = () => {
    try {
      if (section_type === 'CourseComponent') {
        navigate(`section/${grandParent}/course-component/view/${id}`);
      } else if (section_type === 'UnitComponent') {
        navigate(`section/${grandParent}/portfolio-evidence/view/${id}`);
      } else if (section_type === 'ResourceComponent') {
        navigate(`section/${grandParent}/resource-component/view/${id}`);
      } else if (section_type === 'OTJComponent') {
        navigate(`section/${grandParent}/otj-component/view/${id}`);
      } else if (section_type === 'ReviewComponent') {
        navigate(`section/${grandParent}/review-component/view/${id}`);
      }
    } catch (err) {
      Log.error('Error : ComponentRow/displayViewComponent', err);
    }
  };

  useEffect(() => {
    onHasComponentStatus(true);
  });
  const showComponentName = (sectionType) => {
    let componentName = '';
    if (taxonomy) {
      switch (sectionType) {
        case 'CourseComponent':
          componentName = translate(taxonomy)('course');
          break;
        case 'UnitComponent':
          componentName = translate(taxonomy)('assignment');
          break;
        case 'ResourceComponent':
          componentName = translate(taxonomy)('resources');
          break;
        case 'FormComponent':
          componentName = translate(taxonomy)('forms');
          break;
        case 'OTJComponent':
          componentName = translate(taxonomy)('otj');
          break;
        case 'ReviewComponent':
          componentName = translate(taxonomy)('review');
          break;
        case 'QuizComponent':
          componentName = translate(taxonomy)('quizBank');
          break;
        case 'ExternalExamComponent':
          componentName = translate(taxonomy)('externalExam') || 'External Exam';
          break;
        case 'EventComponent':
          componentName = translate(taxonomy)('event');
          break;
      }
    }
    return componentName;
  };
  const editComponentHandler = () => {
    try {
      // if (!is_from_section_bank) {
      //   displayEditComponent();
      // } else {
      //   setMessageBoxStatus(true);
      // }
      // TODO:
      displayEditComponent();
    } catch (error) {
      Log.error(error);
    }
  };
  const popover = (
    <Popover className="useful-note-tooltip popover-block">
      <Popover.Header>
        <div className="custom-tooltip-icon">{explainNoteIcon}</div>
        <span className="useful-note-title">Useful Note</span>
      </Popover.Header>
      <Popover.Body>
        <p>{showComponentName(section_type)}</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <MessageBox
        title={`Please update from ${translate(taxonomy)('section')} bank`}
        show={messageBoxStatus}
        onClose={() => setMessageBoxStatus(false)}
      />
      <DragItem
        parentid={parentId}
        key={id}
        id={id}
        data={{ id }}
        index={itemindex}
        isDraggable={isValidRole ? true : false}
        onChange={onReOrder}
      >
        <div
          className={`section-grid section-grid-row-item me-0 ${show ? 'd-flex' : 'd-none'} ${props?.className || ''} ${selectedSection === id && parentId === activeParentID ? 'on' : ''}`}
          onClick={() => {
            onActiveParentID(sectionId);
            onClickSection(id);
          }}
        >
          <div className="section-grid-left">
            <div className="section-grid-info pe-2">
              <div className="grid-file-icon">
                <OverlayTrigger
                  placement="auto"
                  overlay={popover}
                >
                  {icon}
                </OverlayTrigger>
                {mandatory === true && <span className="asterisk">&nbsp;{asterisk}</span>}
              </div>
            </div>
            <div className="section-grid-size">
              <div
                className="grid-text-overflow"
                title={title}
              >
                {title}
              </div>
            </div>
          </div>
          {/* <div className="section-grid-middle">
          <div className="section-grid-size">
            <div className="section-grid-info">
              <span className="grid-text-overflow" title={title}>
                {title}
              </span>
            </div>
          </div>
        </div> */}
          <div className="section-grid-right gap-2">
            {/* {is_published ? <Badge className="badge">Published</Badge> : <Badge className="badge dark">Drafted</Badge>} */}
            {}
            <div className="add-weightage-holder">
              {mainweightage && (
                <div className="total-weightage-wrap weightage-space-20">
                  <span className={`weightage-text ${weightageStatus ? '' : 'text-danger'}`}>{translate(taxonomy)('weightage')}</span>
                  <div className={`weightage-percentage ${!weightageStatus ? 'weightage-percentage-danger' : ''}`}>
                    <Form.Control
                      type="number"
                      placeholder=""
                      readOnly={!isValidRole}
                      value={ownWeight ? ownWeight : 0}
                      onChange={weightageHandler}
                    />
                    <span>%</span>
                  </div>

                  {weightageStatus !== true && (
                    <UsefulNoteTooltips
                      popoverTitle="Useful Note"
                      popoverInfo={`Please recheck if the individual ${translate(taxonomy)('weightage')} of modules sums up to 100%.`}
                    />
                  )}
                </div>
              )}
              <div className="action-col"></div>
              {isValidRole && (
                <QuickEditDropdown>
                  <Dropdown.Item
                    eventKey="1"
                    onClick={editComponentHandler}
                  >
                    Quick Edit..
                  </Dropdown.Item>

                  <Dropdown.Item
                    eventKey="2"
                    onClick={() => deleteComponentHandler()}
                  >
                    Delete
                  </Dropdown.Item>
                </QuickEditDropdown>
              )}
              {!isValidRole && (
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => displayViewComponent()}
                >
                  View
                </Button>
              )}
              <span className="collapse-section"></span>
            </div>
          </div>
        </div>
      </DragItem>
    </>
  );
};

export default ComponentRow;
