import { useState, useEffect } from 'react';
import { Row, Col, Card, ProgressWrap, ProgressValue, ProgressBar } from 'react-myoneui';
import Button from 'react-myoneui/lib/Button';
import ProgramList from '../../../../components/learners/ProgramList';
import CourseInformationBlock from '../../../../components/program/CourseInformationBlock';
import OberservationEvidence from '../../../../components/learners/OberservationEvidence';
import SubmitModal from '../../../../components/learners/SubmitModal';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setSelectedProgramComponent, changeActiveFirst, changeActiveSecond, changeActiveThird, setTaxonomy } from '../../../../redux';
import { getTaxonomy } from '../../../../api';
import { blackArrowLeftIcon, addIcon, videoPlayIcon } from '../../../../constants/icons';
import ToastBox from '../../../../components/ToastBox';
import ViewModel from '../../components/ViewModal';
import { translate } from '../../../../lib/';
// import { formatDate } from '../../../utils/formatTime';
import { decryptData } from '../../../../lib/';
import { LANG } from '../../../../constants/config';
import DOMPurify from 'dompurify';
import TextEditor from '../../../../components/TextEditor';
import { MessageBox } from '../../../../components/MessageBox';
import PreviewAddNewActivity from './PreviewAddNewActivity';
import PreviewAddTarget from './PreviewAddTarget';
import ls from 'localstorage-slim';
import Log from '../../../../lib/Log';
import { Spinner } from 'react-myoneui';
import ReviewDetailList from 'components/learners/ReviewDetailList';
import ViewReviewOffCanvas from '../ViewReviewOffCanvas';
import EditReviewOffCanvas from '../EditReviewOffCanvas';
import DataNotFound from 'components/DataNotFound';
import { fetchMemberReview, deleteMemberReview } from 'api/review';
import { ShowForm } from 'components/FormBuilder/previewContent/ShowForm';
import PreviewContent from 'components/FormBuilder/previewContent';
import ResourceContent from '../content/ResourceContent';
import QuizPreview from 'components/QuizBuilder/QuizPreview';
import ExamPreview from 'components/ExternalExamComponent/ExamPreview';
import EventPreview from 'components/EventComponent/EventPreview';
// enable global encryption
ls.config.encrypt = true;
function PreviewLectureDetails(props) {
  const purify = DOMPurify(window);
  const dispatch = useDispatch();

  const { onLoadSubPage, column, onLoadColumnData = () => {}, calculateSecondLevelSection, calculateTotalComponents, programInfo, from } = props;
  const [colData, setColData] = useState([]);
  const sections = useSelector((state) => state.sections.sections);
  const selectedComponent = useSelector((state) => state.sections.active_component);

  const [programSubmitToggle, setProgramSubmitToggle] = useState(false);

  const [addTargetShow, setAddTargetShow] = useState(false);
  const [addActivityShow, setAddActivityShow] = useState(false);
  const handleAddTarget = () => setAddTargetShow(true);
  const handleAddActivity = () => setAddActivityShow(true);

  const [show, setShow] = useState(false);
  const [coursePopup, setCoursePopup] = useState(false);
  const [fileType, setFileType] = useState();
  const [fileLink, setFileLink] = useState();
  const [fileName, setFileName] = useState();

  const active_first = useSelector((state) => state.sections.active_first);
  const active_second = useSelector((state) => state.sections.active_second);
  const active_third = useSelector((state) => state.sections.active_third);

  const taxonomy = useSelector((state) => state?.taxonomy?.data);
  const lang = useSelector((state) => state?.lang?.data);

  const language = ls.get('lang') || LANG;
  const [messageImage, setMessageImage] = useState('');

  const [observation, setObservation] = useState('');
  const [displayMsg, setDisplayMsg] = useState(false);

  const [data, setData] = useState({});
  const [memberReviews, setMemberReviews] = useState([]);
  const activeRole = JSON.parse(ls.get('activeRole'));
  const { pathname } = useLocation();
  const role = ls.get('role');
  const pathRole = pathname.split('/')[1];
  const isLearner = role === 'learner' && pathRole === 'learner' && activeRole.identifier === 'apprentice';
  const [addReviewShow, setAddReviewShow] = useState(false);
  const [loadMemberReview, setLoadMemberReview] = useState(false);
  const [confirmBoxStatus, setConfirmBoxStatus] = useState(false);
  const [confirmReviewBoxStatus, setConfirmReviewBoxStatus] = useState(false);
  const [activeReview, setActiveReview] = useState(null);
  const [showReviewOffcanvas, setShowReviewOffcanvas] = useState(false);
  const [selectedReview, setSelectedReview] = useState();
  const [activeReviewId, setActiveReviewId] = useState();
  const [editReviewShow, setEditReviewShow] = useState(false);
  const [reviewMessage, setReviewMessage] = useState(false);

  const orgId = ls.get('orgId');

  const handleAddReview = () => setAddReviewShow(true);

  const setTaxonomyData = async () => {
    try {
      getTaxonomy({
        orgId: ls.get('orgId'),
        lang: language,
      }).then((result) => {
        dispatch(setTaxonomy(result.data));
      });
    } catch (error) {
      Log.error(error);
    }
  };

  const onSubmitClick = () => {
    setProgramSubmitToggle(!programSubmitToggle);
  };

  const findSelectedObj = () => {
    try {
      let obj;
      if (column === 1) {
        obj = sections[active_first];
      } else if (column === 2) {
        obj = sections[active_first].child_sections[active_second];
      } else if (column === 3) {
        obj = sections[active_first].child_sections[active_second].child_sections[active_third];
      }
      return JSON.parse(JSON.stringify(obj));
    } catch (error) {
      Log.error(error);
    }
  };

  const showResource = (data) => {
    setCoursePopup(true);
  };

  const downloadFile = (file) => {
    try {
      fetch(file.link).then((response) => {
        const type = response.url.slice(response.url.lastIndexOf('.'), response.url.length);
        response.blob().then((blob) => {
          // Creating new object
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = `${file.name}.${type}`;
          alink.click();
        });
      });
    } catch (err) {
      Log.error(err);
    }
  };

  const observationHandler = (data) => {
    setObservation(data);
  };

  const handleShow = (item) => {
    try {
      setFileType(item?.type || item.link.slice(-3));
      setFileLink(item?.url || item?.link);
      setFileName(item?.i_item_name || item?.name || item?.title);
      setShow(true);
    } catch (error) {
      Log.error(error);
    }
  };
  const programHandler = (data, index) => {
    try {
      /* Check for column and have to change it's index */
      /* If item is section then need to go bak and have to active section with first indexed section active */
      switch (column) {
        case 1:
          dispatch(changeActiveFirst(index));

          setData({ ...data, active_first: index });
          break;
        case 2:
          dispatch(changeActiveSecond(index));

          setData({ ...data, active_second: index });
          break;
        case 3:
          dispatch(changeActiveThird(index));

          setData({ ...data, active_third: index });
          break;
      }

      if (!data.section_type) {
        onLoadColumnData(index);
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const classNameHandler = (index) => {
    try {
      let classname = '';
      switch (column) {
        case 1:
          classname = active_first === index ? 'active' : '';
          break;
        case 2:
          classname = active_second === index ? 'active' : '';
          break;
        case 3:
          classname = active_third === index ? 'active' : '';
          break;
      }

      return classname;
    } catch (error) {
      Log.error(error);
    }
  };

  useEffect(() => {
    let obj = findSelectedObj();
    dispatch(setSelectedProgramComponent(obj));
    setData({ ...data, selectedComponent: obj });
    setData({ ...data, selectedComponent: obj });
    setData({ ...data, active_first, active_second, active_third });
  }, [active_first, active_second, active_third]);

  useEffect(() => {
    if (column === 1) {
      setColData(sections);
      setData({ ...data, sections: sections });
    } else if (column === 2) {
      setColData(sections[active_first].child_sections);
      setData({ ...data, sections: sections[active_first].child_sections });
    } else if (column === 3) {
      setColData(sections[active_first].child_sections[active_second].child_sections);
      setData({ ...data, sections: sections[active_first].child_sections[active_second].child_sections });
    }
  }, [column, selectedComponent]);

  useEffect(() => {
    ls.set('selectedComponent', JSON.stringify(selectedComponent));
  }, [selectedComponent]);
  useEffect(() => {
    ls.set('active_second', active_second);
  }, [active_second]);
  useEffect(() => {
    ls.set('active_first', active_first);
  }, [active_first]);
  useEffect(() => {
    ls.set('active_third', active_third);
  }, [active_third]);
  useEffect(() => {
    ls.set('column', column);
  }, [column]);

  useEffect(() => {
    if (taxonomy.length === 0) {
      setTaxonomyData();
    }
  }, []);

  const handleDMSPreview = () => {
    try {
      setMessageImage('dmsImage');
      setDisplayMsg(true);
    } catch (error) {
      Log.error(error);
    }
  };

  const taxonomyMatch = {
    CourseComponent: 'course',
    UnitComponent: 'assignment',
    ResourceComponent: 'resources',
    OTJComponent: 'otj',
    ReviewComponent: 'review',
    FormComponent: 'forms',
    QuizComponent: 'quizBank',
  };

  const deleteReview = (id) => {
    setActiveReview(id);
    setConfirmReviewBoxStatus(true);
  };

  const onReviewDelete = async () => {
    try {
      const payload = {
        id: activeReview,
        orgId: orgId,
      };
      // await deleteMemberReview(payload, decryptData(id));
      setActiveReview(null);
      setConfirmReviewBoxStatus(false);
      getMemberReview();
    } catch (err) {
      console.error('Error >> ', err);
    }
  };

  const viewReview = (id) => {
    const review = memberReviews.find((selected) => selected.id === id);
    setSelectedReview(review);
    setActiveReview(id);
    setShowReviewOffcanvas(true);
  };
  const checkActiveCell = (index) => {
    try {
      switch (column) {
        case 1:
          // console.log(index, column, active_first);
          return index === active_first;
        case 2:
          return index === active_second;
        case 3:
          return index === active_third;
        default:
          return true;
      }
    } catch (error) {
      Log.error('Error on checkActiveCell:', error);
    }
  };
  const editReview = (id) => {
    const review = memberReviews.find((selected) => selected.id === id);
    setSelectedReview(review);
    setActiveReviewId(id);
    setEditReviewShow(true);
  };
  const getMemberReview = async () => {
    try {
      const payload = {
        member_program: selectedComponent?.member_section?.member_program,
        member_component: selectedComponent?.component._id || selectedComponent?.member_component._id,
      };
      setLoadMemberReview(true);

      const { data } = await fetchMemberReview(payload);
      setMemberReviews(data.data.reviewsList);
      setLoadMemberReview(false);
    } catch (error) {
      Log.info('Error >> ', error);
    }
  };
  const handleMessageBox = () => {
    setReviewMessage(true);
  };

  return (
    <>
      {colData.length > 0 && (
        <>
          <Col
            xl={4}
            lg={12}
            className="mb-4"
          >
            <Card className="border-0 h-100">
              <Card.Body>
                {colData.map((data, index) => {
                  return (
                    <div key={index}>
                      <ProgramList
                        iconName={data.section_type ? data.section_type : data.parent_section ? 'subSection' : 'Section'}
                        className={classNameHandler(index)}
                        data={{ section: { ...data } }}
                        // title={data.component ? translate(taxonomy)('component') + ': ' + data?.title : translate(taxonomy)('section') + ': ' + data?.title}
                        title={data.title}
                        sub_title={data?.sub_title}
                        // subSection={data?.child_sections?.length > 1 ? calculateSecondLevelSection(data.child_sections) : ''}
                        // subSection={
                        //   !data.component
                        //     ? calculateTotalComponents(data?.child_sections) +
                        //       ' ' +
                        //       (typeof data?.sub_title === 'string' && data?.sub_title?.trim() !== '' ? data?.sub_title : translate(taxonomy)('component') + 's')
                        //     : data?.section_type
                        //     ? data?.sub_title?.trim() !== ''
                        //       ? data?.sub_title
                        //       : sectionTypeHandler(data.section_type)
                        //     : 0 + ' ' + (data?.sub_title.trim() !== '' ? data?.sub_title : translate(taxonomy)('component'))
                        // }
                        subSection={
                          !data.component
                            ? calculateTotalComponents(data?.child_sections) +
                              ' ' +
                              (typeof data?.sub_title === 'string' && data?.sub_title?.trim() !== '' ? data?.sub_title : translate(taxonomy)('component') + 's')
                            : data?.sub_title && data?.sub_title.trim() !== ''
                            ? data?.sub_title
                            : translate(taxonomy)(taxonomyMatch[data?.section_type])
                        }
                        dotCircle={checkActiveCell(index)}
                        weightagePercentage={data.weightage ?? 0}
                        onClick={() => programHandler(data, index)}
                        programInfo={programInfo}
                        column={column}
                        component={data?.section_type}
                        progressValue="Not Started"
                        preview={true}
                        activeId={data?.id}
                      />
                      <hr className="program-list-divider" />
                    </div>
                  );
                })}
              </Card.Body>
            </Card>
          </Col>

          <Col
            xl={8}
            lg={12}
            className="mb-4"
          >
            <Card className="border-0 h-100">
              <Card.Body>
                <div className="program-list-detail-block">
                  <div className="program-fixed-header">
                    <Button
                      variant="text-icon"
                      className="p-0 back-btn"
                      onClick={() => onLoadSubPage('leactureList')}
                    >
                      {blackArrowLeftIcon} Back
                    </Button>

                    <div className="program-button-wrap">
                      {selectedComponent?.section_type !== 'ReviewComponent' &&
                        selectedComponent?.section_type !== 'QuizComponent' &&
                        selectedComponent?.section_type !== 'ExternalExamComponent' &&
                        selectedComponent?.section_type !== 'EventComponent' && (
                          <Button
                            className="border border-primary text-primary btn-outline"
                            varient="outline"
                            onClick={handleDMSPreview}
                          >
                            <span>Document Bank</span>
                          </Button>
                        )}

                      {selectedComponent?.section_type === 'OTJComponent' && (
                        <div className="progressbar-width-wrap">
                          <ProgressWrap>
                            <ProgressValue ProgressPercentage={`${!isNaN(0 / (0 + selectedComponent.component.requiredHours)) ? (0 / (0 + selectedComponent.component.requiredHours)) * 100 : 0}%`} />
                            <ProgressBar
                              variant="info"
                              now={(0 / (0 + selectedComponent.component.requiredHours)) * 100}
                            />
                          </ProgressWrap>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="program-list-detail-body">
                    <MessageBox
                      show={displayMsg}
                      messageImage={messageImage}
                      onClose={() => {
                        setDisplayMsg(false);
                        setMessageImage('');
                      }}
                      title="Information"
                      description={`Data Can't Be Saved Since this is Preview Of the Selected ${from ?? 'Program'}`}
                    />
                    <div className="program-detial-header-wrap">
                      <div className="program-detial-info">
                        <h4>{selectedComponent.title}</h4>
                      </div>
                    </div>
                    <div
                      className="program-list-description"
                      dangerouslySetInnerHTML={{ __html: purify.sanitize(selectedComponent?.description) }}
                    />
                    {selectedComponent?.section_type !== 'ReviewComponent' && (
                      <Button
                        onClick={handleAddTarget}
                        variant="leading-icon"
                        className="leading-icon add-target-btn"
                        type="button"
                      >
                        {addIcon} Add {translate(taxonomy)('target')}
                      </Button>
                    )}
                    {selectedComponent?.section_type === 'ReviewComponent' && (
                      <div className="activities-block">
                        <div className="activities-detail-header justify-content-between">
                          <h4>{`${translate(taxonomy)('review')}s`} &#40;5&#41;</h4>

                          {/* <h4>Reviews &#40;{memberReviews.length}&#41;</h4> */}
                          {!isLearner && (
                            <Button
                              variant="outline"
                              className="dark"
                              onClick={() => {
                                handleAddReview();
                                setReviewMessage(true);
                              }}
                            >
                              {`Add ${translate(taxonomy)('review')}`}
                            </Button>
                          )}
                        </div>
                        {loadMemberReview ? (
                          <div className="spinner-middle">
                            <Spinner animation="border" />
                          </div>
                        ) : memberReviews && memberReviews.length > 0 ? (
                          memberReviews.map((review, i) => (
                            <ReviewDetailList
                              key={i}
                              reviewId={review.id}
                              title={review.subject}
                              // location={review.location}
                              name={review.review_by.first_name + ' ' + review.review_by.last_name}
                              reviewedId={review.review_by.id}
                              schedule={review.createdAt}
                              viewButton="View"
                              onEditReview={() => editReview(review.id)}
                              onHandleMesgBox={() => handleMessageBox()}
                              onViewReview={() => viewReview(review.id)}
                              onDeleteReview={() => deleteReview(review.id)}
                              // status={review.is_approved}
                            />
                          ))
                        ) : (
                          <DataNotFound title={`${translate(taxonomy)('review')}`} />
                        )}
                      </div>
                    )}
                    {reviewMessage && (
                      <MessageBox
                        show={reviewMessage}
                        messageImage={messageImage}
                        onClose={() => {
                          setReviewMessage(false);
                          setMessageImage('');
                        }}
                        title="Information"
                        description="This operation cannot be performed Since this is Preview Of the Selected Program"
                      />
                    )}
                    {selectedComponent?.section_type === 'OTJComponent' && (
                      <div className="required-hours-wrap">
                        <span>
                          Required hours - <strong>{selectedComponent.component.requiredHours ?? 0} Hours</strong>
                        </span>
                        <span>
                          Your hours - <strong>{0} Hours</strong>
                        </span>
                        {selectedComponent.component.requiredHours - 0 > 0 && <span>&#40; You'r behind {selectedComponent.component.requiredHours} hours &#41;</span>}
                      </div>
                    )}
                    {selectedComponent?.section_type === 'OTJComponent' && (
                      <div className="activities-block">
                        <div className="activities-detail-header">
                          <h4>
                            {translate(taxonomy)('activity')} &#40;{0}&#41;
                          </h4>

                          <Button
                            variant="outline"
                            className="dark"
                            onClick={handleAddActivity}
                          >
                            Add {translate(taxonomy)('activity')}
                          </Button>
                        </div>
                      </div>
                    )}
                    {selectedComponent?.section_type === 'ResourceComponent' && (
                      <>
                        <ResourceContent
                          section={selectedComponent?.component}
                          onShowModel={() => console.log('onShowModel')}
                        />
                      </>
                    )}
                    {selectedComponent?.section_type === 'FormComponent' && (
                      <>
                        <PreviewContent
                          mode="trial"
                          id={selectedComponent?.component?.id}
                        />
                      </>
                    )}
                    {selectedComponent?.section_type === 'CourseComponent' && (
                      <Row>
                        <Col sm={8}>
                          <CourseInformationBlock
                            url={selectedComponent?.course_launch_url}
                            className="mb-4 information-border information-overflow-text information-course-block "
                            cirleImage={selectedComponent?.component?.image}
                            name={selectedComponent?.component?.name}
                            description={selectedComponent?.component?.description}
                            onClick={(data) => showResource(data)}
                          />
                        </Col>
                      </Row>
                    )}
                    {selectedComponent?.section_type !== 'ReviewComponent' &&
                      selectedComponent?.section_type !== 'FormComponent' &&
                      selectedComponent?.section_type !== 'QuizComponent' &&
                      selectedComponent?.section_type !== 'ExternalExamComponent' &&
                      selectedComponent?.section_type !== 'EventComponent' && (
                        <>
                          <div className="observation-block observation-pointer-block bg-transparent">
                            <h5 className="small-text-title">Observation</h5>
                            <TextEditor
                              onChange={observationHandler}
                              value={observation ? observation : ''}
                            />
                          </div>
                        </>
                      )}
                    {selectedComponent?.section_type === 'QuizComponent' && (
                      <>
                        <QuizPreview
                          mode="trial"
                          id={selectedComponent?.component?.id}
                          selectedComponent={selectedComponent?.component}
                        />
                      </>
                    )}
                    {selectedComponent?.section_type === 'ExternalExamComponent' && (
                      <>
                        <ExamPreview
                          mode="trial"
                          id={selectedComponent?.component?.id}
                          selectedComponent={selectedComponent?.component}
                        />
                      </>
                    )}
                    {selectedComponent?.section_type === 'EventComponent' && (
                      <EventPreview
                        mode="trial"
                        id={selectedComponent?.component?.id}
                        selectedComponent={selectedComponent?.component}
                      />
                    )}
                    {selectedComponent?.section_type !== 'ReviewComponent' && (
                      <div className="program-detial-footer-wrap justify-content-end">
                        {
                          <div className="program-button-wrap">
                            <Button
                              variant="outline"
                              className="dark"
                              onClick={() => setDisplayMsg(true)}
                            >
                              Save as Draft
                            </Button>
                            <Button
                              variant="primary"
                              onClick={onSubmitClick}
                            >
                              Save &amp; Submit
                            </Button>
                          </div>
                        }
                      </div>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <ViewModel
            onClose={() => setShow(false)}
            show={show}
            fileType={fileType}
            fileLink={fileLink}
            fileName={fileName}
          />
          <PreviewAddTarget
            sectionTitle={selectedComponent.title}
            show={addTargetShow}
            onSave={() => {
              setAddTargetShow(false);
              setDisplayMsg(true);
            }}
            onClose={() => setAddTargetShow(false)}
          />
          <PreviewAddNewActivity
            show={addActivityShow}
            activities={selectedComponent?.component?.activities}
            onClose={() => setAddActivityShow(false)}
            onSave={() => {
              setDisplayMsg(true);
              setAddActivityShow(false);
            }}
          />

          {coursePopup && (
            <MessageBox
              show={coursePopup}
              messageImage={messageImage}
              onClose={() => {
                setCoursePopup(false);
                setMessageImage('');
              }}
              title="Information"
              description={`Course Can't Be Viewed Since this is Preview Of the Selected ${from ?? 'Program'}`}
            />
          )}
          {programSubmitToggle && (
            <SubmitModal
              onSubmit={() => {
                setDisplayMsg(true);
                setProgramSubmitToggle(false);
              }}
              onSaveAsDraft={() => {
                setDisplayMsg(true);
                setProgramSubmitToggle(false);
              }}
              show={programSubmitToggle}
              onClose={() => setProgramSubmitToggle(false)}
            />
          )}
          <ToastBox />
        </>
      )}
    </>
  );
}

export default PreviewLectureDetails;
