import { memo, useEffect, useState } from 'react';
import { Form, Col, FloatingLabel, Row } from 'react-myoneui';
import { cameraIcon } from 'constants/icons';
import Card from 'react-myoneui/lib/Card';

function InputImageCommentBlock({ id = '', data, onDelete, onEdit }) {
  const [state, setState] = useState({ ...data });

  useEffect(() => {
    setState(data);
  }, [data]);
  return (
    <>
      <h3 className="evidenceLabel">{state.label}</h3>

      <Row className="evidence-row">
        <Col
          className="py-2"
          md={5}
          xl={4}
        >
          <Card className="evidence-thumbnail border-none">
            {state?.profile_img_url ? (
              <Card.Img
                src={state?.profile_img_url}
                className="rounded w-100 form-image-desc-block"
                alt="Course Image"
              />
            ) : (
              <Card.Body className="img-wrap">
                {cameraIcon}
                <span className="txt">200x200</span>
              </Card.Body>
            )}
          </Card>
        </Col>
        <Col
          className="py-2"
          md={7}
          xl={8}
        >
          <FloatingLabel
            controlId="floatingInputTitle"
            label={state.placeholder}
            className="form-builder-floating form-builder-textarea"
          >
            <Form.Control
              readOnly
              tabIndex="1"
              as="textarea"
              placeholder={state?.placeholder ? state.placeholder : ''}
            />
          </FloatingLabel>
        </Col>
      </Row>
    </>
  );
}
export default memo(InputImageCommentBlock);
