import React, { useState, useEffect } from 'react';
import Button from 'react-myoneui/lib/Button';
import { Form, Card, Spinner } from 'react-myoneui';
import Badge from 'react-myoneui/lib/Badge';
import { decryptData, translate, synArrayData } from 'lib/';
import Log from 'lib/Log';
import { formatDate, formatUTCDate } from 'utils/formatTime';
import { useSelector, useDispatch } from 'react-redux';
import { deleteEvidences, getMemberSection, getAllFeedBacks } from 'api';
import SubmitModal from 'components/learners/SubmitModal';
import AddTargetSelectedOffCanvas from './Target/AddTargetSelectedOffCanvas';
import { viewMemberProgram } from 'api/learner/program';
import { updateLastAssedAt, saveObservation, setStatusComplete, removeVideoEvidences } from 'api/learner/section';
import { setToastBoxDescription, setToastBoxStatus, storeMemberProgram, setSelectedProgramComponent, setFeedbacks, storeSections, setFirstCol, setActiveSection } from '../../../redux';
import { useParams, useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import OberservationEvidence from 'components/learners/OberservationEvidence';
import TextEditor from 'components/TextEditor';
import ToastBox from 'components/ToastBox';
import { fetchMemberActivities, getActivities, updateRequiredHour } from 'api/offTheJob';
import ActivitiesDetailList from 'components/learners/ActivitiesDetailList';
import { addIcon, subtractionIcon } from 'constants/icons';
import { displayMonth } from 'utils/formatTime';
import AddNewActivityOffCanvas from './AddNewActivityOffCanvas';
import ViewModel from '../components/ViewModal';
import { setConfirmBoxStatus } from 'redux/confirmBox';
import { ConfirmBox } from 'components/ConfirmBox';
import { Feedback } from '../components/Feedback';
import ls from 'localstorage-slim';
import { enrollSubscription } from 'api/component/course';
import { ShowForm } from 'components/FormBuilder/previewContent/ShowForm';
import EditQuiz from 'components/QuizBuilder/EditQuiz';
import EditExternalExam from 'components/ExternalExamComponent/EditExternalExam';
import EditEvent from 'components/EventComponent/EditEvent';
import ReviewDetailList from 'components/learners/ReviewDetailList';
import DataNotFound from 'components/DataNotFound';
import { deleteMemberReview, fetchMemberReview } from 'api/review';
import AddNewReviewOffCanvas from './AddNewReviewOffCanvas';
import ViewReviewOffCanvas from './ViewReviewOffCanvas';
import EditReviewOffCanvas from './EditReviewOffCanvas';
import ResourceContent from './content/ResourceContent';
import OTJContent from './content/OTJContent';
import CourseContent from './content/CourseContent';
import ComponentTitle from './content/ComponentTitle';
import ComponentHeader from './content/ComponentHeader';
import AddVideoEvidence from './AddVideoEvidence';

// enable global encryption
ls.config.encrypt = true;
function SelectedComponent({ backStatus = true, onBack = () => {}, id = null, onSectionDetail = () => {}, onChange = () => {}, onUpdateSections = () => {} }) {
  const purify = DOMPurify(window);
  const orgId = ls.get('orgId');
  const role = ls.get('role');
  const { programId } = useParams();
  const handleAddActivity = () => setAddActivityShow(true);
  const [addActivityShow, setAddActivityShow] = useState(false);
  const [programSubmitToggle, setProgramSubmitToggle] = useState(false);
  const [memberActivities, setMemberActivities] = useState([]);
  const activeRole = JSON.parse(ls.get('activeRole'));
  const [show, setShow] = useState(false);
  const [fileType, setFileType] = useState();
  const [fileLink, setFileLink] = useState();
  const [fileName, setFileName] = useState();
  const [confirmBoxLoading, setConfirmBoxLoading] = useState(false);
  const [addTargetShow, setAddTargetShow] = useState(false);
  const { can_manage_apprentices, can_edit_event, can_edit_external_exam, enable_feedback_to_learner, can_create_smart_target, can_view_smart_target } = activeRole?.privileges;
  const selectedComponent = useSelector((state) => state.sections.active_component);
  const activeSection = useSelector((state) => state.sections.active_section);
  const sections = useSelector((state) => state?.sections?.sections);
  const memberProgram = useSelector((state) => state.memberProgram);
  const section = selectedComponent?.section;
  const [member_section, setMemberSection] = useState(null);
  const [member_component, setMemberComponent] = useState(null);
  const [observation, setObservation] = useState(null);
  const evidences = useSelector((state) =>
    state?.sections?.active_component?.member_section ? state.sections?.active_component.member_section.evidences : state.sections.active_component?.evidences,
  );
  const videoEvidences = useSelector((state) =>
    state?.sections?.active_component?.member_section ? state.sections?.active_component.member_section.video_evidences : state.sections.active_component?.video_evidences,
  );
  const status = useSelector((state) => (state?.sections?.active_component?.status ? state.sections.active_component.status : state?.sections?.active_component?.member_section?.status));
  const isSubmitted = status === 'Submitted';
  const isCompleted = status === 'Completed';
  const isNotStarted = status === 'Not Started';
  const isInProgress = status === 'In Progress';
  const targets = useSelector((state) => state?.sections?.active_component?.targets);
  const [observation_by, setObservationBy] = useState(null);
  const [observation_at, setObservationAt] = useState(null);
  const [saveAsDraftLoder, setsaveAsDraftLoder] = useState(false);
  const [loadMemberActivities, setLoadMemberActivities] = useState(false);
  const [activities, setActivities] = useState([]);
  const [computedActivities, setComputedActivities] = useState([]);
  const taxonomy = useSelector((state) => state?.taxonomy?.data);

  const [markToggle, setMarkToggle] = useState(false);
  const [yourHours, setYourHours] = useState(0);
  const [requiredHours, setRequiredHours] = useState(0);
  const dispatch = useDispatch();
  const handleAddTarget = () => setAddTargetShow(true);
  const { pathname, state } = useLocation();

  const pathRole = pathname.split('/')[1];
  const isLearner = role === 'learner' && pathRole === 'learner' && activeRole.identifier === 'apprentice';
  const [evidenceToRemove, setEvidenceToRemove] = useState({});
  const [evidenceVideoToRemove, setEvidenceVideoToRemove] = useState('');
  const confirmBoxStatus = useSelector((state) => state?.confirmBox?.status);
  const [updateStatus, setUpdateStatus] = useState(false);
  const formElements = useSelector((state) => state?.formData?.data);
  const quizElements = useSelector((state) => state?.quizData?.data);
  const externalExamElements = useSelector((state) => state?.externalExamData?.data);

  let isValidForEdit = false;
  if (isLearner) {
    if (selectedComponent?.section?.section_type === 'EventComponent') {
      isValidForEdit = can_edit_event;
    } else if (selectedComponent?.section?.section_type === 'ExternalExamComponent') {
      isValidForEdit = can_edit_external_exam;
    } else {
      isValidForEdit = true;
    }
  } else {
    isValidForEdit = can_manage_apprentices;
  }

  const [formData, setFromData] = useState([]);
  const [confirmBox, setConfirmBox] = useState({
    name: '',
    status: false,
    OkText: 'Yes, Confirm',
    CancelText: 'No, Cancel',
    question: '',
  });
  const [addReviewShow, setAddReviewShow] = useState(false);
  const isIQV = role === 'iqv' && pathRole === 'iqv' && activeRole.identifier === 'iqv';
  const isEQV = role === 'eqv' && pathRole === 'eqv' && activeRole.identifier === 'eqv';
  const hideActivityButton = isIQV || isEQV;
  const { id: member_id } = useParams();
  const [loading, setLoading] = useState(false);
  const firstCol = useSelector((state) => state.sections.firstCol);
  const [loadMemberReview, setLoadMemberReview] = useState(false);
  const [confirmBoxEditShow, setConfirmBoxEdit] = useState(false);
  const handleAddReview = () => setAddReviewShow(true);
  const [confirmReviewBoxStatus, setConfirmReviewBoxStatus] = useState(false);
  const [activeReview, setActiveReview] = useState(null);
  const [selectedReview, setSelectedReview] = useState();
  const [showReviewOffcanvas, setShowReviewOffcanvas] = useState(false);
  const [activeReviewId, setActiveReviewId] = useState();
  const [editReviewShow, setEditReviewShow] = useState(false);
  const [memberReviews, setMemberReviews] = useState([]);
  const [showVideoOffcanvas, setShowVideoOffcanvas] = useState(false);
  const [memberEventStatus, setMemberEventStatus] = useState('');

  const isReviewComponent = selectedComponent?.section?.section_type === 'ReviewComponent';

  const updateRequireHour = async (requiredHour) => {
    if (requiredHour > 0) {
      setRequiredHours(requiredHour);
      const { data } = await updateRequiredHour({ required_hour: requiredHour, member_section: selectedComponent?.id });
      let cloneActiveComponent = { ...selectedComponent };
      cloneActiveComponent.required_hour = data.data.required_hour;
      dispatch(setSelectedProgramComponent(cloneActiveComponent));
      setConfirmBoxEdit(false);
    }
  };

  const syncFormData = (newData) => {
    try {
      setFromData(synArrayData([...formData], newData));
    } catch (error) {
      Log.error('Error on syncFormData :', error);
    }
  };
  const fetchMemberProgramDetail = async () => {
    try {
      const payload = {
        orgId: ls.get('orgId'),
        memberProgramId: ls.get('activeMemberProgramId'),
      };
      const { data } = await viewMemberProgram(payload);

      dispatch(storeMemberProgram(data.data));
    } catch (error) {
      Log.error('Error on syncFormData :', error);
    }
  };
  const handleActivityStatus = async (res) => {
    try {
      let cloneActiveComponent = JSON.parse(JSON.stringify(selectedComponent));
      cloneActiveComponent.member_section.status = res.membersection?.status;
      dispatch(setSelectedProgramComponent(cloneActiveComponent));
    } catch (error) {
      Log.error('Error on handleActivityStatus', error);
    }
  };

  const statusUpdate = async () => {
    try {
      let payload = {
        member_section_id: selectedComponent?.member_section?.id ? selectedComponent.member_section.id : selectedComponent?.id,
      };
      let result = await setStatusComplete({ payload, orgId });
      if (result) {
        onChange(result?.data?.data?.membersection);
        setConfirmBoxLoading(false);
        dispatch(setConfirmBoxStatus(false));
        // Updating Active Component
        let cloneActiveComponent = JSON.parse(JSON.stringify(selectedComponent));
        if (cloneActiveComponent?.member_section) {
          cloneActiveComponent.member_section.status = 'Completed';
        } else {
          cloneActiveComponent.status = 'Completed';
        }
        dispatch(setSelectedProgramComponent(cloneActiveComponent));
        // Updating Active Section
        let cloneActiveSection = JSON.parse(JSON.stringify(activeSection));
        cloneActiveSection.status = 'Completed';

        dispatch(setActiveSection(cloneActiveSection));
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(result.data.message));

        dispatch(setConfirmBoxStatus(false));
        setUpdateStatus(false);

        setMarkToggle(false);

        /* Replicate on First Col */
        let cloneFirstCol = JSON.parse(JSON.stringify(firstCol));
        let obj = cloneFirstCol.find((item) => item.id === selectedComponent.id);
        let index = cloneFirstCol.indexOf(obj);
        cloneFirstCol[index].status = 'Completed';
        updateFirstCol(selectedComponent);
        dispatch(setFirstCol(cloneFirstCol));
        fetchMemberProgramDetail();
      }
    } catch (error) {
      Log.error(error);
      setConfirmBoxLoading(false);
      setMarkToggle(false);
    }
  };

  const updateLastAssed = async (data) => {
    try {
      let mydata = JSON.parse(JSON.stringify(data));
      const payload = { member_section_id: id };
      const result = await updateLastAssedAt({ payload, orgId });

      if (result) {
        let cloneSections = JSON.parse(JSON.stringify(sections));
        let obj = cloneSections.find((item) => item.id === data.member_section.id);
        let index = cloneSections.indexOf(obj);
        obj.last_assessed_at = result?.data?.data;
        cloneSections[index] = obj;
        mydata.member_section.last_assessed_at = result?.data?.data;
        dispatch(storeSections(cloneSections));
        dispatch(setSelectedProgramComponent(mydata.member_section));
        // onUpdateSections(cloneSections);
        fetchAllFeedbacks();
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const fetchAllFeedbacks = async () => {
    try {
      let result = await getAllFeedBacks({ member_program: programId });
      dispatch(setFeedbacks({ ...result.data.data }));
    } catch (error) {
      Log.error(error);
    }
  };
  const onMarkClick = (e) => {
    dispatch(setConfirmBoxStatus(true));
    setUpdateStatus(true);

    setConfirmBox({ ...confirmBox, name: 'mark-as-complete', question: 'Are you Sure you want to Mark as complete ?' });
  };
  const handleShow = (item) => {
    setFileType(item?.type || item.link.slice(-3));
    setFileLink(item?.url || item?.link);
    setFileName(item?.i_item_name || item?.name || item?.title);
    setShow(true);
  };

  const removeEvidence = async (data) => {
    try {
      let result = await deleteEvidences({ member_section_id: selectedComponent?.member_section?.id ?? selectedComponent?.id, evidence_id: data.id });

      if (result) {
        setConfirmBoxLoading(false);
        let cloneSelectedComponent = JSON.parse(JSON.stringify(selectedComponent));
        let remainFiles = (cloneSelectedComponent?.member_section?.evidences ?? cloneSelectedComponent?.evidences).filter((item) => item.id !== data.id);
        if (cloneSelectedComponent?.member_section?.evidences) {
          cloneSelectedComponent.member_section.evidences = remainFiles;
        } else {
          cloneSelectedComponent.evidences = remainFiles;
        }
        // setConfirmBox({ ...confirmBox, name: 'evidence', question: 'Are you sure you want to remove this Evidence ?' });
        // setEvidences(remainFiles);
        dispatch(setSelectedProgramComponent(cloneSelectedComponent));
        dispatch(setConfirmBoxStatus(false));
        setUpdateStatus(false);
        setEvidenceToRemove({});
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(result?.data?.message));
      }
    } catch (error) {
      setConfirmBoxLoading(false);
      dispatch(setConfirmBoxStatus(false));
      Log.error(error);
    }
  };

  const deleteReview = (id) => {
    setActiveReview(id);
    setConfirmReviewBoxStatus(true);
  };
  const getMemberReview = async () => {
    try {
      const payload = {
        member_program: selectedComponent?.member_program,
        member_component: member_component.id,
      };
      setLoadMemberReview(true);

      const { data } = await fetchMemberReview(payload);
      setMemberReviews(data.data.reviewsList);
      setLoadMemberReview(false);
    } catch (error) {
      Log.info('Error : getMemberReview >> ', error);
    }
  };

  const onReviewDelete = async () => {
    try {
      const payload = {
        id: activeReview,
        orgId: orgId,
      };
      await deleteMemberReview(payload, decryptData(id));
      setActiveReview(null);
      setConfirmReviewBoxStatus(false);
      getMemberReview();
    } catch (err) {
      console.error('Error : onReviewDelete >> ', err);
    }
  };

  const viewReview = (id) => {
    try {
      const review = memberReviews.find((selected) => selected.id === id);
      setSelectedReview(review);
      setActiveReview(id);
      setShowReviewOffcanvas(true);
    } catch (error) {
      Log.error('Error on viewReviewa :', error);
    }
  };

  const editReview = (id) => {
    try {
      const review = memberReviews.find((selected) => selected.id === id);
      setSelectedReview(review);
      setActiveReviewId(id);
      setEditReviewShow(true);
    } catch (error) {
      Log.error('Error on editReview :', error);
    }
  };

  useEffect(() => {
    if (selectedComponent?.section?.section_type === 'OTJComponent' && member_section?.id) {
      getMemberActivities();
    }
  }, [selectedComponent, member_section]);
  /* on Review Component, if user click on left side row . 
  It will it API for detail of component and then need to hit
  another API to get reviewMemberReview .
  */
  useEffect(() => {
    if (isReviewComponent) {
      getMemberReview();
    }
  }, [member_component]);

  const getMemberActivities = async () => {
    try {
      const payload = { member: isLearner ? ls.get('userid') : decryptData(member_id), member_section: member_section?.id, otj: selectedComponent?.section?.component?.id };

      let formattedItems = [];
      // fetch activities of OTJ component created by admin

      if (selectedComponent?.section.component?.can_admin_create_activity) {
        const res = await getActivities(selectedComponent?.section?.component.id);

        formattedItems = res.data.data[0].activities.map((activity) => ({
          id: activity._id,
          name: activity.title,
        }));
        setActivities(formattedItems);
      }
      // fetch member activities
      const { data } = await fetchMemberActivities(payload);

      // now we have to replace activity by activity title if activity exist
      const formatedActivities = [];
      setYourHours(data.data.your_hours);
      setRequiredHours(data.data.computed_required_hour);
      data.data.memberActivities.forEach((memberActivity) => {
        if (memberActivity.activity && selectedComponent?.section?.component?.can_admin_create_activity) {
          formattedItems.forEach((activity) => {
            if (activity.id === memberActivity.activity) {
              formatedActivities.push({ ...memberActivity, activity: activity.name });
            }
          });
        } else {
          formatedActivities.push(memberActivity);
        }
      });
      setMemberActivities(formatedActivities);

      setLoadMemberActivities(false);
    } catch (error) {
      Log.error('Error :getMemberActivities >> ', error);
      setLoading(false);
    }
  };
  const fetchSectionDetail = async () => {
    // getMemberSection
    try {
      setLoading(true);
      dispatch(setSelectedProgramComponent(null));
      let result = await getMemberSection(id);
      // console.log('first', result);
      if (result) {
        setLoading(false);
        let resulData = { ...result?.data?.data };
        // console.log('second', resulData);

        const { member_section, member_component } = resulData;
        const { observation, evidences, status, targets, section, observation_by, observation_at } = member_section;
        setMemberSection(member_section);
        setMemberComponent(member_component);
        setObservation(observation);
        setObservationBy(observation_by);
        setObservationAt(observation_at);
        ls.set('selectedComponent', JSON.stringify(result?.data?.data?.member_section));
        member_component?.member_event && setMemberEventStatus(member_component?.member_event?.status);
        dispatch(setSelectedProgramComponent(result?.data?.data?.member_section));
        onSectionDetail(result?.data?.data?.member_section);
        if (role === 'learner') {
          updateLastAssed(JSON.parse(JSON.stringify(result?.data?.data)));
        }
      }
    } catch (err) {
      setLoading(false);
      Log.error(err);
    }
  };
  useEffect(() => {
    fetchSectionDetail();
  }, [id]);

  useEffect(() => {
    if (memberActivities) {
      const memberActivitiesName = memberActivities.map((memberActivity) => memberActivity.activity);
      const filteredActivities = activities.filter((activity) => !memberActivitiesName.includes(activity.name));
      setComputedActivities(filteredActivities);
    }
  }, [memberActivities]);

  const observationHandler = (data) => {
    try {
      const cloneActiveComponent = JSON.parse(JSON.stringify(selectedComponent));
      cloneActiveComponent.observation = data;
      setObservation(data);
      // dispatch(setSelectedProgramComponent(cloneActiveComponent));
    } catch (err) {
      Log.error(err);
    }
  };

  const saveAsDraft = async () => {
    try {
      setsaveAsDraftLoder(true);
      const observation_by = ls.get('userid');
      const member_section_id = member_section.id;
      const payload = {
        // formData: convertPayloadFormData(formElements),
        // quizData: convertPayloadQuizData(quizElements),
        // member_component: member_component?._id,
        // marks_obtained: externalExamElements[0]?.value,
        // external_exam_custom_fields: externalExamElements,
        member_section_id,
        observation: observation,
        observation_by,
        is_submitted: isSubmitted,
      };
      if (selectedComponent?.section?.section_type === 'FormComponent') {
        payload.formData = convertPayloadFormData(formElements);
      }
      if (selectedComponent?.section?.section_type === 'QuizComponent') {
        payload.quizData = convertPayloadQuizData(quizElements);
      }
      if (selectedComponent?.section?.section_type === 'ExternalExamComponent') {
        // payload.member_component = member_component?._id;
        payload.marks_obtained = externalExamElements[0]?.value;
        payload.external_exam_custom_fields = JSON.stringify(externalExamElements);
      }
      if (selectedComponent?.section?.section_type === 'EventComponent') {
        payload.status = memberEventStatus;
      }
      const res = await saveObservation(payload);
      if (res) {
        let response = { ...res };
        setsaveAsDraftLoder(false);
        setProgramSubmitToggle(false);
        let observation = '';
        let observation_at = '';
        let observation_by = '';
        let status = '';
        if (response?.data?.data.membersection) {
          let membersection = { ...response?.data?.data.membersection };
          observation = membersection.observation;
          observation_at = membersection.observation_at;
          observation_by = membersection.observation_by;
          status = membersection.status;
        }

        let cloneActiveComponent = JSON.parse(JSON.stringify(selectedComponent));
        cloneActiveComponent.observation = observation;
        cloneActiveComponent.observation_at = observation_at;
        cloneActiveComponent.observation_by = observation_by;
        cloneActiveComponent.status = status;
        setObservation(observation);
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(response?.data?.message));
        dispatch(setSelectedProgramComponent(cloneActiveComponent));
        let result = { ...res.data.data };
        result.member_section = result?.member_section ? result.member_section : result?.membersection;
        onChange(result.member_section);
      }
    } catch (error) {
      Log.error(error);
      setProgramSubmitToggle(false);
      setsaveAsDraftLoder(false);
    }
  };
  const updateFirstCol = (member_section) => {
    try {
      let cloneFirstCol = JSON.parse(JSON.stringify(firstCol));
      if (cloneFirstCol?.length > 0) {
        let obj = cloneFirstCol.find((item) => item.id === member_section.id);
        let index = cloneFirstCol.indexOf(obj);
        cloneFirstCol[index].status = member_section.status;
        dispatch(setFirstCol(cloneFirstCol));
      }
    } catch (error) {
      Log.error('Error on updateData :', error);
    }
  };

  const updateData = (data) => {
    try {
      let clonseSelectedComponent = JSON.parse(JSON.stringify(selectedComponent));
      clonseSelectedComponent.status = data.status;
      let cloneFirstCol = JSON.parse(JSON.stringify(firstCol));
      let obj = cloneFirstCol.find((item) => item.id === data.id);
      let index = cloneFirstCol.indexOf(obj);
      cloneFirstCol[index].status = data.status;
      dispatch(setSelectedProgramComponent(clonseSelectedComponent));
      dispatch(setFirstCol(cloneFirstCol));
      if (isReviewComponent) {
        getMemberReview();
      }
    } catch (error) {
      Log.error('Error on updateData :', error);
    }
  };
  const convertPayloadFormData = (data) => {
    try {
      let formFields = data.filter((item) => item.name === 'form-element');
      let mydata = formFields.map((item) => {
        let subSectionObj = data.find((subsection) => subsection.id === item.parent_id);
        let sectionObj = data.find((section) => section.id === subSectionObj.parent_id);
        let obj = {
          program: memberProgram.program.id,
          section: selectedComponent?.section?.id,
          member_program: selectedComponent?.member_program,
          member: memberProgram.member.id,
          form: selectedComponent?.section?.component?.id,
          form_sub_section: item.parent_id,
          form_section: sectionObj.id,
          field: item.id,
          value: item.value,
          image_url: item?.image_url,
        };
        return obj;
      });

      return mydata;
    } catch (error) {
      console.error(error);
    }
  };

  const convertPayloadQuizData = (data) => {
    try {
      let obj = {
        program: memberProgram.program.id,
        member: memberProgram.member.id,
        member_program: selectedComponent?.member_program,
        section: selectedComponent?.section?._id,
        quiz_component: selectedComponent?.section?.component?.id,
        answers: data,
      };

      return obj;
    } catch (error) {
      console.error(error);
    }
  };

  const updateActiveSection = (membersection) => {
    try {
      let cloneActiveSection = JSON.parse(JSON.stringify(activeSection));
      cloneActiveSection.status = membersection.status;
      dispatch(setActiveSection(cloneActiveSection));
    } catch (error) {
      Log.error('Error on updateActiveSection:', error);
    }
  };

  const submitHandler = async () => {
    try {
      let payload = {
        is_submitted: true,
        member_section_id: selectedComponent?.id,
        observation: observation,
        observation_by: ls.get('userid'),
        // formData: convertPayloadFormData(formElements),
        // quizData: convertPayloadQuizData(quizElements),
        // member_component: member_component?._id,
        // marks_obtained: externalExamElements[0]?.value,
        // external_exam_custom_fields: externalExamElements,
      };

      if (selectedComponent?.section?.section_type === 'FormComponent') {
        payload.formData = convertPayloadFormData(formElements);
      }
      if (selectedComponent?.section?.section_type === 'QuizComponent') {
        payload.quizData = convertPayloadQuizData(quizElements);
      }
      if (selectedComponent?.section?.section_type === 'ExternalExamComponent') {
        // payload.member_component = member_component?._id;
        payload.marks_obtained = externalExamElements[0]?.value;
        payload.external_exam_custom_fields = JSON.stringify(externalExamElements);
      }
      if (selectedComponent?.section?.section_type === 'EventComponent') {
        payload.status = memberEventStatus;
      }
      let result = await saveObservation(payload);
      if (result) {
        let res = JSON.parse(JSON.stringify(result?.data?.data));

        let cloneActiveComponent = JSON.parse(JSON.stringify(selectedComponent));
        cloneActiveComponent.observation_by = res?.membersection?.observation_by;
        cloneActiveComponent.observation_at = res?.membersection?.observation_at;
        cloneActiveComponent.submitted_at = res?.membersection?.submitted_at;
        cloneActiveComponent.updatedAt = res?.membersection?.updatedAt;
        cloneActiveComponent.status = res?.membersection?.status;
        dispatch(setSelectedProgramComponent(cloneActiveComponent));
        onChange(res?.membersection);
        updateFirstCol(cloneActiveComponent);

        updateActiveSection(res?.membersection);
        //  updateSections(res?.membersection);
        setProgramSubmitToggle(false);
        setObservation(res?.membersection?.observation);
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(result?.data?.message));
        (selectedComponent?.section?.section_type === 'ExternalExamComponent' || selectedComponent?.section?.section_type === 'EventComponent') && statusUpdate();
      }
    } catch (error) {
      Log.error(error);
      setProgramSubmitToggle(false);
    }
  };
  const onSubmitClick = () => {
    setProgramSubmitToggle(!programSubmitToggle);
  };
  const displayToaster = (msg) => {
    dispatch(setToastBoxStatus(true));
    dispatch(setToastBoxDescription(msg));
  };
  async function toDataURL(url) {
    const blob = await fetch(url).then((res) => res.blob());
    return URL.createObjectURL(blob);
  }
  async function downloadFile(myfile) {
    try {
      let url = myfile?.link ? myfile.link : myfile?.url;
      const aElement = document.createElement('a');
      aElement.setAttribute('download', myfile.name);
      aElement.href = await toDataURL(url);
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      Log.error(error);
    }
  }

  const activitySaveHandler = (data) => {
    try {
      setAddActivityShow(false);
      setMemberActivities([...memberActivities, data]);
      let ownstatus = selectedComponent.status;
      if (ownstatus === 'Not Started') {
        ownstatus = 'In Progress';
      }
      let firstColClone = JSON.parse(JSON.stringify(firstCol));
      let obj = firstColClone.find((item) => item.id === selectedComponent.id);
      let index = firstColClone.indexOf(obj);
      firstColClone[index].status = ownstatus;
      dispatch(setFirstCol(firstColClone));
    } catch (err) {
      Log.error(err);
    }
  };

  const canCreateActivity = () => {
    switch (role) {
      case 'learner':
        return member_component.component.can_learner_create_activity;
      case 'admin':
        return member_component.component.can_admin_create_activity;
    }
  };
  const showAddActivityBtn = () => {
    try {
      if ((member_component?.component?.can_learner_create_activity || member_component?.component?.can_admin_create_activity) && status !== 'Completed') {
        if (role === 'admin' && can_manage_apprentices) {
          return (
            <Button
              variant="outline"
              className="dark"
              onClick={handleAddActivity}
            >
              Add {translate(taxonomy)('activity')}
            </Button>
          );
        } else if (role === 'tutor' || role === 'assessor' || role === 'learner') {
          return (
            <Button
              variant="outline"
              className="dark"
              onClick={handleAddActivity}
            >
              Add {translate(taxonomy)('activity')}
            </Button>
          );
        }
      }
    } catch (error) {
      Log.error('Error on showAddActivityBtn :', error);
    }
  };
  const okConfirm = () => {
    try {
      Log.info(JSON.stringify(confirmBox));
      setConfirmBoxLoading(true);
      switch (confirmBox.name) {
        case 'evidence':
          removeEvidence(evidenceToRemove);
          break;
        case 'mark-as-complete':
          statusUpdate();
          break;
        case 'video-evidence':
          handleVideoEvidenceDelete(evidenceVideoToRemove);
          break;
        default:
          break;
      }
    } catch (err) {
      Log.error(err);
    }
    //removeEvidence(evidenceToRemove)
  };
  const isValidateAddReviewBtn = () => {
    let status;
    if (role === 'assessor' || role === 'tutor') {
      status = true;
    } else if (role === 'admin' && can_manage_apprentices === true) {
      status = true;
    } else {
      status = false;
    }

    return status;
  };

  const handleOpenAddVideo = () => {
    setShowVideoOffcanvas(!showVideoOffcanvas);
  };

  const handleSaveVideoEvidence = (data) => {
    let cloneActiveComponent = { ...selectedComponent };
    cloneActiveComponent.video_evidences = data;
    dispatch(setSelectedProgramComponent(cloneActiveComponent));
  };

  const handleUpdateNewTag = (newTagVideo) => {
    let cloneActiveComponent = { ...selectedComponent };
    cloneActiveComponent.video_evidences = cloneActiveComponent.video_evidences?.map((video) => (video?.id === newTagVideo?.id ? newTagVideo : video));
    dispatch(setSelectedProgramComponent(cloneActiveComponent));
  };

  const handleVideoEvidenceDelete = async (videoId) => {
    try {
      const payload = {
        member_section_id: selectedComponent?.id,
        video_evidence_id: videoId,
      };
      const result = await removeVideoEvidences(payload);
      if (result) {
        let cloneActiveComponent = { ...selectedComponent };
        cloneActiveComponent.video_evidences = cloneActiveComponent.video_evidences?.filter((v) => v?.id !== videoId);
        dispatch(setSelectedProgramComponent(cloneActiveComponent));
        dispatch(setConfirmBoxStatus(false));
        setEvidenceVideoToRemove('');
        setUpdateStatus(false);
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(result?.data?.message));
      }
    } catch (error) {
    } finally {
      setConfirmBoxLoading(false);
      dispatch(setConfirmBoxStatus(false));
    }
  };
  return (
    <>
      {
        <Card className="border-0">
          <Card.Body>
            {loading === true ? (
              <div className="spinner-wrapper">
                <Spinner animation="border" />{' '}
              </div>
            ) : (
              <div className="program-list-detail-block">
                <ComponentHeader
                  componentName={section?.section_type}
                  yourHours={yourHours}
                  requiredHours={requiredHours}
                  can_manage_apprentices={can_manage_apprentices}
                  status={status}
                  isLearner={isLearner}
                  onBack={onBack}
                  onMarkAsComplete={onMarkClick}
                  backStatus={state?.fromNotification ? false : true}
                  role={role}

                  // component score
                />

                <div className="program-list-detail-body">
                  <ComponentTitle section={selectedComponent?.section} />
                  <div className="target-wrap">
                    {(activeRole.identifier === 'apprentice' || can_view_smart_target) &&
                      selectedComponent?.section?.section_type !== 'ExternalExamComponent' &&
                      selectedComponent?.section?.section_type !== 'EventComponent' &&
                      targets &&
                      targets.length > 0 &&
                      targets?.map((target, i) => (
                        <Badge
                          className="badge badge-round badge-border"
                          key={i}
                        >
                          {subtractionIcon}
                          {`${translate(taxonomy)('target')}  ${i + 1}:`}
                          <span>
                            {formatUTCDate(target.date)}&nbsp; ({target.progress}%)
                          </span>
                        </Badge>
                      ))}
                  </div>
                  <div
                    className="program-list-description"
                    dangerouslySetInnerHTML={{ __html: purify.sanitize(section?.description) }}
                  />
                  {((!isLearner && can_create_smart_target) || isLearner) &&
                    status !== 'Completed' &&
                    selectedComponent?.section?.section_type !== 'ReviewComponent' &&
                    selectedComponent?.section?.section_type !== 'ExternalExamComponent' &&
                    selectedComponent?.section?.section_type !== 'EventComponent' && (
                      <Button
                        onClick={handleAddTarget}
                        variant="leading-icon"
                        className="leading-icon add-target-btn"
                        type="button"
                      >
                        {addIcon} Add {translate(taxonomy)('target')}
                      </Button>
                    )}
                  {confirmReviewBoxStatus && (
                    <ConfirmBox
                      show={confirmReviewBoxStatus}
                      question={`Are you sure you want to delete this ${translate(taxonomy)('review')}`}
                      cancelText="Cancel"
                      okText="Delete"
                      onHide={() => setConfirmReviewBoxStatus(false)}
                      onOk={onReviewDelete}
                      // loading={deleteLoad}
                    />
                  )}
                  <ConfirmBox
                    show={confirmBoxEditShow}
                    question={`Are you sure you want to update required hours for current ${selectedComponent?.section?.title} ${translate(taxonomy)('component')}?`}
                    editRequiredHour={true}
                    editRequiredHourBody={selectedComponent?.required_hour}
                    okText="Save"
                    cancelText="Cancel"
                    onOk={(requiredHour) => updateRequireHour(requiredHour)}
                    onHide={() => setConfirmBoxEdit(false)}
                  />
                  {section?.section_type === 'OTJComponent' && !loadMemberActivities && (
                    <OTJContent
                      status={status}
                      requiredHours={requiredHours}
                      yourHours={yourHours}
                      onShowEditBox={() => setConfirmBoxEdit(true)}
                    />
                  )}

                  {member_component?.component_type === 'OTJComponent' && !loadMemberActivities && (
                    <div className="activities-block">
                      <div className="activities-detail-header">
                        <h4>
                          {translate(taxonomy)('activity')} &#40;{memberActivities.length}&#41;
                        </h4>
                        {showAddActivityBtn()}
                      </div>
                      {memberActivities.map((activity, i) => (
                        <ActivitiesDetailList
                          key={i}
                          sectionId={id}
                          memberProgram={memberProgram}
                          activityId={activity.id}
                          title={activity.title !== '' ? activity.title : activity.activity}
                          location={activity.location}
                          // name="Alex Mathew" it is name of supervisor
                          schedule={displayMonth(activity.date)}
                          viewButton="View"
                          backBtnStatus={backStatus}
                          hours={activity.hours}
                          status={activity.is_approved}
                          otj={{
                            id: member_component?.component?.id,
                            title: member_component?.component?.title,
                          }}
                        />
                      ))}
                    </div>
                  )}
                  {section?.section_type === 'CourseComponent' && (
                    <CourseContent
                      url={member_component?.course_launch_url}
                      image={section?.component?.image}
                      name={section?.component?.name}
                      description={section?.component?.description}
                      section={selectedComponent?.section}
                    />
                  )}
                  {section?.section_type === 'ResourceComponent' && (
                    <ResourceContent
                      section={selectedComponent?.section}
                      onShowModel={handleShow}
                    />
                  )}
                  {selectedComponent?.section?.section_type === 'FormComponent' && memberProgram?.program && selectedComponent?.member_program && memberProgram?.program && (
                    <>
                      <ShowForm
                        id={selectedComponent?.section?.component?.id}
                        mode={isSubmitted || isCompleted ? 'readonly' : 'add'}
                        onUpdate={(data) => syncFormData(data)}
                        payloadData={{
                          program: memberProgram.program.id,
                          section: selectedComponent?.section?.id,
                          member_program: selectedComponent.member_program,
                          member: memberProgram.member.id,
                          form: selectedComponent?.section?.component?.id,
                        }}
                        data={formData}
                        memberProgram={selectedComponent.member_program}
                        status={selectedComponent?.section?.status}
                      />
                    </>
                  )}
                  {selectedComponent?.section?.section_type === 'QuizComponent' && memberProgram?.program && selectedComponent?.member_program && memberProgram?.program && (
                    <>
                      <EditQuiz
                        id={selectedComponent?.section?.component?.id}
                        mode={isSubmitted || isCompleted ? 'readonly' : 'add'}
                        onUpdate={(data) => syncFormData(data)}
                        payloadData={{
                          program: memberProgram.program.id,
                          section: selectedComponent?.section?.id,
                          member_program: selectedComponent.member_program,
                          member: memberProgram.member.id,
                          form: selectedComponent?.section?.component?.id,
                        }}
                        // data={formData}
                        memberProgram={selectedComponent.member_program}
                        status={selectedComponent?.section?.status}
                        selectedComponent={member_section?.section?.component}
                      />
                      {/* <QuizPreview
                        mode="trial"
                        id={selectedComponent?.component?.id}
                        selectedComponent={member_section?.section?.component}
                      /> */}
                    </>
                  )}
                  {selectedComponent?.section?.section_type === 'ExternalExamComponent' && memberProgram?.program && selectedComponent?.member_program && memberProgram?.program && (
                    <EditExternalExam
                      id={selectedComponent?.section?.component?.id}
                      mode={isSubmitted || isCompleted ? 'readonly' : 'add'}
                      onUpdate={(data) => syncFormData(data)}
                      payloadData={{
                        program: memberProgram.program.id,
                        section: selectedComponent?.section?.id,
                        member_program: selectedComponent.member_program,
                        member: memberProgram.member.id,
                        form: selectedComponent?.section?.component?.id,
                      }}
                      memberProgram={selectedComponent.member_program}
                      status={selectedComponent?.section?.status}
                      selectedComponent={member_section?.section?.component}
                      data={member_component?.external_exam_custom_fields}
                    />
                  )}

                  {selectedComponent?.section?.section_type === 'EventComponent' && memberProgram?.program && selectedComponent?.member_program && memberProgram?.program && (
                    <EditEvent
                      id={selectedComponent?.section?.component?.id}
                      mode={isSubmitted || isCompleted ? 'readonly' : 'add'}
                      onUpdate={(data) => syncFormData(data)}
                      payloadData={{
                        program: memberProgram.program.id,
                        section: selectedComponent?.section?.id,
                        member_program: selectedComponent.member_program,
                        member: memberProgram.member.id,
                        form: selectedComponent?.section?.component?.id,
                      }}
                      memberProgram={selectedComponent.member_program}
                      status={selectedComponent?.section?.status}
                      selectedComponent={member_section?.section?.component}
                      component_status={member_section?.status}
                      memberEventStatus={memberEventStatus}
                      setMemberEventStatus={setMemberEventStatus}
                    />
                  )}
                  {selectedComponent?.section?.section_type === 'ReviewComponent' && (
                    <div className="activities-block">
                      <div className="activities-detail-header justify-content-between">
                        <h4>Reviews &#40;{memberReviews.length}&#41;</h4>
                        {isValidateAddReviewBtn() === true && status !== 'Completed' && (
                          <Button
                            variant="outline"
                            className="dark"
                            onClick={handleAddReview}
                          >
                            {` Add ${translate(taxonomy)('review')}`}
                          </Button>
                        )}
                      </div>
                      {loadMemberReview ? (
                        <div className="spinner-middle">
                          <Spinner animation="border" />
                        </div>
                      ) : memberReviews && memberReviews.length > 0 ? (
                        memberReviews.map((review, i) => (
                          <ReviewDetailList
                            key={i}
                            reviewId={review.id}
                            title={review.subject}
                            location={review.location}
                            name={review.review_by.first_name + ' ' + review.review_by.last_name}
                            reviewedId={review.review_by.id}
                            schedule={formatDate(review.createdAt)}
                            viewButton="View"
                            onEditReview={() => editReview(review.id)}
                            onViewReview={() => viewReview(review.id)}
                            onDeleteReview={() => deleteReview(review.id)}
                            status={status}

                            // status={review.is_approved}
                          />
                        ))
                      ) : (
                        <DataNotFound title={`${translate(taxonomy)('review')}`} />
                      )}
                    </div>
                  )}
                  <div className="program-list-editor">
                    {observation_by && observation_at && (
                      <span className="program-list-update-text">
                        Last Update:
                        <strong>
                          {formatDate(observation_at)}
                          {observation_by?.first_name && observation_by?.last_name && ' By ' + observation_by?.first_name + ' ' + observation_by?.last_name}
                        </strong>
                      </span>
                    )}
                  </div>
                  {/*  selectedComponent?.section?.section_type !== 'QuizComponent' && */}
                  {selectedComponent?.section?.section_type !== 'ReviewComponent' &&
                    selectedComponent?.section?.section_type !== 'FormComponent' &&
                    selectedComponent?.section?.section_type !== 'QuizComponent' &&
                    selectedComponent?.section?.section_type !== 'ExternalExamComponent' &&
                    selectedComponent?.section?.section_type !== 'EventComponent' && (
                      <>
                        {(!can_manage_apprentices && !isLearner) || isSubmitted === true || status === 'ReSubmission' || isCompleted === true ? (
                          <div className="observation-block observation-pointer-block observation-read-only">
                            <h5 className="small-text-title">Observation</h5>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <div dangerouslySetInnerHTML={{ __html: observation }} />
                            </Form.Group>
                          </div>
                        ) : (
                          <div className="observation-block observation-pointer-block bg-transparent">
                            <h5 className="small-text-title">Observation</h5>
                            {observation ? (
                              <>
                                <TextEditor
                                  onChange={observationHandler}
                                  readOnly={(!can_manage_apprentices && !isLearner) || isSubmitted === true || isCompleted === true}
                                  value={observation ? observation : '<p>&nbsp;</p>'}
                                />
                              </>
                            ) : (
                              <TextEditor
                                onChange={observationHandler}
                                readOnly={(!can_manage_apprentices && !isLearner) || isSubmitted === true || isCompleted === true}
                                value={'<p>&nbsp;</p>'}
                              />
                            )}
                          </div>
                        )}
                      </>
                    )}
                  {evidences && evidences.length > 0 && (
                    <div className="program-list-observation">
                      <h3>Observation Evidences</h3>
                      {evidences.map((item) => (
                        <OberservationEvidence
                          link={item.url}
                          onDelete={(data) => {
                            setEvidenceToRemove(data);
                            setConfirmBox({ ...confirmBox, name: 'evidence', question: 'Are you sure you want to remove this Evidence ?' });
                            dispatch(setConfirmBoxStatus(true));
                            setUpdateStatus(true);
                          }}
                          title={item?.i_item_name || item.name}
                          data={item}
                          key={item._id}
                          type={item.type}
                          status={selectedComponent?.member_section?.status ? selectedComponent?.member_section?.status : selectedComponent?.status}
                          viewBtn
                          removeBtn={isIQV || isEQV ? false : true}
                          onShowModel={() => handleShow(item)}
                          downloadBtn={() => {
                            downloadFile(item);
                          }}
                        />
                      ))}
                    </div>
                  )}
                  {selectedComponent?.section?.section_type === 'UnitComponent' && (
                    <div className="p-2">
                      {!videoEvidences?.length && (isLearner || can_manage_apprentices) && !isSubmitted && !isCompleted && (
                        <Card.Link
                          href="#"
                          onClick={handleOpenAddVideo}
                        >
                          {addIcon}
                          Add Video
                        </Card.Link>
                      )}
                      {(showVideoOffcanvas || videoEvidences?.length > 0) && (
                        <AddVideoEvidence
                          member_section_id={selectedComponent?.id}
                          onSave={(video, newTag) => {
                            video && handleSaveVideoEvidence(video);
                            newTag && handleUpdateNewTag(newTag);
                          }}
                          oldVideo={videoEvidences}
                          removeVideo={(data) => {
                            setEvidenceVideoToRemove(data);
                            setConfirmBox({ ...confirmBox, name: 'video-evidence', question: 'Are you sure you want to remove this Video ?' });
                            dispatch(setConfirmBoxStatus(true));
                            setUpdateStatus(true);
                          }}
                          canAccessAll={(isLearner || can_manage_apprentices) && !isSubmitted && !isCompleted}
                        />
                      )}
                    </div>
                  )}
                  <div className="program-detial-footer-wrap justify-content-end">
                    {(status === 'In Progress' || status === 'Not Started') && isValidForEdit && selectedComponent?.section?.section_type !== 'ReviewComponent' && (
                      <div className="program-button-wrap">
                        {!['EventComponent', 'ExternalExamComponent'].includes(selectedComponent?.section?.section_type) && (
                          <Button
                            variant="outline"
                            className="dark"
                            onClick={saveAsDraft}
                          >
                            {saveAsDraftLoder && (
                              <Spinner
                                animation="border"
                                size="sm"
                              />
                            )}
                            Save as Draft
                          </Button>
                        )}
                        <Button
                          variant="primary"
                          onClick={onSubmitClick}
                        >
                          Save &amp; Submit
                        </Button>
                      </div>
                    )}
                  </div>
                  {selectedComponent && selectedComponent?.section?.section_type !== 'ReviewComponent' && <Feedback onUpdateSectionOnly={(data) => onChange(data)} />}
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      }
      {selectedComponent && (
        <AddTargetSelectedOffCanvas
          name=""
          show={addTargetShow}
          onClose={() => setAddTargetShow(false)}
          displayMsg={(msg) => displayToaster(msg)}
          component_type={member_component?.component_type}
        />
      )}
      {showReviewOffcanvas && (
        <ViewReviewOffCanvas
          // onChange={(data) => Log.info('onchange:', data)}
          data={selectedReview}
          show={showReviewOffcanvas}
          onClose={() => setShowReviewOffcanvas(false)}
          // column={column}
        />
      )}
      {editReviewShow && (
        <EditReviewOffCanvas
          member_program={selectedComponent?.member_section?.member_program}
          member_component={selectedComponent?.member_component?._id}
          data={selectedReview}
          show={editReviewShow}
          reviewId={activeReviewId}
          onClose={() => {
            setEditReviewShow(false);
            getMemberReview();
          }}
        />
      )}
      {confirmReviewBoxStatus && (
        <ConfirmBox
          show={confirmReviewBoxStatus}
          question={`Are you sure you want to delete this ${translate(taxonomy)('review')}`}
          cancelText="Cancel"
          okText="Delete"
          onHide={() => setConfirmReviewBoxStatus(false)}
          onOk={onReviewDelete}
          // loading={deleteLoad}
        />
      )}
      <ViewModel
        onClose={() => setShow(false)}
        show={show}
        fileType={fileType}
        fileLink={fileLink}
        fileName={fileName}
      />
      <AddNewReviewOffCanvas
        show={addReviewShow}
        onClose={() => {
          setAddReviewShow(false);
          getMemberReview();
        }}
        review_id={selectedComponent?.member_component?.component?.id}
        member_section_id={selectedComponent?.id}
        member_id={selectedComponent?.member_section?.member}
        member_program={selectedComponent?.member_program}
        member_component={member_component?.id}
        onUpdateSectionOnly={(data) => updateData(data)}
      />
      <AddNewActivityOffCanvas
        show={addActivityShow}
        onClose={() => setAddActivityShow(false)}
        otj_id={member_component?.component?.id}
        member_section_id={member_section?.id}
        activities={computedActivities}
        onSave={activitySaveHandler}
        updateStatus={(res) => handleActivityStatus(res)}
        canCreateNewActivity={canCreateActivity}
        displayAdminActivity={selectedComponent?.section?.component?.can_admin_create_activity}
        isValidRole={!isLearner}
      />

      {programSubmitToggle && (
        <SubmitModal
          hideSaveAsDraft={['ExternalExamComponent', 'EventComponent'].includes(selectedComponent?.section?.section_type)}
          loading={saveAsDraftLoder}
          onSubmit={() => submitHandler()}
          onSaveAsDraft={() => saveAsDraft()}
          show={programSubmitToggle}
          onClose={() => setProgramSubmitToggle(false)}
        />
      )}
      <ToastBox />
      <ConfirmBox
        show={confirmBoxStatus && updateStatus}
        question={confirmBox.question}
        loading={confirmBoxLoading}
        onHide={() => {
          dispatch(setConfirmBoxStatus(false));
          setUpdateStatus(false);
        }}
        onOk={() => okConfirm()}
      />
    </>
  );
}
export default SelectedComponent;
