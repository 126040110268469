import React from 'react';
import { CircleImage } from 'react-myoneui';
import DOMPurify from 'dompurify';
import { useSelector } from 'react-redux';
import { translate } from '../../lib';
const purify = DOMPurify(window);
// Images

const PreviewExternalComponentBlock = (props) => {
  const { id, name, description, cirleImage, onClick, url = '' } = props;
  const taxonomy = useSelector((state) => state.taxonomy.data);
  const containsHTML = (string) => /<\/?[a-z][\s\S]*>/i.test(string);
  const clickHandler = () => {
    if (onClick) {
      onClick({
        id,
        name,
        description,
        image: cirleImage,
        url: url,
      });
    }
  };
  return (
    <div
      className={`information-block information-block-sm-pd bg-ghost-white ${props?.className || ''}`}
      onClick={clickHandler}
    >
      <div className="information-wrap">
        <div className="information-holder">
          {cirleImage && <CircleImage className="circle-wrap ">{cirleImage}</CircleImage>}
          <div className="information-detail">
            {name && <h4>{name}</h4>}
            {containsHTML(description) ? (
              <span
                className="help-text"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            ) : (
              <span className="help-text">{description}</span>
            )}
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
};
export default PreviewExternalComponentBlock;
