import React, { useState, forwardRef, Children, useRef, useEffect } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Button from 'react-myoneui/lib/Button';
import { Row, Col } from 'react-myoneui';
import Spinner from 'react-myoneui/lib/Spinner';
import SecondaryHeaderSearchField from 'react-myoneui/lib/SecondaryHeaderSearchField';
import CourseInformationBlock from '../CourseInformationBlock';
import { getCategories, getSectionBankList } from '../../../api';
import DropdownCollection from '../../../modules/superAdmin/program/DropdownCollection';
import ls from 'localstorage-slim';
import DataNotFound from '../../DataNotFound';
import Log from 'lib/Log';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { translate } from 'lib';
// enable global encryption
ls.config.encrypt = true;
function ChooseExistingUnit(props) {
  const { onSelected, onClose, show, sectionType, categoryList } = props;
  const handleClose = () => onClose();
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [sectionBankList, setUnitList] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageChange, setPageChange] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [category, setCategory] = useState();
  const taxonomy = useSelector((state) => state?.taxonomy?.data);
  const { programId } = useParams();
  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().startsWith(value))}</ul>
      </div>
    );
  });

  const loadMoreHandler = () => {
    setCurrentPage(currentPage + 1);
    setPageChange(true);
    setLoadMore(true);
    setSearch(true);
  };

  const reLoadData = async () => {
    try {
      if (loading === false) {
        if (!pageChange) {
          setLoading(true);
        }
        let payload = {
          currentPage: currentPage,
          search: searchText,
          perPage: 10,
          program: programId,
        };
        if (category) {
          payload = { ...payload, category: category.id };
        }
        const result = await getSectionBankList(payload);
        if (result) {
          const sectionBanks = result?.data?.data?.sectionsList?.map((item) => {
            return {
              id: item.id,
              title: item.title,
              description: item.description,
              categories: item.categories,
              component_count: item?.component_count,
            };
          });

          setUnitList([...sectionBankList, ...sectionBanks]);
          setTotalData(result?.data?.data?.meta.sectionCount);
          setNext(result.data.data.meta.next);
        }
      }
    } catch (err) {
      Log.error(err);
    } finally {
      setLoading(false);
      setSearch(false);
      setLoadMore(false);
    }
  };

  useEffect(() => {
    if (show && search) {
      reLoadData();
    }
  }, [search, show]);

  const setDefaultValues = () => {
    setUnitList([]);
    setTotalData(0);
    setCurrentPage(1);
    setPageChange(false);
  };

  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  useEffect(() => {
    try {
      if (searchText === '') {
        setDefaultValues();
        setSearch(true);
      } else {
        const implementSearch = setTimeout(() => {
          setDefaultValues();
          setSearch(true);
        }, 800);
        return () => clearTimeout(implementSearch);
      }
    } catch (err) {
      Log.error('ChooseExistingUnit/useEffect', err);
    }
  }, [searchText, category]);

  const selectUnit = (data) => {
    if (selectedUnit.map((unit) => unit.id).includes(data.id)) {
      const updatedSelectedUnit = selectedUnit.filter((unit) => unit.id !== data.id);
      setSelectedUnit(updatedSelectedUnit);
    } else {
      setSelectedUnit([...selectedUnit, data]);
    }
  };
  const addToProgram = () => {
    setSearch('');
    onSelected(selectedUnit);
  };

  useEffect(() => {
    setSelectedUnit(props.selectedUnits);
  }, [props.selectedUnits]);

  return (
    <>
      <Offcanvas
        backdropClassName="transparent-backdrop"
        className="canvas-active-border"
        show={props.show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Header
          closeButton
          backButton
          className="pb-0"
        >
          <Offcanvas.Title>
            Choose Existing {translate(taxonomy)(sectionType)}
            <p className="help-text w-75 fs-12">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col
              sm={4}
              className="mb-3"
            >
              <DropdownCollection
                autoComplete={false}
                defaultValue={category}
                items={categoryList}
                label={'Category'}
                onChange={(obj) => setCategory(obj)}
                onClear={() => setCategory()}
              />
            </Col>
            <Col
              sm={8}
              className="mb-3"
            >
              <SecondaryHeaderSearchField
                placeholder={`Search ${translate(taxonomy)(sectionType)}…`}
                value={searchText}
                onChange={onSearchChange}
                onClear={() => setSearchText('')}
              />
            </Col>
          </Row>
          {/* <h4 className="offcanvas-courses-title">
            {translate(taxonomy)('assignment')} <span>({total})</span>
          </h4> */}
          <div className="user-filter-block ps-0 pt-2">
            <h4 className="offcanvas-courses-title">
              {translate(taxonomy)(sectionType)} <span>({totalData})</span>
            </h4>
          </div>
          <div className="offcanvas-scrolling-content">
            <>
              {sectionBankList && sectionBankList.length > 0 ? (
                sectionBankList.map((item) => {
                  return (
                    <CourseInformationBlock
                      id={item.id}
                      key={item.id}
                      className={`mb-3 information-border information-overflow-text ${selectedUnit.map((unit) => unit.id).includes(item.id) && 'active'}`}
                      name={item.title}
                      // description={`${item?.component_count}`}
                      description={`${item?.component_count}  ${translate(taxonomy)('component')}`}
                      onClick={(data) => selectUnit({ ...data, categories: item.categories })}
                    />
                  );
                })
              ) : loading ? (
                <div className="pt-2 d-flex justify-content-center align-items-center ">
                  <Spinner
                    animation="border"
                    className="spinner-border"
                  />
                </div>
              ) : (
                <DataNotFound />
              )}
            </>

            {sectionBankList && next && sectionBankList.length > 0 && totalData && totalData > 0 && (
              <div className="p-2">
                {next && sectionBankList.length && (
                  <button
                    className="load-more-btn"
                    onClick={loadMoreHandler}
                  >
                    {loadMore && (
                      <>
                        <Spinner
                          animation="border"
                          size="sm"
                        />{' '}
                        &nbsp;
                      </>
                    )}
                    Load More
                  </button>
                )}
              </div>
            )}
          </div>
        </Offcanvas.Body>
        <Offcanvas.Footer className="border-top">
          <Button
            variant="default"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={addToProgram}
          >
            Add to Program
          </Button>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default ChooseExistingUnit;
