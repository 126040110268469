import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Card from 'react-myoneui/lib/Card';
import Button from 'react-myoneui/lib/Button';
import { Form } from 'react-myoneui';

import 'react-quill/dist/quill.snow.css';
import TextEditor from '../../../components/TextEditor';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Spinner from 'react-myoneui/lib/Spinner';
import { useParams } from 'react-router-dom';
import { setFeedbacks, storeMemberProgram } from '../../../redux';
import { createFeedback, getAllFeedBacks } from '../../../api';
import DropdownFeedback from '../../../components/DropdownFeedback';
import ls from 'localstorage-slim';
import { startOfYesterday } from 'date-fns';
import Log from '../../../lib/Log';
import { getMemberProgramAssessors } from 'api/assessor/learner';
import { getMemberProgramIqvs } from 'api/eqv';
import { getAssignedApprenticeTutors } from 'api/tutor/learner';
import { decryptData } from 'lib';
ls.config.encrypt = true;
function AddFeedbackOffCanvas(props) {
  const { member_program_id, programId, id } = useParams();
  const { show, onClose, onSave, isIQV, isEQV, onSubmit = () => {} } = props;
  const selectedComponent = useSelector((state) => state.sections.active_component);
  const status = selectedComponent?.member_section?.status ? selectedComponent?.member_section?.status : selectedComponent?.status;
  const learnerInfo = useSelector((state) => state?.memberProgram?.member);
  const member_section_id = useSelector((state) =>
    state?.sections?.active_component?.member_section?.id ? state?.sections?.active_component?.member_section?.id : state?.sections?.active_component?.id,
  );

  const [role, setRole] = useState(null);
  const dispatch = useDispatch();
  const orgId = ls.get('orgId');
  const roles = JSON.parse(ls.get('roles'));
  const memberProgram = useSelector((state) => state.memberProgram);
  const activeRole = JSON.parse(ls.get('activeRole'));
  const { can_manage_apprentices } = JSON.parse(JSON.stringify(activeRole?.privileges));
  const isSubmitted = selectedComponent?.member_section?.status === 'Submitted';
  const callSaveObservation = JSON.parse(ls.get('activeRole'))?.identifier === 'apprentice' && selectedComponent?.status === 'ReSubmission';
  const [loading, setLoading] = useState(false);
  const [assessors, setAssessors] = useState([]);
  const [tutors, setTutors] = useState([]);
  const [iqvs, setIqvs] = useState([]);
  const [showDescBox, setShowDescBox] = useState(false);
  const [feedBackToEveryone, setFeedbackToEveryOne] = useState(false);

  const [state, setState] = useState({
    description: '',
    created_to: null,
    saveLoading: false,
    isFormValid: false,
    mark_for_resubmission: false,
    userList: [],
    list: [],
    errMsg: '',
  });

  const [validation, setValidation] = useState({
    description: {
      status: null,
      msg: '',
    },
  });
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const handleClose = () => {
    onClose();
    setState({ ...state, description: '' });
    setShowDescBox(false);
  };

  const addFeedback = async () => {
    try {
      var roles = JSON.parse(ls.get('roles'));
      var activeRole = JSON.parse(ls.get('activeRole'));
      var created_by = {
        member: ls.get('userid'),
        role: activeRole.id,
      };
      let payload = {
        member_program: member_program_id || programId,
        member_section_id: member_section_id,
        feedback: state.description,
        created_by,
      };
      if (status === 'ReSubmission' && role === 'apprentice') {
        payload.mark_for_submission = true;
      }
      if (role !== 'apprentice') {
        let learnerRoleId = roles.find((item) => item.identifier === 'apprentice');
        let assessorRoleId = roles.find((item) => item.identifier === 'assessor');
        let iqvRoleId = roles.find((item) => item.identifier === 'iqv');

        payload.mark_for_resubmission = state.mark_for_resubmission;
        let created_to = state.created_to.map((item) => {
          let obj = {};
          obj.member = item.id;
          obj.role = item.isIqv ? iqvRoleId.id : item.isAssesssor ? assessorRoleId.id : learnerRoleId.id;
          return obj;
        });

        // for feedback to everyone case send to all associated tutor as well
        // if (feedBackToEveryone) {
        //   let tutorRoleId = roles.find((item) => item.identifier === 'tutor');

        //   const create_to_tutor = tutors.map((tutor) => {
        //     let obj = {};
        //     obj.member = tutor.id;
        //     obj.role = tutorRoleId.id;
        //     return obj;
        //   });

        //   if (isIQV) {
        //     created_to = [...created_to, ...create_to_tutor];
        //   }
        // }

        payload.created_to = created_to;
      }
      setState({ ...state, saveLoading: true });
      callSaveObservation && (await onSubmit());
      let response = await createFeedback(payload);
      if (response) {
        setShowDescBox(false);
        onSave(response.data);
        setState({ ...state, errMsg: '', description: '', saveLoading: false });
        fetchAllFeedbacks();
        /* Update score and progress on member program on redux as well as in localstorage */
        const { score, submitted_progress } = { ...response.data.member_program };
        const cloneMemberProgram = JSON.parse(JSON.stringify(memberProgram));
        cloneMemberProgram.score = score;
        cloneMemberProgram.submitted_progress = submitted_progress;
        dispatch(storeMemberProgram(cloneMemberProgram));
      }
    } catch (error) {
      Log.error(error);
      setState({ ...state, errMsg: error?.data?.message, saveLoading: false });
    }
  };

  const descriptionHandler = (data) => {
    setState({ ...state, description: data });
    if (data.length === 8) {
      setValidation({ ...validation, description: { status: false, msg: `${role === 'apprentice' ? 'Observation' : 'Feedback'} is required to fill.` } });
    } else {
      setValidation({ ...validation, description: { status: true, msg: '' } });
    }
  };
  const userSelectHandler = (data, isForEveryone) => {
    let cloneState = JSON.parse(JSON.stringify(state));
    cloneState.created_to = data;
    setState(cloneState);
    setFeedbackToEveryOne(!!isForEveryone);
  };
  const resubmissionHandler = (e) => {
    let cloneState = JSON.parse(JSON.stringify(state));
    cloneState.mark_for_resubmission = e.target.checked;
    setState(cloneState);
  };
  const cloneObj = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  };

  useEffect(() => {
    if (validation.description.status) {
      setState({
        ...state,
        isFormValid: true,
      });
    } else {
      setState({
        ...state,
        isFormValid: false,
      });
    }
  }, [validation]);

  const fetchAllFeedbacks = async () => {
    try {
      let result = await getAllFeedBacks({ member_program: programId });
      dispatch(setFeedbacks({ ...result.data.data }));
    } catch (error) {
      Log.error(error);
    }
  };

  const fetchAssessors = async (payloadData) => {
    try {
      setLoading(true);
      const payload = {
        memberProgram: programId,
      };

      const { data } = await getMemberProgramAssessors(payload);

      if (data) {
        const members = data?.data?.members.map((member) => {
          return {
            id: member?.id,
            name: member?.first_name + ' ' + member?.last_name,
            roleId: member?.role,
            image: member?.profile_image,
            isAssesssor: true,
          };
        });

        setAssessors(members);
      }
    } catch (err) {
      Log.error('Error >> ', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchTutors = async () => {
    try {
      setLoading(true);
      const tutorRoleId = roles && roles.length > 0 ? roles.filter((role) => role.identifier === 'tutor')[0]?.id : null;
      const payload = {
        member: decryptData(id),
        role: tutorRoleId,
      };

      const { data } = await getAssignedApprenticeTutors(payload);

      if (data) {
        const members = data?.data?.members.map((member) => {
          return {
            id: member?.id,
            name: member?.first_name + ' ' + member?.last_name,
            roleId: member?.role,
            image: member?.profile_image,
            isTutor: true,
          };
        });

        setTutors(members);
      }
    } catch (err) {
      Log.error('Error >> ', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchIqvs = async (payloadData) => {
    try {
      setLoading(true);
      const payload = {
        memberProgram: programId,
      };

      const { data } = await getMemberProgramIqvs(payload);

      if (data) {
        const members = data?.data?.members.map((member) => {
          return {
            id: member?.id,
            name: member?.first_name + ' ' + member?.last_name,
            roleId: member?.role,
            image: member?.profile_image,
            isIqv: true,
          };
        });

        setIqvs(members);
      }
    } catch (err) {
      Log.error('Error >> ', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let obj = {
      name: learnerInfo?.first_name + ' ' + learnerInfo?.last_name,
      id: learnerInfo?.id,
      isLearner: true,
      image: learnerInfo?.profile_image,
    };
    let cloneState = cloneObj(state);
    cloneState.created_to = [{ ...obj }];
    cloneState.userList = [{ ...obj }];

    setState(cloneState);
    var activeRole = JSON.parse(ls.get('activeRole'));
    setRole(activeRole.identifier);
    isIQV && fetchAssessors();
    isIQV && fetchTutors();
    isEQV && fetchIqvs();
  }, []);
  useEffect(() => {
    setShowDescBox(show);
  }, [show]);

  const defaultFeedback = isEQV ? iqvs[0] : isIQV ? assessors[0] : state?.userList[0];

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Header
          closeButton
          backButton="Close"
          icon="close"
          className="pb-0"
        >
          <Offcanvas.Title>{role === 'apprentice' ? 'Resubmit Observation' : 'Add Feedback'}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p className="help-text fs-12 mt-0">Provide personalized feedback to students enrolled in the programme to help them improve and track their progress.</p>
          {role !== 'apprentice' && state?.userList && state?.userList?.length > 0 && (
            <DropdownFeedback
              className=" user-max-content-dropdown mb-4"
              defaultValue={defaultFeedback}
              items={[...state?.userList, ...assessors, ...iqvs]}
              label={'Choose whom to feedback'}
              onChange={userSelectHandler}
              isIQV={isIQV}
              isEQV={isEQV}
            />
          )}
          {showDescBox && (
            <Card className="react-quill-editor mb-4">
              <TextEditor
                placeholder={role === 'apprentice' ? 'Observation' : 'Feedback'}
                className="myeditor"
                theme="snow"
                value={state.description}
                onChange={descriptionHandler}
              />
            </Card>
          )}
          {validation.description.status === false && (
            <div>
              <span
                className="invalid-feedback mb-4"
                style={{ display: 'block' }}
              >
                {validation.description.msg}
              </span>
            </div>
          )}
          {state.errMsg && (
            <div>
              <span
                className="invalid-feedback mb-4"
                style={{ display: 'block' }}
              >
                {state.errMsg}
              </span>
            </div>
          )}
          {can_manage_apprentices && status === 'Submitted' && role !== 'apprentice' && (
            <div className="form-switch-wrap">
              <label className="form-switch">
                <input
                  type="checkbox"
                  checked={state.mark_for_resubmission}
                  onChange={resubmissionHandler}
                />
                <span className="form-switch-slider" />
              </label>
              <Form.Check.Label className="pe-none">Mark for Resubmission</Form.Check.Label>
            </div>
          )}
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <Button
            variant="default"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={state.saveLoading || !state.isFormValid}
            variant="primary"
            onClick={addFeedback}
          >
            {state.saveLoading ? (
              <>
                <div className="spinner-align-center">
                  <Spinner
                    animation="border"
                    size="sm"
                  />{' '}
                  Send
                </div>
              </>
            ) : (
              <>Send</>
            )}
          </Button>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}
export default React.memo(AddFeedbackOffCanvas);
