import { memo, useRef, useEffect, useState } from 'react';
import { Row, Col, Card, Spinner } from 'react-myoneui';
import { cameraIcon, explainIcon, externalLink } from 'constants/icons';
import Log from 'lib/Log';
import TextEditor from 'components/TextEditor';
import { fileUploader } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { storeFormData } from '../../../redux/FormDataSlice';
import DOMPurify from 'dompurify';
import ViewModel from 'modules/learner/components/ViewModal';

function InputImageCommentBlock({ data, mode }) {
  const state = useSelector((state) => state.formData.data.find((item) => item.id === data.id));
  const formData = useSelector((state) => state.formData.data);
  const dispatch = useDispatch();
  const purify = DOMPurify(window);
  const [showEditor, setShowEditor] = useState(false);
  useEffect(() => {
    setShowEditor(true);
  }, [state]);

  const valueControl = (value) => {
    try {
      if (mode !== 'preview') {
        let cloneFormData = JSON.parse(JSON.stringify(formData));
        let obj = cloneFormData.find((item) => item.id === data.id);
        let index = cloneFormData.indexOf(obj);
        obj.value = value;
        cloneFormData[index] = obj;
        dispatch(storeFormData(cloneFormData));
      }
    } catch (error) {
      Log.error(error);
    }
  };
  return (
    <>
      <h3 className="evidenceLabel">{state.label}</h3>

      <Row className="evidence-row">
        <Col
          className="py-2"
          md={5}
          xl={4}
        >
          <Card className="evidence-thumbnail border-none">
            {state?.profile_img_url ? (
              <Card.Img
                src={state?.profile_img_url}
                className="rounded w-100 form-image-desc-block"
                alt="Course Image"
              />
            ) : (
              <Card.Body className="img-wrap">
                {cameraIcon}
                <span className="txt">200x200</span>
              </Card.Body>
            )}
          </Card>
        </Col>
        <Col
          className="py-2"
          md={7}
          xl={8}
        >
          {mode == 'readonly' ? (
            <div
              className="observation-block observation-pointer-block observation-read-only"
              style={{ marginBottom: '0px', maxHeight: '100%' }}
            >
              <h5 className="small-text-title">{state?.label}</h5>
              <div dangerouslySetInnerHTML={{ __html: purify.sanitize(state?.value ? state.value : '<p>&nbsp;</p>') }} />
            </div>
          ) : (
            <Card>
              {showEditor && (
                <TextEditor
                  className={mode === 'preview' ? 'disabled' : ''}
                  placeholder={state.placeholder}
                  value={state.value}
                  onChange={valueControl}
                />
              )}
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
}
export default memo(InputImageCommentBlock);
