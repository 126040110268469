import React from 'react';
import { CircleImage } from 'react-myoneui';
import DOMPurify from 'dompurify';
import { useSelector } from 'react-redux';
import { translate } from '../../lib';
const purify = DOMPurify(window);
// Images

const CourseInformationBlock = (props) => {
  const { id, name, description, cirleImage, onClick, url = '' } = props;
  const taxonomy = useSelector((state) => state.taxonomy.data);
  const containsHTML = (string) => /<\/?[a-z][\s\S]*>/i.test(string);
  const clickHandler = () => {
    if (onClick) {
      onClick({
        id,
        name,
        description,
        image: cirleImage,
        url: url,
      });
    }
  };
  return (
    <div
      className={`information-block information-block-sm-pd bg-ghost-white ${props?.className || ''}`}
      onClick={clickHandler}
    >
      <div className="information-wrap">
        <div className="information-holder">
          {cirleImage && (
            <CircleImage className="circle-wrap flat-img-wrap">
              <img src={cirleImage} />
            </CircleImage>
          )}
          <div className="information-detail">
            {name && <h4>{name}</h4>}
            {/* {description && <span dangerouslySetInnerHTML={{ __html: purify.sanitize(description) }}></span>} */}
            {/* <span>{description}</span> */}
            {containsHTML(description) ? (
              <span dangerouslySetInnerHTML={{ __html: description }} />
            ) : (
              <span>{description}</span> // Render plain text if it's a string
            )}
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
};
export default CourseInformationBlock;
