import { memo, useRef } from 'react';
import Button from 'react-myoneui/lib/Button';
import { Col, Row } from 'react-myoneui';
import { videoPlayIcon } from 'constants/icons';
import OberservationEvidence from 'components/learners/OberservationEvidence';
import Log from 'lib/Log';
import { addLog } from 'api/log';
import { covertSecsToTime } from 'lib/common';
const ResourceContent = ({ section, onShowModel }) => {
  const videoRef = useRef(null);
  const component = section.component ? section.component : section;
  async function downloadResourceFile(myfile) {
    try {
      await addLog({
        model: section?.id,
        model_name: 'MemberSection',
        from: 'resource_attach_file_download',
      });
      let url = myfile?.link ? myfile.link : myfile?.url;
      const aElement = document.createElement('a');
      aElement.setAttribute('download', myfile.name);
      aElement.href = url;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      Log.error(error);
    }
  }
  const applyResourceLinkLog = async () => {
    try {
      await addLog({
        model: section?.id,
        model_name: 'MemberSection',
        from: 'resource_link',
      });
    } catch (error) {
      Log.error(error);
    }
  };
  const addVideoLog = async () => {
    try {
      await addLog({
        model: section?.id,
        model_name: 'MemberSection',
        from: 'play_pause_resource_vedio',
      });
    } catch (error) {
      Log.error(error);
    }
  };
  const viewFile = async (item) => {
    try {
      await addLog({
        model: section?.id,
        model_name: 'MemberSection',
        from: 'resource_attach_file',
      });
      onShowModel(item);
    } catch (err) {
      Log.error(err);
    }
  };

  // const playVideoTimeLine = (e, time) => {
  //   try {
  //     e.preventDefault();
  //     if (time > videoRef.current.duration) {
  //       videoRef.current.currentTime = videoRef.current.duration;
  //     } else {
  //       videoRef.current.currentTime = time;
  //       videoRef.current.play();
  //     }
  //   } catch (error) {
  //     Log.error('Error on playVideoTimeLine:', error);
  //   }
  // };

  const playVideoTimeLine = (e, time) => {
    try {
      e.preventDefault();
      if (videoRef.current) {
        videoRef.current.pause();

        videoRef.current.currentTime = time; // Update to the new time

        // Delay playback slightly to ensure currentTime is updated
        setTimeout(() => {
          videoRef.current.play().catch((error) => {
            Log.error('Error attempting to play video:', error);
          });
        }, 50); // Adjust this delay
      }
    } catch (error) {
      Log.error('Error on playVideoTimeLine:', error);
    }
  };

  return (
    <div className="feature-video-block">
      <Row>
        {component?.videos[0]?.url?.length > 0 && component?.videos[0].url.length > 0 && (
          <Col lg={12}>
            <h4>Featured Video</h4>

            <div className="feature-video-wrap feature-video-size">
              {component?.videos[0].url === '' ? (
                <Button variant="text-icon">{videoPlayIcon}</Button>
              ) : (
                <video
                  height="135"
                  controls={true}
                  ref={videoRef}
                  onPlay={() => addVideoLog()}
                >
                  <source
                    src={component?.videos[0].url}
                    type="video/mp4"
                  />
                </video>
              )}
            </div>
          </Col>
        )}
        {component?.videos[0]?.tags?.length > 0 && (
          <Col lg={12}>
            <h4>Video Tags</h4>
            <ul className="styled-list">
              {component?.videos[0].tags.map((item, index) => {
                return (
                  <li key={index}>
                    <a
                      href="#"
                      onClick={(e) => playVideoTimeLine(e, item.time)}
                    >
                      {covertSecsToTime(item.time)}
                    </a>
                    &nbsp;- {item.tag}
                  </li>
                );
              })}
            </ul>
          </Col>
        )}

        {component?.resources && component?.resources?.length > 0 && (
          <Col lg={12}>
            <h4>Resource Links</h4>
            <ul>
              {component?.resources.map((resource) => {
                return (
                  <li
                    className="mb-2"
                    key={resource?._id}
                  >
                    <a
                      href={resource?.link}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: 'none' }}
                      onClick={applyResourceLinkLog}
                    >
                      {resource?.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </Col>
        )}
        {component?.files?.length > 0 && (
          <Col lg={12}>
            <h4>Resource Files </h4>
            <div className="observation-scrollbar">
              {component?.files.map((file) => {
                return (
                  <OberservationEvidence
                    onShowModel={(item) => viewFile(item)}
                    link={file?.link}
                    key={file?._id}
                    title={file?.name}
                    type={file?.link ? file.link.slice(-3) : ''}
                    totalMb={file?.size}
                    removeBtn={false}
                    downloadBtn={() => {
                      downloadResourceFile(file);
                    }}
                  />
                );
              })}
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default memo(ResourceContent);
