import * as React from 'react';
import { useState, useEffect } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Card from 'react-myoneui/lib/Card';
import Button from 'react-myoneui/lib/Button';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import { Row, Col, Spinner } from 'react-myoneui';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { eventCalendarIcon } from '../../../constants/icons';
import DropdownCollection from '../../../components/DropdownCollection';
import TextEditor from '../../../components/TextEditor';
import DateTimePicker from 'react-datetime-picker';
import TimePicker from 'react-time-picker';
import { addHours } from '../../../api/offTheJob';
import { formatToUTC } from '../../../utils/formatTime';
import Log from '../../../lib/Log';
import { useSelector } from 'react-redux';
function AddHoursOffCanvas({ activeActivity, onClose, show, addedNewHour, canAddManualHours }) {
  const handleClose = () => {
    setStartDate('');
    setStartTime({ id: 0, name: '00:00' });
    setEndDate('');
    setEndTime({ id: 0, name: '00:00' });
    setIsManualEntry(false);
    setTotalHours(0);
    setDescription('');
    onClose();
  };
  const offCanvasKeyDown = (e) => e.stopPropagation();

  const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image'];
  const modules = {
    toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }], ['link', 'image'], [{ align: [] }]], // remove formatting button
  };

  const chooseTime = [
    { id: 0, name: '00: 00' },
    { id: 1, name: '12: 45' },
    { id: 2, name: '5: 00' },
    { id: 3, name: '4: 30' },
  ];

  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState({ id: 0, name: '00:00' });
  const [endDate, setEndDate] = useState('');
  const [endTime, setEndTime] = useState({
    id: 0,
    name: '00:00',
  });
  const [invalidDateRange, setInvalidDateRange] = useState(false);
  const [startActiveClass, setStartActiveClass] = useState(false);
  const [endActiveClass, setEndActiveClass] = useState(false);
  const [description, setDescription] = useState('');
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [totalHours, setTotalHours] = useState(0);
  const [formattedStartTime, setFormattedStartTime] = useState('');
  const [formattedEndTime, setFormattedEndTime] = useState('');
  const invalidNumbers = ['e', 'E', '+', '-'];
  const [load, setLoad] = useState(false);
  const selectedComponent = useSelector((state) => state.sections.active_component);
  const member_section_id = selectedComponent?.member_section ? selectedComponent?.member_section?.id : selectedComponent?.id;

  const computeMiliseconds = (time) => {
    const { name } = time;
    return Number(name?.split(':')[0]) * 3600000 + Number(name?.split(':')[1]) * 60000;
  };

  useEffect(() => {
    const startTimeCalc = new Date(new Date(formatToUTC(startDate)).getTime() + computeMiliseconds(startTime));
    setFormattedStartTime(startTimeCalc);
    const endTimeCalc = new Date(new Date(formatToUTC(endDate)).getTime() + computeMiliseconds(endTime));
    setFormattedEndTime(endTimeCalc);
    setInvalidDateRange(endTimeCalc - startTimeCalc < 0);
    if (!isManualEntry && startDate && startTime && endDate && endTime) {
      const calculatedTotalHours = ((endTimeCalc - startTimeCalc) / 3600000).toFixed(2);
      setTotalHours(calculatedTotalHours);
    }
  }, [startDate, startTime, endDate, endTime, isManualEntry]);

  const addNewHour = async () => {
    const payload = {
      member_section_id,
      member_activity: activeActivity,
      start_time: formattedStartTime,
      end_time: formattedEndTime,
      is_manual_entry: isManualEntry,
      total_hours: Number(totalHours),
      description,
    };
    try {
      setLoad(true);
      const { data } = await addHours(payload);
      addedNewHour(data.data);
      setLoad(false);
    } catch (err) {
      Log.info('Error >> ', err);
    } finally {
      handleClose();
    }
  };

  const formatTotalHour = (totalHour) => {
    const isDecimal = totalHour % 1 !== 0;
    const countDecimal = isDecimal && totalHour.toString().split('.')[1].length;
    if (!isDecimal || countDecimal === 1) {
      setTotalHours(totalHour);
    } else {
      setTotalHours(totalHour.toFixed(2));
    }
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={() => handleClose(false)}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Header
          closeButton
          backButton
          className="pb-0"
        >
          <Offcanvas.Title>
            Add <br /> Hours
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p className="help-text w-75 fs-12 mt-0">Log the start and end times of the activity, or manually enter the total hours spent.</p>
          <Row>
            <Col
              sm={6}
              className="floating-icon-wrap"
            >
              {/* <FloatingLabel className="mb-4" controlId="startDate" label="Start Date">
                <Form.Control type="text" placeholder="Start Date" />
                <span className="floating-icon">{eventCalendarIcon}</span>
              </FloatingLabel> */}
              <FloatingLabel
                className="mb-4 target-end-date-label"
                controlId="targetSartEndDate"
                label="Start Date"
              >
                <Form.Control
                  type="text"
                  placeholder="Start Date"
                />
                <DateTimePicker
                  className={`remove-time-wrap ${startActiveClass}`}
                  clearIcon={null}
                  placeholder="StartDate"
                  value={startDate}
                  onChange={(value) => {
                    setStartDate(value);
                  }}
                  onBlur={() => {
                    setStartActiveClass('');
                  }}
                  onFocus={() => {
                    setStartActiveClass('target-date-focus');
                  }}
                  locale="en-US"
                />
              </FloatingLabel>
              {/* {JSON.stringify(startTime)} */}
            </Col>
            <Col
              sm={6}
              className="floating-icon-wrap mb-4"
            >
              {/* <FloatingLabel className="mb-4" controlId="startDate" label="Start Date">
                <Form.Control type="text" placeholder="Start Date" />
                <span className="floating-icon">{eventCalendarIcon}</span>
              </FloatingLabel> */}
              <FloatingLabel
                className=" target-end-date-label"
                controlId="targetSartEndDate"
                label="Start Time"
              >
                <Form.Control
                  type="text"
                  placeholder="Start Time"
                />

                <TimePicker
                  className={`remove-time-wrap  remove-time-border  ${startActiveClass}`}
                  placeholder="StartTime"
                  value={startTime.name}
                  onChange={(value) => {
                    setStartTime({
                      id: 0,
                      name: value,
                    });
                  }}
                  onBlur={() => {
                    setStartActiveClass('');
                  }}
                  onFocus={() => {
                    setStartActiveClass('target-date-focus');
                  }}
                />
              </FloatingLabel>

              {/* {JSON.stringify(startTime)} */}
            </Col>
            {/* <Col lg={4}>
              <DropdownCollection
                defaultValue={startTime}
                items={chooseTime}
                label={'Time'}
                onChange={(obj) => {
                  setStartTime(obj);
                  console.log(obj);
                }}
                disable={isManualEntry}
              />
            </Col> */}
          </Row>
          <Row>
            <Col
              sm={6}
              className="floating-icon-wrap mb-4"
            >
              {/* <FloatingLabel className="mb-4" controlId="endDate" label="End Date">
                <Form.Control type="text" placeholder="End Date" />
                <span className="floating-icon">{eventCalendarIcon}</span>
              </FloatingLabel> */}
              <FloatingLabel
                className=" target-end-date-label"
                controlId="targetSartEndDate"
                label="End Date"
              >
                <Form.Control
                  type="text"
                  placeholder="End Date"
                />
                <DateTimePicker
                  className={`remove-time-wrap ${endActiveClass}`}
                  clearIcon={null}
                  placeholder="End Date"
                  value={endDate}
                  label="End Date"
                  onChange={(value) => {
                    setEndDate(value);
                  }}
                  onBlur={() => {
                    setEndActiveClass('');
                  }}
                  onFocus={() => {
                    setEndActiveClass('target-date-focus');
                  }}
                  locale="en-US"
                />
              </FloatingLabel>
              {isManualEntry && invalidDateRange && <div className="text-danger mt-2 invalid-picker-text">End Date must be greater than Start Date</div>}
            </Col>
            <Col
              sm={6}
              className="floating-icon-wrap"
            >
              {/* <FloatingLabel className="mb-4" controlId="startDate" label="Start Date">
                <Form.Control type="text" placeholder="Start Date" />
                <span className="floating-icon">{eventCalendarIcon}</span>
              </FloatingLabel> */}
              <FloatingLabel
                className="mb-4 target-end-date-label"
                controlId="targetSartEndDate"
                label="End Time"
              >
                <Form.Control
                  type="text"
                  placeholder="End Time"
                />

                <TimePicker
                  className={`remove-time-wrap remove-time-border  ${startActiveClass}`}
                  placeholder="EndTime"
                  value={endTime.name}
                  onChange={(value) => {
                    setEndTime({
                      id: 0,
                      name: value,
                    });
                  }}
                  onBlur={() => {
                    setStartActiveClass('');
                  }}
                  onFocus={() => {
                    setStartActiveClass('target-date-focus');
                  }}
                />
              </FloatingLabel>
              {/* {JSON.stringify(startTime)} */}
            </Col>
            {/* <Col lg={4}>
              <DropdownCollection
                defaultValue={endTime}
                items={chooseTime}
                label={'Time'}
                onChange={(obj) => setEndTime(obj)}
                disable={isManualEntry}
              />
            </Col> */}
          </Row>
          <div className="mb-3">
            <div className="total-duration-wrap">
              <FloatingLabel
                controlId="totalDuration"
                label="Total Duration"
              >
                <Form.Control
                  type="number"
                  placeholder="Total Duration"
                  value={totalHours}
                  disabled={!isManualEntry}
                  onKeyDown={(e) => invalidNumbers.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    formatTotalHour(Number(e.target.value));
                  }}
                />
              </FloatingLabel>
              {canAddManualHours && (
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    id="flexCheckCourse"
                    checked={isManualEntry}
                    onChange={(obj) => setIsManualEntry(obj.target.checked)}
                  />
                  <Form.Check.Label htmlFor="flexCheckCourse">Manually Enter hours</Form.Check.Label>
                </Form.Check>
              )}
            </div>
            {!isManualEntry && totalHours < 0 && <div className="text-danger mt-2 invalid-picker-text">End Date must be greater than Start Date</div>}
          </div>
          <p className="help-text fs-12 mt-0 mb-4">Provide additional details or notes regarding the hours logged for this activity.</p>
          <Card className="react-quill-editor">
            <TextEditor
              placeholder="Description"
              className="myeditor"
              theme="snow"
              value={description}
              onChange={(data) => setDescription(data)}
            />
          </Card>
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <Button
            variant="default"
            onClick={() => handleClose(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={addNewHour}
            disabled={startDate === '' || endDate === '' || description === '' || description === '<p></p>\n' || totalHours < 0}
          >
            {load ? (
              <>
                <div className="spinner-align-center">
                  <Spinner
                    animation="border"
                    size="sm"
                  />{' '}
                  Add hours
                </div>
              </>
            ) : (
              'Add hours'
            )}
          </Button>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default AddHoursOffCanvas;
