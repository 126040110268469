import React, { useRef, useState, useEffect } from 'react';
import { Col, ProgressBar, ProgressValue, ProgressWrap, Row, SecondaryHeaderSearchField } from 'react-myoneui';
import { Link } from 'react-router-dom';
import { getLearnerPrograms } from 'api/learner/program';
import { activitiesIcon, remindersIcon, pieIcon, products, unitBankIcon, leftArrow, rightArrow } from 'constants/icons';
import ls from 'localstorage-slim';
import BasicContentLoader from 'components/basicContentLoader';
import Log from 'lib/Log';
import IntroCard from './IntroCard';
import LearnerHeading from './LearnerHeader';
import LearnerThumbnail from './LearnerThumbnail';
import Spinner from 'react-myoneui/lib/Spinner';
import LearningProgress from './LearningProgress';
import MyActivities from './MyActivities';
import RemindersSlider from './RemindersSlider';
import { useNavigate } from 'react-router-dom';

export default function LearnerPrograms() {
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [lastAccessPrograms, setLastAccessPrograms] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [reminderCount, setReminderCount] = useState(0);
  const memberId = ls.get('userid');
  const orgId = ls.get('orgId');
  const navigate = useNavigate();

  const keyDown = (e) => {};

  const viewProgramDetail = (memberProgramId) => {
    navigate(`/learner/program/${memberProgramId}/lecture-list`);
  };
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);

  const handlePrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const handleSlideChange = (newSlideIndex) => {
    setCurrentSlide(newSlideIndex);
  };

  const handleTotalSlidesUpdate = (total) => {
    setTotalSlides(total);
  };

  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const fetchPrograms = async () => {
    try {
      setLoading(true);
      const { data } = await getLearnerPrograms({ orgId, memberId, search: searchText });
      const programs = data.data.memberPrograms.map((info) => ({
        program: info.program,
        memberProgramId: info?._id,
        custom_fields: info?.custom_fields,
        memberProgramStatus: info?.status,
        completedProgress: info?.completed_progress,
        submittedProgress: info?.submitted_progress,
        startedAt: info?.started_at,
        imgSrc: info.program?.program_image_url,
        title: info.program?.name,
        units: info?.program?.component_count,
        progress: info?.completed_progress,
        createdAt: info?.createdAt,
        programImage: info.program?.program_image_url,
      }));
      setPrograms(programs);
      if (searchText === '') {
        setLastAccessPrograms([programs[0]]);
      }
    } catch (error) {
      Log.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchText === '') {
      fetchPrograms();
    } else {
      const implementSearch = setTimeout(() => {
        fetchPrograms();
      }, 800);
      return () => clearTimeout(implementSearch);
    }
  }, [searchText]);

  return (
    <div className="learner-container">
      <Row>
        <Col
          className="pr-24"
          xl={7}
          xxl={8}
        >
          <div className="reminder-sm-show">
            {reminderCount > 0 && (
              <LearnerHeading
                svgIcon={remindersIcon}
                title="Remindersd"
              >
                <div className="slider-actions">
                  <span
                    role="button"
                    className={`btn-move btn-prev ${currentSlide === 0 ? 'disabled' : ''}`}
                    onClick={handlePrevSlide}
                    disabled={currentSlide === 0}
                  >
                    {leftArrow}
                  </span>
                  <span
                    role="button"
                    className={`btn-move btn-next ${currentSlide === totalSlides - 1 ? 'disabled' : ''}`}
                    onClick={handleNextSlide}
                    disabled={currentSlide === totalSlides - 1}
                  >
                    {rightArrow}
                  </span>
                </div>
              </LearnerHeading>
            )}
            <RemindersSlider
              ref={sliderRef}
              onSlideChange={handleSlideChange}
              onTotalSlidesUpdate={handleTotalSlidesUpdate}
              setReminderCount={setReminderCount}
            />
          </div>

          {lastAccessPrograms.length > 0 && (
            <IntroCard
              title="Resume Learning"
              imgSrc={lastAccessPrograms[0]?.programImage !== '' ? lastAccessPrograms[0]?.programImage : 'defaultImage'}
              createdAt={lastAccessPrograms[0]?.createdAt}
              subTitle={lastAccessPrograms[0]?.program?.name}
              progress={lastAccessPrograms[0]?.completedProgress}
              onClick={() => viewProgramDetail(lastAccessPrograms[0].memberProgramId)}
            />
          )}

          <LearnerHeading
            svgIcon={products}
            title="My Programs"
            className="program-search-wrap"
          >
            <SecondaryHeaderSearchField
              placeholder="Search Program..."
              value={searchText}
              onChange={onSearchChange}
              onClear={() => setSearchText('')}
              onKeyDown={keyDown}
            />

            <Link
              className="view-link"
              to="/learner/program"
            >
              View all
            </Link>
          </LearnerHeading>
          <Row className="learner-thumbnail-row">
            {loading ? (
              // <div className="spinner-middle">
              //   <Spinner animation="border" />
              // </div>
              <BasicContentLoader />
            ) : (
              programs.slice(searchText === '' ? 1 : 0, 9).map((item, index) => (
                <Col
                  sm={6}
                  lg={4}
                  xxl={3}
                  key={index}
                >
                  <LearnerThumbnail
                    imgSrc={item.imgSrc}
                    startedAt={item.createdAt}
                    title={item.title}
                    onClick={() => viewProgramDetail(item?.memberProgramId)}
                  >
                    <ProgressWrap>
                      <ProgressBar
                        variant="success"
                        now={item.progress}
                      />
                      <ProgressValue ProgressPercentage={`${item.progress}%`} />
                    </ProgressWrap>
                    <div className="badge-wrap">
                      <span className="unit-badge">
                        {unitBankIcon} {item.units} Unit
                      </span>
                    </div>
                  </LearnerThumbnail>
                </Col>
              ))
            )}
          </Row>
        </Col>
        <Col
          xl={5}
          xxl={4}
        >
          <div className="reminder-sm-hide">
            {reminderCount > 0 && (
              <LearnerHeading
                svgIcon={remindersIcon}
                title="Reminders"
              >
                <div className="slider-actions">
                  <span
                    role="button"
                    className={`btn-move btn-prev ${currentSlide === 0 ? 'disabled' : ''}`}
                    onClick={handlePrevSlide}
                    disabled={currentSlide === 0}
                  >
                    {leftArrow}
                  </span>
                  <span
                    role="button"
                    className={`btn-move btn-next ${currentSlide === totalSlides - 1 ? 'disabled' : ''}`}
                    onClick={handleNextSlide}
                    disabled={currentSlide === totalSlides - 1}
                  >
                    {rightArrow}
                  </span>
                </div>
              </LearnerHeading>
            )}
            <RemindersSlider
              ref={sliderRef}
              onSlideChange={handleSlideChange}
              onTotalSlidesUpdate={handleTotalSlidesUpdate}
              setReminderCount={setReminderCount}
            />
          </div>

          <LearnerHeading
            svgIcon={pieIcon}
            title="My Learning Progress"
          />
          <LearningProgress />
          <LearnerHeading
            svgIcon={activitiesIcon}
            title="My Activities"
          />
          <MyActivities />
        </Col>
      </Row>
    </div>
  );
}
