import { deadlineIcon } from 'constants/icons';
import { displayMonth, formatUTCTime } from 'utils/formatTime';
export default function LearnerThumbnail(props) {
  const { className, imgSrc, startedAt, title, children, onClick } = props;
  return (
    <div
      className={`learner-thumbnail ${className || ''}`}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <div className="img-wrap">
        <img
          loading="lazy"
          src={imgSrc}
          alt="Resume learning"
        />
      </div>
      <div className="info-wrap">
        {startedAt && (
          <span>
            {deadlineIcon}
            <span>Enrolled at : {displayMonth(startedAt)}</span>
            <span className="dot"></span>
            {/* <span>{formatUTCTime(startedAt)}</span> */}
          </span>
        )}
        {title && <h4 title={title}>{title}</h4>}
      </div>
      <>{children}</>
    </div>
  );
}
