import { memo, useEffect } from 'react';
import DmsUploadWidget from '../DmsUploadWidget';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addEvidences } from 'api';
import Log from 'lib/Log';
import ls from 'localstorage-slim';
import { setSelectedProgramComponent, setFirstCol } from '../../../../redux';
import { dms_base_url } from 'constants/config';
ls.config.encrypt = true;
const DMSUploadBtn = () => {
  const selectedComponent = useSelector((state) => state.sections.active_component);
  const status = useSelector((state) => (state?.sections?.active_component?.status ? state.sections.active_component.status : state?.sections?.active_component?.member_section?.status));
  const role = ls.get('role');
  const activeRole = JSON.parse(ls.get('activeRole'));
  const { pathname } = useLocation();
  const pathRole = pathname.split('/')[1];
  const dmsToken = ls.get('dmstoken');
  const dispatch = useDispatch();
  const firstCol = useSelector((state) => state.sections.firstCol);
  const { can_manage_apprentices } = activeRole?.privileges;
  const localActiveComponent = ls.get('selectedComponent');
  const localFirstCol = ls.get('firstCol');

  useEffect(() => {
    if (firstCol.length > 0) {
      ls.set('firstCol', JSON.stringify(firstCol));
    }
  }, [firstCol]);
  const addObservationEvidenceList = async (member_section_id, evidences) => {
    try {
      return await addEvidences({ member_section_ids: [member_section_id], evidences });
    } catch (error) {
      Log.error(error);
    }
  };
  const isLearner = role === 'learner' && pathRole === 'learner' && activeRole.identifier === 'apprentice';
  const setEvidencesLocal = async (data) => {
    try {
      if (data) {
        let mySelectedComponent = JSON.parse(ls.get('selectedComponent'));
        let sectionid = mySelectedComponent?.member_section?.id ? mySelectedComponent?.member_section.id : mySelectedComponent?.id;
        let rawlist = [...data];

        if (Array.isArray(rawlist)) {
          let list = rawlist.map((item) => {
            return {
              item_id: item.id.toString(),
              name: item.i_item_name,
              url: item.download_url,
              type: item.i_document_file_type,
            };
          });
          if (list.length > 0) {
            return await addObservationEvidenceList(sectionid, list);
          }
        }
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const widgetResponse = async (data) => {
    // setUploadedItem(result);
    try {
      let mySelectedComponent = JSON.parse(ls.get('selectedComponent'));
      let status = mySelectedComponent?.member_section?.status || mySelectedComponent.status || null;
      if (status === 'Not Started') {
        status = 'In Progress';
      }
      let result = await setEvidencesLocal(data);
      let resultEvidence = result?.data?.data?.evidences;
      if (mySelectedComponent?.member_section) {
        mySelectedComponent.member_section.evidences = resultEvidence;
      } else {
        mySelectedComponent.evidences = resultEvidence;
      }
      dispatch(setSelectedProgramComponent(mySelectedComponent));
      let firstColClone = JSON.parse(localFirstCol);
      let obj = firstColClone.find((item) => item.id === selectedComponent.id);
      let index = firstColClone.indexOf(obj);
      firstColClone[index].status = status;
      dispatch(setFirstCol(firstColClone));
      // onChange(mySelectedComponent);

      /* If this component status is Not Started need to change into In Progress */
    } catch (error) {
      Log.error(error);
    }
  };
  return (
    <>
      {isLearner &&
        activeRole?.privileges?.enable_document_bank &&
        status !== 'Submitted' &&
        status !== 'isCompleted' &&
        dmsToken &&
        selectedComponent?.section?.section_type !== 'ReviewComponent' &&
        selectedComponent?.section?.section_type !== 'FormComponent' && (
          <DmsUploadWidget
            buttonTitle={'Document create folder'}
            widgetEndPoint={`${dms_base_url}/widget/document-manager?token=${dmsToken}`}
            uploadCallback={widgetResponse}
          />
        )}

      {!isLearner &&
        activeRole?.privileges?.enable_document_bank &&
        status !== 'Submitted' &&
        status !== 'isCompleted' &&
        can_manage_apprentices === true &&
        dmsToken &&
        selectedComponent?.section?.section_type !== 'ReviewComponent' &&
        selectedComponent?.section?.section_type !== 'FormComponent' && (
          <DmsUploadWidget
            buttonTitle={'Document create folder'}
            widgetEndPoint={`${dms_base_url}/widget/document-manager?token=${dmsToken}`}
            uploadCallback={widgetResponse}
          />
        )}
    </>
  );
};

export default memo(DMSUploadBtn);
