import { useEffect, useState } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Button from 'react-myoneui/lib/Button';
import UploadEvidenceFile from '../../../components/learners/UploadEvidenceFile';
import { getTokenForDMS } from '../../../api';
import DmsUploadWidget from './DmsUploadWidget';
import Log from '../../../lib/Log';
import { dms_base_url } from 'constants/config';
function AddEvidenceOffCanvas({ onClose, show, user_id }) {
  const handleClose = () => onClose();
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const [dmsToken, setDMSToken] = useState('');

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    try {
      const { data } = await getTokenForDMS(user_id);
      setDMSToken(data.data.token);
    } catch (err) {
      Log.info('Error >> ', err);
    }
  };

  const widgetResponse = (res) => {
    Log.info('Res >> ', res);
  };

  return (
    <>
      {dmsToken && (
        <DmsUploadWidget
          buttonTitle={'Document create folder'}
          widgetEndPoint={`${dms_base_url}/widget/document-manager?token=${dmsToken}`}
          uploadCallback={widgetResponse}
        />
      )}
      {/* <Offcanvas show={show} onHide={handleClose} placement="end" onKeyDown={offCanvasKeyDown}>
        <Offcanvas.Header closeButton backButton className="pb-0">
          <Offcanvas.Title>
            Add <br /> Evidence
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p className="help-text w-75 fs-12 mt-0">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          <UploadEvidenceFile className="upload-evidence-height" />
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <Button variant="default" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Add Evidence
          </Button>
        </Offcanvas.Footer>
      </Offcanvas> */}
    </>
  );
}

export default AddEvidenceOffCanvas;
