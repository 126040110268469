import { memo, useEffect } from 'react';
import { Form, Col } from 'react-myoneui';
import { useDispatch, useSelector } from 'react-redux';
import { updateValue } from 'redux/QuizDataSlice';
import Log from 'lib/Log';
function InputRadio({ data, mode, question_number }) {
  const state = useSelector((state) => state?.quizData?.data?.find((item) => item?.questionId === data?.id));
  const dispatch = useDispatch();
  const questionId = data?.id;
  // const quizData = useSelector((state) => state?.quizData.data);

  const valueControl = (e, index) => {
    try {
      const { value, checked } = e.target;
      const updates = {
        value: value,
        checked: checked,
        index: index,
      };
      dispatch(updateValue({ questionId, index, updates, type: 'radio' }));
    } catch (error) {
      Log.error(error);
    }
  };

  return (
    <>
      <Col
        className="py-2"
        // md={state?.col_width}
        name="formElement"
      >
        <Form.Label
          htmlFor={data?.title}
          className="form-builder-label"
        >
          {question_number}. {data?.title}
        </Form.Label>
        <ol
          type="a"
          className="ol-style"
        >
          {data?.options?.length > 0
            ? data.options.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="align-items-center pt-2 "
                  >
                    <Form.Check
                      value={item?.label}
                      label={item.label}
                      name={question_number}
                      type="radio"
                      className="ms-2"
                      onChange={(e) => valueControl(e, index)}
                      checked={state?.selectedOptions?.find((option) => option.index == index)}
                      disabled={mode === 'preview' || mode === 'readonly'}
                    />
                  </li>
                );
              })
            : ''}
        </ol>
      </Col>
    </>
  );
}
export default memo(InputRadio);
