import axios from 'axios';
import { API_URL } from '../../constants/config';
import ls from 'localstorage-slim';
ls.config.encrypt = true;

export const getLearnerProgramEnrollmentStatus = async () => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.get(`${API_URL}/apprentice/member-program-enrollment-status`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const getLearnerProgramUpcomingTargetsAndDeadlines = async () => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.get(`${API_URL}/apprentice/member-upcoming-targets-and-deadlines`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const getLearnerActivity = (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    organisationid: `${ls.get('orgId') || payload?.orgId}`,
  };

  return new Promise((resolve, reject) => {
    const { currentPage, perPage } = payload;
    return axios
      .get(`${API_URL}/apprentice/member-activity`, { params: { currentPage, perPage }, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const checkOgrFlag = async ({ orgId }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.get(`${API_URL}/apprentice/check-organization-flag/${orgId}`, headers);
  } catch (err) {
    throw new Error(err);
  }
};
