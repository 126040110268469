import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [] };

export const FormDataSlice = createSlice({
  name: 'formData',
  initialState,
  reducers: {
    storeFormData: (state, action) => {
      return { ...state, data: action.payload };
    },
    addFormData: (state, action) => {
      // console.log(action.payload);
      let cloneData = [...state.data];
      // console.log(cloneData);
      cloneData.push(action.payload);

      return { ...state, data: cloneData };
    },
    flushFormData: () => {
      return [];
    },
    updateValue: (state, action) => {
      // console.log(action.payload);
      let cloneData = { ...state.data };
      // console.log(cloneData);
      /*
      let obj = clone.find((item) => item.id === action.payload.id);
      console.log(obj);
      let index = clone.indexOf(obj);
      obj.value = action.payload.value;
      clone[index] = obj;
      console.log(clone);
      return [...state];
      */
      return [...state];
    },
  },
});

export const activeRole = (state) => state;
export const { storeFormData, addFormData, flushFormData, updateValue } = FormDataSlice.actions;
export default FormDataSlice.reducer;
