import { Routes, Route } from 'react-router-dom';
import { lazyWithRetry } from 'components/lazyWithRetry';
import CommonRoute from '../common';
import ApprenticeProgramRoute from '../common/apprenticeProgramRoute';
import ProgramRoute from 'routes/common/programRoute';
const ProgramDetail = lazyWithRetry(() => import('modules/admin/users/programs/programDetail'));
const ProgrammeProgress = lazyWithRetry(() => import('modules/admin/users/programs/programProgress'));
const ActivePrograms = lazyWithRetry(() => import('modules/admin/users/programs/activePrograms'));
const AssessorLayout = lazyWithRetry(() => import('layouts/AssessorLayout'));
const Dashboard = lazyWithRetry(() => import('modules/admin/dashboard'));
const ProfileActivity = lazyWithRetry(() => import('modules/admin/profile'));
const Program = lazyWithRetry(() => import('modules/admin/program'));
const ProgramFieldManagement = lazyWithRetry(() => import('modules/admin/program/FieldManagement'));
const AllComponent = lazyWithRetry(() => import('modules/admin/program/allComponent/index'));
const UserApprentices = lazyWithRetry(() => import('modules/admin/users/apprentices'));
const ProfileFieldManagement = lazyWithRetry(() => import('modules/admin/users/FieldManagement'));
const Taxonomy = lazyWithRetry(() => import('modules/superAdmin/taxonomy'));
const Profile = lazyWithRetry(() => import('modules/admin/profile/Profile'));
const LectureList = lazyWithRetry(() => import('modules/learner/program/LectureList'));
const ActivityDetail = lazyWithRetry(() => import('modules/learner/program/ActivityDetail'));
const TaskList = lazyWithRetry(() => import('modules/learner/program/TaskList/'));
const Target = lazyWithRetry(() => import('modules/learner/program/Target/'));
const Resubmission = lazyWithRetry(() => import('modules/learner/program/Resubmission/'));
const MemberProgramList = lazyWithRetry(() => import('modules/admin/program/MemberProgramList'));
const QuickLinks = lazyWithRetry(() => import('../../modules/learner/program/QuickLinks/'));
const Review = lazyWithRetry(() => import('modules/iqv/assessors/Review'));
const Notification = lazyWithRetry(() => import('components/notification'));
const ActiveApprentices = lazyWithRetry(() => import('modules/admin/users/apprentices/activeApprentices'));
const RatingReview = lazyWithRetry(() => import('modules/admin/program/ratingReview'));
const WageSchedule = lazyWithRetry(() => import('modules/admin/wage'));
const Reports = lazyWithRetry(() => import('modules/admin/program/Reports'));
const ExternalExamComponentList = lazyWithRetry(() => import('modules/admin/externalExam/componentList'));
const ExternalExamLearnerList = lazyWithRetry(() => import('modules/admin/externalExam/learnerList'));
const EventComponentList = lazyWithRetry(() => import('modules/admin/event/componentList'));
const EventLearnerList = lazyWithRetry(() => import('modules/admin/event/learnerList'));

const NoMatch = lazyWithRetry(() => import('modules/NoMatch'));
export default (
  <>
    <Routes>
      <Route
        path="assessor/"
        element={<AssessorLayout />}
      >
        {CommonRoute}
        {ApprenticeProgramRoute}
        <Route
          index
          element={<Dashboard />}
        />
        <Route
          path="dashboard/"
          element={<Dashboard />}
        />
        <Route
          path="notification"
          element={<Notification />}
        />
        <Route
          path="view-profile/:id"
          element={<ProfileActivity />}
        />
        <Route
          path="review/:id/"
          element={<Review reviewFrom="assessor" />}
        />
        {ProgramRoute}
        {/* <Route
          path="program/*"
          element={<Program />}
        />
        <Route
          path="program/:programId/field-management"
          exact
          element={<ProgramFieldManagement />}
        />
        <Route
          path="program/:programId/all-component"
          exact
          element={<AllComponent />}
        /> */}
        <Route
          path="apprentices/"
          exact
          element={<UserApprentices />}
        />
        <Route
          path="field-management/apprentices"
          exact
          element={<ProfileFieldManagement />}
        />
        <Route
          path="taxonomy/*"
          element={<Taxonomy />}
        />
        <Route
          path="profile/edit-profile/:id"
          element={<Profile />}
        />
        <Route
          path="wage/:memberProgramId"
          element={<WageSchedule />}
        />
        {/* <Route
          path="apprentices/view-profile/:id"
          exact
          element={<ProfileActivity name="apprentices" />}
        />
        <Route
          path="apprentices/view-profile/:id/program/:programId/"
          exact
          element={<LectureList />}
        />
        <Route
          path="apprentices/view-profile/:id/program/:programId/lecture-list"
          exact
          element={<LectureList />}
        />
        <Route
          path="apprentices/view-profile/:id/program/:programId/:lectureDetail/activitityDetail"
          element={<ActivityDetail />}
        />
        <Route
          path="apprentices/view-profile/:id/program/:programId/lecture-list/:lectureDetail/activitityDetail"
          element={<ActivityDetail />}
        />

        <Route
          path="apprentices/view-profile/:id/program/:member_program_id/target"
          exact
          element={<Target />}
        />
        <Route
          path="apprentices/view-profile/:id/program/:programId/task-list"
          exact
          element={<TaskList />}
        />
        <Route
          path="apprentices/view-profile/:id/program/:programId/task-list/:lectureDetail/activitityDetail"
          exact
          element={<ActivityDetail />}
        />
        <Route
          path="apprentices/view-profile/:id/program/:programId/quick-links"
          exact
          element={<QuickLinks />}
        />
        <Route
          path="apprentices/view-profile/:id/program/:programId/quick-links/:lectureDetail/activitityDetail"
          exact
          element={<ActivityDetail />}
        />
        <Route
          path="apprentices/view-profile/:id/program/:programId/resubmission"
          exact
          element={<Resubmission />}
        /> */}

        {/* <Route
          path="program/:programId/learner-list"
          exact
          element={<MemberProgramList />}
        />
        <Route
          path="program/:programId/learner-list/:id/lecture-list"
          exact
          element={<LectureList />}
        />
        <Route
          path="program/:programId/learner-list/:id/target"
          exact
          element={<Target />}
        />
        <Route
          path="program/:programId/learner-list/:id/quick-links"
          exact
          element={<QuickLinks />}
        />
        <Route
          path="program/:programId/learner-list/:id/task-list"
          exact
          element={<TaskList />}
        />
        <Route
          path="program/:programId/learner-list/:id/resubmission"
          exact
          element={<Resubmission />}
        />
        <Route
          path="program/:programId/learner-list/:id/lecture-list/:lectureDetail/activitityDetail"
          element={<ActivityDetail />}
        />
        <Route
          path="program/:programId/:backpage/:id"
          exact
          element={<LectureList />}
        /> */}
        {/* Progress Reports Route */}
        <Route
          path="users/programs/active"
          exact
          element={<ActivePrograms />}
        />
        <Route
          path="users/apprentices/active"
          exact
          element={<ActiveApprentices />}
        />
        <Route
          path="users/programs/progress/:programId"
          exact
          element={<ProgrammeProgress />}
        />
        <Route
          path="users/programs/active/progress/:programId/:memberProgramId"
          exact
          element={<ProgramDetail />}
        />
        <Route
          path="users/programs/active/progress/:memberProgramId"
          exact
          element={<ProgramDetail />}
        />
        <Route
          path="users/programs/active/progress/learner-program-detail"
          exact
          element={<ProgramDetail />}
        />
        <Route
          path="users/apprentice/program/report"
          exact
          element={<ProgrammeProgress />}
        />
        <Route
          path="program/:programId/rating-review"
          exact
          element={<RatingReview />}
        />
        <Route
          path="program/:programId/reports"
          exact
          element={<Reports />}
        />
        <Route
          path="external-exam"
          element={<ExternalExamComponentList />}
        />
        <Route
          path="external-exam/:examId"
          element={<ExternalExamLearnerList />}
        />
        <Route
          path="event"
          element={<EventComponentList />}
        />
        <Route
          path="event/:eventId"
          element={<EventLearnerList />}
        />
        <Route
          path="*"
          element={<NoMatch />}
        />
      </Route>
    </Routes>
  </>
);
