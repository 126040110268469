import * as React from 'react';
import { useState } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Card from 'react-myoneui/lib/Card';
import Button from 'react-myoneui/lib/Button';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import { Row, Col } from 'react-myoneui';
import 'react-quill/dist/quill.snow.css';
import TextEditor from '../../../../components/TextEditor';
import DateTimePicker from 'react-datetime-picker';
import { useSelector } from 'react-redux';

import Spinner from 'react-myoneui/lib/Spinner';

import { translate } from '../../../../lib';
import ls from 'localstorage-slim';

function PreviewAddTarget(props) {
  const taxonomy = useSelector((state) => state.taxonomy.data);
  const roles = JSON.parse(ls.get('roles'));
  const learnerName = roles.filter((role) => role.identifier === 'apprentice')[0].name.toLowerCase();
  const [load, setLoad] = useState(false);
  const [activeClass, setActiveClass] = useState('');
  const [validationError, setValidationError] = useState('');
  const { show, onClose, sectionTitle, onSave } = props;
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const [target, setTarget] = useState({
    date: '',
    progress: '',
    description: '',
  });

  const [clicked, setClicked] = useState({
    progress: false,
    description: false,
    date: false,
  });

  const invalidNumbers = ['e', 'E', '.', '+', '-'];

  const { date, progress, description } = target;
  const isFormValid = date !== '' && progress !== '' && description.length > 8;

  const handleClose = () => {
    // clear state
    setTarget({
      date: '',
      progress: '',
      description: '',
    });
    setValidationError('');
    setClicked({ progress: false, description: false, date: false });
    setLoad(false);
    onClose();
  };

  const addNewTarget = async () => {
    handleClose();
    onSave();
  };

  const checkProgress = (value) => {
    setTarget({ ...target, progress: value });
  };

  const checkDescription = (value) => {
    setTarget({ ...target, description: value?.trim() });
  };

  const checkTargetDate = (value) => {
    setTarget({ ...target, date: value });
  };

  const handleChange = (component, value) => {
    switch (component) {
      case 'date':
        checkTargetDate(value);
        break;
      case 'progress':
        checkProgress(value);
        break;
      case 'description':
        checkDescription(value);
        break;
      default:
    }
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Header
          closeButton
          backButton
          className="pb-0"
        >
          <Offcanvas.Title>
            Add <br />
            {`${translate(taxonomy)('target')}`}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p className="help-text fs-12 mt-0">
            {`${translate(taxonomy)('target')}s are Milestones for the ${learnerName} in ${sectionTitle} . Please considere these as progresses ${learnerName} should achieve by certain dates.`}
          </p>
          <Row>
            <Col
              lg={6}
              className="floating-icon-wrap"
            >
              <FloatingLabel
                className="mb-4 target-end-date-label"
                controlId="targetSartEndDate"
                label={`${translate(taxonomy)('target')} End date`}
              >
                <Form.Control
                  type="text"
                  placeholder="Target start &amp; End date"
                />
                <DateTimePicker
                  className={`remove-time-wrap ${activeClass}`}
                  clearIcon={null}
                  placeholder="Target start &amp; End date"
                  value={target.date}
                  minDate={new Date()}
                  onChange={(value) => handleChange('date', value)}
                  onBlur={() => {
                    setActiveClass('');
                    setClicked({ ...clicked, date: true });
                  }}
                  onFocus={() => {
                    setActiveClass('target-date-focus');
                    setClicked({ ...clicked, date: false });
                  }}
                  locale="en-US"
                />
              </FloatingLabel>
              {clicked.date && target.date === '' && (
                <div>
                  <span
                    className="invalid-feedback mb-4"
                    style={{ display: 'block' }}
                  >
                    {` ${translate(taxonomy)('target')} Date is required`}
                  </span>
                </div>
              )}
            </Col>
            <Col lg={6}>
              <FloatingLabel
                className="mb-4"
                controlId="targetPercentage"
                label={`${translate(taxonomy)('target')} Percentage %`}
              >
                <Form.Control
                  type="number"
                  max="100"
                  min="1"
                  placeholder="Target Percentage %"
                  value={target.progress}
                  onKeyDown={(e) => invalidNumbers.includes(e.key) && e.preventDefault()}
                  onChange={(e) => handleChange('progress', e.target.value)}
                  onBlur={() => {
                    setClicked({ ...clicked, progress: true });
                  }}
                  onFocus={() => {
                    setClicked({ ...clicked, progress: false });
                  }}
                />
              </FloatingLabel>

              {clicked.progress && target.progress.trim() === '' && (
                <div>
                  <span
                    className="invalid-feedback mb-4"
                    style={{ display: 'block' }}
                  >
                    {`${translate(taxonomy)('target')} is required`}
                  </span>
                </div>
              )}
            </Col>
          </Row>

          <Card className="react-quill-editor">
            <TextEditor
              placeholder={`${translate(taxonomy)('target')} Description`}
              className="myeditor"
              theme="snow"
              value={target.description?.trim()}
              onChange={(value) => handleChange('description', value)}
              onFocus={() => setClicked({ ...clicked, description: false })}
              onBlur={() => {
                setClicked({ ...clicked, description: true });
              }}
            />
          </Card>
          {clicked.description && target.description.length === 8 && (
            <div>
              <span
                className="invalid-feedback mb-4"
                style={{ display: 'block' }}
              >
                <h3>Description is required</h3>
              </span>
            </div>
          )}
          {validationError.trim().length > 1 && (
            <div>
              <span
                className="invalid-feedback mb-4"
                style={{ display: 'block' }}
              >
                <h3>{validationError}</h3>
              </span>
            </div>
          )}
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <Button
            variant="default"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={load || !isFormValid}
            variant="primary"
            onClick={addNewTarget}
          >
            {load ? (
              <>
                <div className="spinner-align-center">
                  <Spinner
                    animation="border"
                    size="sm"
                  />{' '}
                  {`Add ${translate(taxonomy)('target')}`}
                </div>
              </>
            ) : (
              `Add ${translate(taxonomy)('target')}`
            )}
          </Button>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default PreviewAddTarget;
