import * as React from 'react';
import { useState, useRef } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Button from 'react-myoneui/lib/Button';
import { Row, Col, Spinner } from 'react-myoneui';
import SecondaryHeaderSearchField from 'react-myoneui/lib/SecondaryHeaderSearchField';
import GridTable from '../../../../../utils/react-grid-table';

import { getRoles, assignAssessorsProgram, listAssessors } from '../../../../../api';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { storeRoles } from '../../../../../redux';
import DataNotFound from '../../../../../components/DataNotFound';
import TutorNameTemplate from './grid-table/FullNameTemplate';
import TutorEmailTemplate from './grid-table/EmailTemplate';

import ls from 'localstorage-slim';
import Log from '../../../../../lib/Log';
// enable global encryption
ls.config.encrypt = true;

function AssignAssessorsOffCanvas({ onClose = () => {}, program = '', show = false, name, onAssigned }) {
  const programName = program?.name;
  const handleClose = () => onClose();
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const [search, setSearch] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [pageChange, setPageChange] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [perPage, setPerPage] = useState(0);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const { orgId, programId } = useParams();
  const [organisationId] = useState(orgId || ls.get('orgId'));
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [tutorId, setTutorId] = useState();
  const roles = JSON.parse(ls.get('roles'));
  const accessorName = roles.find((role) => role.identifier === 'assessor')?.name + 's' ?? 'Accessors';
  const activeRole = JSON.parse(ls.get('activeRole'));
  const [isValid, setIsValid] = useState(false);

  const dispatch = useDispatch();

  const assignTutor = async () => {
    try {
      setSaveLoader(true);

      await assignAssessorsProgram({
        assessors: users,
        memberPrograms: [programId],
      });
      setSaveLoader(false);
      onAssigned(true);
    } catch (error) {
      setSaveLoader(false);
      onClose(true);
      Log.error(error);
    }
  };

  const columnData = [
    {
      id: 'checkbox',
      pinned: true,
      className: 'selectAll',
      width: '55px',
      minResizeWidth: 0,
      maxResizeWidth: null,
      resizable: false,
      visible: true,
    },
    {
      id: 1,
      field: 'learner_name',
      visible: true,
      label: `${roles.filter((role) => role.identifier === 'assessor')[0]?.name + 's' ?? ''} Name`,
      width: '1fr',
      sortable: false,
      cellRenderer: TutorNameTemplate,
    },
    {
      id: 2,
      field: 'learner_email',
      visible: true,
      label: 'Email ID',
      width: '1fr',
      sortable: false,
      cellRenderer: TutorEmailTemplate,
    },
  ];

  const tableManager = useRef(null);

  const setTableManager = (tm) => (tableManager.current = tm);

  useEffect(() => {
    if (roles.length > 1) {
      const role = roles.filter((role) => role?.identifier === 'tutor');
      setTutorId(role[0]?.id);
    } else {
      fetchRoles();
    }
  }, []);

  useEffect(() => {
    if (show && search) {
      reLoadData();
    }
  }, [search, show]);

  const fetchRoles = async () => {
    try {
      const roles = await getRoles(organisationId);
      const role = roles?.data?.data.filter((role) => role?.identifier === 'tutor');
      setTutorId(role[0].id);
      dispatch(storeRoles(roles?.data));
      ls.set('roles', JSON.stringify(roles.data.data));
    } catch (err) {
      Log.error('Error >> ', err);
    }
  };

  const setDefaultValues = () => {
    try {
      setData([]);
      setTotalData(0);
      setCurrentPage(1);
      setPerPage(0);
      setPageChange(false);
    } catch (err) {
      Log.error('Error >> ', err);
    }
  };

  const onSearchChange = (e) => {
    try {
      const value = e.target.value;
      setSearchText(value);
    } catch (err) {
      Log.error('Error ', err);
    }
  };

  const loadMoreHandler = () => {
    try {
      setCurrentPage(currentPage + 1);
      setPageChange(true);
      setLoadMore(true);
      setSearch(true);
    } catch (err) {
      Log.error('Error ', err);
    }
  };

  const reLoadData = async () => {
    try {
      if (organisationId && loading === false) {
        if (!pageChange) {
          setLoading(true);
        }
        Log.info(perPage);
        const result = await listAssessors({
          currentPage: currentPage,
          search: searchText,
          memberProgram: program,
        });

        if (result) {
          const members = result?.data?.data?.members.map((member) => {
            return {
              id: member?.id,
              learner_name: member?.first_name + ' ' + member?.last_name,
              learner_email: member?.decrypted_email,
              profile_image: member?.profile_image,
              role_identifier: 'assessor',
              roles: member?.roles,
            };
          });

          setData([...members]);
          setTotalData(result?.data.data?.meta?.totalMembers);
          setHasNextPage(result?.data.data?.meta?.hasNextPage);
          setPerPage((prev) => prev + members.length);
        }
      }
    } catch (err) {
      Log.error(err);
    } finally {
      setLoading(false);
      setSearch(false);
      setLoadMore(false);
    }
  };
  useEffect(() => {
    if (searchText === '') {
      setDefaultValues();
      setSearch(true);
    } else {
      const implementSearch = setTimeout(() => {
        setDefaultValues();
        setSearch(true);
      }, 800);
      return () => clearTimeout(implementSearch);
    }
  }, [searchText]);
  return (
    <>
      <Offcanvas
        className="canvas-active-border"
        show={show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Header
          closeButton
          backButton
          className="pb-0"
        >
          <Offcanvas.Title>
            Assign {accessorName} <br />
            <span
              className="assign-program-name"
              title={programName}
            >
              {programName}
            </span>
            <p className="help-text w-75 fs-12">Select and assign {accessorName} to guide and support learner throughout the programme.</p>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col
              sm={12}
              className="mb-3"
            >
              <SecondaryHeaderSearchField
                placeholder={`Search ${roles.filter((role) => role.identifier === 'assessor')[0]?.name + 's' ?? ''}....`}
                value={searchText}
                onChange={onSearchChange}
                onClear={() => setSearchText('')}
              />
            </Col>
          </Row>
          {loading && (
            <div className="spinner-wrapper">
              <Spinner animation="border" />
            </div>
          )}
          {data && data.length > 0 && totalData && !loading ? (
            <div className="assign-program-scrollbar grid-table-border">
              <GridTable
                className="grid-table-border"
                highlightSearch={false}
                columns={columnData}
                onRowsRequest={() => {
                  return {
                    rows: data,
                    totalRows: totalData,
                  };
                }}
                onSelectedRowsChange={(a) => {
                  setUsers(a);
                  if (a.length > 0) {
                    setIsValid(true);
                  } else {
                    setIsValid(false);
                  }
                }}
                onPageChange={(page) => Log.info('page Chagne ', page)}
                onPageSizeChange={(size) => Log.info('page size', size)}
                onRowClick={(a) => Log.info('onRowClick:', a)}
                onLoad={setTableManager}
                pageSize={perPage}
              />
              {data && data.length > 0 && totalData && (
                <div className="p-2">
                  <>
                    {hasNextPage && data.length && (
                      <button
                        className="load-more-btn"
                        onClick={loadMoreHandler}
                      >
                        {loadMore && (
                          <>
                            <Spinner
                              animation="border"
                              size="sm"
                            />{' '}
                            &nbsp;
                          </>
                        )}
                        Load More
                      </button>
                    )}
                  </>
                </div>
              )}
            </div>
          ) : (
            !loading && <DataNotFound title={roles.filter((role) => role.identifier === 'assessor')[0]?.name + 's' ?? ''} />
          )}
        </Offcanvas.Body>
        <Offcanvas.Footer className="border-top">
          <Button
            variant="default"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!isValid}
            onClick={() => {
              assignTutor();
            }}
          >
            {saveLoader && (
              <>
                <Spinner
                  animation="border"
                  size="sm"
                />{' '}
                &nbsp;
              </>
            )}
            Assign
          </Button>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default AssignAssessorsOffCanvas;
