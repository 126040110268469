export default function BasicContentLoader() {
  return (
    <div className="content-loader-holder">
      <div className="content-loader">
        <div className="top-loader">
          <div className="loader-bar small-bar"></div>
          <div className="loader-bar small-bar"></div>
        </div>
        <div className="loader-bar large-bar"></div>
      </div>

      <div className="content-loader full-content-loader">
        <div className="top-loader">
          <div className="loader-bar small-bar"></div>
          <div className="loader-bar small-bar"></div>
        </div>
        <div className="loader-bar full-bar"></div>
      </div>

      <div className="content-loader full-content-loader">
        <div className="top-loader">
          <div className="loader-bar small-bar"></div>
          <div className="loader-bar small-bar"></div>
        </div>
        <div className="loader-bar full-bar"></div>
      </div>

      <div className="content-loader full-content-loader">
        <div className="top-loader">
          <div className="loader-bar small-bar"></div>
          <div className="loader-bar small-bar"></div>
        </div>
        <div className="loader-bar full-bar"></div>
      </div>
    </div>
  );
}
