import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [] };

export const EventsSlice = createSlice({
  name: 'eventsData',
  initialState,
  reducers: {
    storeEventsData: (state, action) => {
      state.data = action.payload;
    },
    addEventsData: (state, action) => {
      const { member, checked, profile } = action.payload;
      if (checked) {
        state.data.push({ member, profile });
      } else {
        state.data = state.data.filter((item) => item.member !== member);
      }
    },
    flushEventsData: () => initialState,
    deleteEventsData: (state, action) => {
      const member = action.payload;
      state.data = state.data.filter((item) => item.member !== member);
    },
  },
});

export const { storeEventsData, addEventsData, flushEventsData, deleteEventsData } = EventsSlice.actions;
export default EventsSlice.reducer;
