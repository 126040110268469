import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [] };

export const externalExamUpdateScoreSlice = createSlice({
  name: 'examScoreData',
  initialState,
  reducers: {
    storeExternalExamUpdateScoreData: (state, action) => {
      state.data = action.payload;
    },
    addExternalExamUpdateScoreData: (state, action) => {
      const { member, checked, profile, marks_obtained, external_exam_custom_fields } = action.payload;
      if (checked) {
        state.data.push({ member, profile, marks_obtained, external_exam_custom_fields });
      } else {
        state.data = state.data.filter((item) => item.member !== member);
      }
    },
    flushExternalExamUpdateScoreData: () => initialState,
    updateExternalExamUpdateScoreData: (state, action) => {
      const { member, marks_obtained } = action.payload;
      const item = state.data.find((item) => item.member === member);
      if (item) {
        item.marks_obtained = marks_obtained;
      }
    },
    deleteExternalExamUpdateScoreData: (state, action) => {
      const member = action.payload;
      state.data = state.data.filter((item) => item.member !== member);
    },
  },
});

export const { storeExternalExamUpdateScoreData, addExternalExamUpdateScoreData, flushExternalExamUpdateScoreData, updateExternalExamUpdateScoreData, deleteExternalExamUpdateScoreData } =
  externalExamUpdateScoreSlice.actions;
export default externalExamUpdateScoreSlice.reducer;
