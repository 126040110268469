import { memo, useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-myoneui';
import SubSection from './SubSection';
import { getSubSections, getFormElements } from 'api/formBuilder';
import { getFormFields } from 'api/component/form';
import { addFormData, formData, storeFormData } from '../../../../src/redux/FormDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import Log from 'lib/Log';
function SectionElement({ data = {}, mode, status, onUpdate, payloadData = {} }) {
  const allData = useSelector((state) => state.formData?.data);
  //const myelements = allData.filter((item) => item.parent_id === data.id);
  const [elements, setElements] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const formData = useSelector((state) => state.formData.data);

  const fetchFormData = async () => {
    let payload = { memberProgram: payloadData?.member_program, form: payloadData?.form };

    let result = await getFormFields({ memberProgram: payloadData?.member_program, form: payloadData?.form });
    return result.data.data.map((item) => {
      let obj = { ...item };
      obj.id = item.field;
      return obj;
    });

    //onUpdate(result);
  };
  const setUniqueOnly = (data) => {
    // console.log(data);
    let collection = [];
    data.forEach((item) => {
      if (collection?.find((col) => col.id === item.id)) {
        Log.info('no add');
      } else {
        // console.log('add');
        collection.push(item);
      }
    });
    // console.log(collection);
    return collection;
  };
  useEffect(() => {
    switch (mode) {
      case 'preview':
        setElements(allData.filter((item) => item.parent_id === data.id));
        break;
      case 'add':
        fetchSubSections();
        break;
      case 'edit':
        fetchSubSections();
      case 'view':
        fetchSubSections();
      default:
        fetchSubSections();
    }
  }, [mode]);
  const fetchSubSections = async () => {
    try {
      if (data.id) {
        if (data?.name === 'section') {
          setLoading('loading');
          let result = await getSubSections({ sectionId: data.id });
          setLoading('loaded');
          if (result.data.data.length > 0) {
            setElements([...result.data.data]);
            // onUpdate([...result.data.data]);
            // dispatch(storeFormData([...formData, ...result.data.data]));
            let subSections = [...result.data.data];
            let myallPromise = await result.data.data.map(async (item) => {
              let result = await getFormElements({ subSectionId: item.id });
              if (result) {
                setLoading(true);
                var formElements = [...result.data.data];

                let formFilledData = mode === 'preview' ? [] : await fetchFormData();
                if (formFilledData.length > 0) {
                  let readyData = formElements.map((item) => {
                    let formFilledObj = formFilledData.find((ele) => ele.field === item.id);
                    let obj = {
                      ...item,
                      ...formFilledObj,
                    };

                    return obj;
                  });
                  return readyData;
                } else {
                  return formElements;
                }
              }
            });

            let myfinal = await Promise.allSettled(myallPromise).then((value) => value);
            let finalResult = myfinal.map((item) => item.value);
            let allFields = [];
            finalResult.forEach((item) => item.map((obj) => allFields.push(obj)));
            dispatch(storeFormData(setUniqueOnly([...formData, ...subSections, ...allFields])));
          }
        }
      }
    } catch (error) {
      Log.error(error);
      setLoading('loaded');
    }
  };
  return loading === 'loading' ? (
    <div className="spinner-wrapper">
      <Spinner animation="border" />
    </div>
  ) : (
    <div className="sectionblock">
      <Row>
        <Col className="py-2">
          <div className="form-builder-section">
            <h4>{data.heading}</h4>
            <div className="desc"> {data.sub_heading}</div>
          </div>
        </Col>
      </Row>

      {elements &&
        elements.length > 0 &&
        elements.map((subsection) => (
          <SubSection
            key={subsection.id}
            data={subsection}
            mode={mode}
            payloadData={payloadData}
            form_section={data.id}
            status={status}
          />
        ))}
    </div>
  );
}
export default memo(SectionElement);
