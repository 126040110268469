import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Col, Row } from 'react-myoneui';
import ls from 'localstorage-slim';
import { Chart, ArcElement } from 'chart.js';

const CircleRadiusChart = ({ data }) => {
  const dataName = data.map((items) => items.name);
  const dataCountValues = data.map((items) => items.value);
  const computedLabels = dataName.slice(0, 3);
  const dataValues = dataCountValues.slice(0, 3);

  const totalPrograms = data.find((item) => item.name === 'TotalPrograms Count')?.value || 0;
  const dataPercentages = dataValues.map((value) => ((value / totalPrograms) * 100).toFixed(2));

  const backgroundColors = ['#539A86', '#2F64BD', '#E4E6FF'];
  const [legendStates, setLegendStates] = useState(computedLabels.map(() => false));
  const roles = JSON.parse(ls.get('roles'));
  //const learnerName = roles?.filter((role) => role.identifier === 'apprentice')[0]?.name;
  Chart.register(ArcElement);

  const [chartData, setChartData] = useState({
    labels: computedLabels,
    datasets: [
      {
        data: dataValues,
        backgroundColor: backgroundColors,
        borderWidth: 1,
      },
    ],
  });

  const chartOptions = {
    aspectRatio: 1.2,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        // callbacks: {
        //   label: (tooltipItem) => {
        //     const value = tooltipItem.formattedValue;
        //     return ` Active ${learnerName} : ${value}`;
        //   },
        // },
        backgroundColor: '#263238',
        titleAlign: 'bottom',
        bodyAlign: 'bottom',
        padding: 8,
        caretSize: 0,
        yAlign: 'bottom',
      },
    },
  };

  const handleLegendItemClick = (index) => {
    const newLegendStates = [...legendStates];
    newLegendStates[index] = !newLegendStates[index];
    setLegendStates(newLegendStates);

    const newBackgroundColors = [...chartData.datasets[0].backgroundColor];

    if (newLegendStates[index]) {
      chartData.datasets[0].data[index] = 0;
    } else {
      chartData.datasets[0].data[index] = dataValues[index];
    }

    setChartData((prevChartData) => ({
      ...prevChartData,
      datasets: [
        {
          ...prevChartData.datasets[0],
          backgroundColor: newBackgroundColors,
        },
      ],
    }));
  };

  return (
    <Row className="chart-wrapper program-chart-wrapper">
      <Col
        sm={6}
        md={8}
        xl={7}
      >
        <div className="legend-container">
          {/* <div className="legend-title">
            <h4>Legend</h4>
            <h4>Learners</h4>
          </div> */}
          {computedLabels.map((label, index) => (
            <div
              key={index}
              className={`legend-item ${legendStates[index] ? 'selected' : ''}`}
              onClick={() => handleLegendItemClick(index)}
            >
              <div className="legend-details">
                <div
                  className="legend-color"
                  style={{ backgroundColor: backgroundColors[index] }}
                ></div>
                <div
                  className="legend-label"
                  title={`${label} (${dataValues[index]}/${totalPrograms})`}
                >
                  {label} ({dataValues[index]}/{totalPrograms})
                </div>
              </div>
              <div className="legend-percentage">{dataPercentages[index]}%</div>
            </div>
          ))}
        </div>
      </Col>
      <Col
        sm={6}
        md={4}
        xl={5}
      >
        <div className="chart-container">
          <Doughnut
            options={chartOptions}
            data={chartData}
          />
        </div>
      </Col>
    </Row>
  );
};

export default CircleRadiusChart;
