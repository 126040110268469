import { useState, memo } from 'react';
import { Form, Col } from 'react-myoneui';
import DateTimePicker from 'react-datetime-picker';
import { closeIcon } from 'constants/icons';
function InputDate({ data, mode, number }) {
  const [activeClass, setActiveClass] = useState('');
  const [date, setDate] = useState('');
  return (
    <>
      <Col
        className="py-2"
        // md={state?.col_width}
        name="formElement"
      >
        <Form.Label
          htmlFor={data?.label}
          className="form-builder-label"
        >
          {number}. {data?.label}
        </Form.Label>
      </Col>
      <DateTimePicker
        className={`block ${activeClass}`}
        clearIcon={closeIcon}
        placeholder="Date"
        value={date}
        onChange={(value) => setDate(value)}
        onBlur={() => {
          setActiveClass('');
        }}
        onFocus={() => {
          setActiveClass('target-date-focus');
        }}
        locale="en-US"
      />
      <p className="help-text ms-2">{data?.helper_text}</p>
    </>
  );
}
export default memo(InputDate);
