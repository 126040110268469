import { ProgressBar, ProgressValue, ProgressWrap } from 'react-myoneui';
import { deadlineIcon, playBtnIcon } from 'constants/icons';
import { displayMonth, formatUTCTime } from 'utils/formatTime';
export default function IntroCard(props) {
  const { title, imgSrc, createdAt, subTitle, className, progress, onClick } = props;
  return (
    <div className={`intro-card-wrapper ${className || ''}`}>
      {title && <h2>{title}</h2>}
      <div
        className="intro-card-holder"
        onClick={onClick}
        role="button"
        tabIndex={0}
      >
        <div className="intro-card">
          <img
            loading="lazy"
            src={imgSrc}
            alt="Resume learning"
          />
          <img
            className="image-shadow"
            loading="lazy"
            src={imgSrc}
            alt="Resume learning"
          />
          <span
            role="button"
            className="play-icon"
          >
            {playBtnIcon}
          </span>
        </div>
        <div className="intro-card-info">
          <span className="intro-card-date">
            {deadlineIcon}
            <span>Enrolled at : {displayMonth(createdAt)}</span>
            <span className="dot"></span>
            <span>{formatUTCTime(createdAt)}</span>
          </span>
          {subTitle && <h4 title={subTitle}>{subTitle}</h4>}
          <ProgressWrap>
            <ProgressBar
              variant="success"
              now={progress}
            />
            <ProgressValue ProgressPercentage={`${progress}%`} />
          </ProgressWrap>
        </div>
      </div>
    </div>
  );
}
