import React from 'react';
import { Form, Modal, Button, Spinner, FloatingLabel, Row, Col } from 'react-myoneui';
import Log from 'lib/Log';
export const InputPopUpBox = (props) => {
  const {
    show,
    onChange,
    value,
    okText,
    cancelText,
    onOk,
    onHide,
    loading,
    variantBtn,
    title,
    inputField,
    inputType,
    inputLabel,
    inputPlaceholder,
    disabled,
    list,
    activeListIndex,
    onListClick,
    errMsg,
  } = props;

  const okHandler = async (e) => {
    try {
      e.preventDefault();
      onOk();
    } catch (err) {
      Log.error(err);
    }
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      contentClassName={list ? 'border rounded' : 'bg-transparent'}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          {inputField && (
            <Row>
              <Col>
                <FloatingLabel
                  controlId="text"
                  label={inputLabel}
                >
                  <Form.Control
                    type={inputType === 'email' ? 'email' : 'text'}
                    name={inputType === 'email' ? 'email' : 'text'}
                    value={value}
                    onChange={onChange}
                    placeholder={inputPlaceholder}
                  />
                </FloatingLabel>
              </Col>
            </Row>
          )}

          {list && list?.length > 0
            ? list.map((element, index) => (
                <div
                  className="d-flex overflow-hidden  position-relative my-2 py-2 px-1 bg-gray rounded btn"
                  key={index}
                  onClick={() => onListClick(element?.id, index)}
                >
                  <p className="fs-4">
                    <span className="me-2">{index + 1}.</span>
                    {element?.name}
                    {index === activeListIndex && (
                      <Spinner
                        animation="border"
                        size="sm"
                        className="ms-2"
                      />
                    )}
                  </p>
                </div>
              ))
            : ''}
        </Modal.Body>
        <Modal.Footer>
          <>
            {onHide && (
              <Button
                variant="glitter"
                onClick={onHide}
              >
                {cancelText ? cancelText : ' No, Cancel'}
              </Button>
            )}
            {onOk && (
              <Button
                variant={variantBtn}
                onClick={okHandler}
                disabled={disabled}
                type="submit"
              >
                <div className="d-flex">
                  {loading && (
                    <span>
                      <Spinner
                        animation="border"
                        size="sm"
                      />
                      &nbsp; &nbsp;
                    </span>
                  )}

                  {okText ? okText : ' Yes, Confirm'}
                </div>
              </Button>
            )}
          </>
        </Modal.Footer>
        {errMsg ? (
          <div>
            <span
              className="invalid-feedback mb-4"
              style={{ display: 'block' }}
            >
              {errMsg}
            </span>
          </div>
        ) : null}
      </Form>
    </Modal>
  );
};
