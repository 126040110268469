import { memo } from 'react';
import { Form, Col, Row } from 'react-myoneui';
import { useDispatch, useSelector } from 'react-redux';
import { storeFormData } from '../../../redux/FormDataSlice';
import Log from 'lib/Log';

function InputYesNoQuestionBlock({ data, mode }) {
  const state = useSelector((state) => state.formData.data.find((item) => item.id === data.id));
  const formData = useSelector((state) => state.formData.data);
  const dispatch = useDispatch();

  const valueControl = (e) => {
    try {
      let cloneFormData = JSON.parse(JSON.stringify(formData));
      let obj = cloneFormData.find((item) => item.id === e.target.id);
      let index = cloneFormData.indexOf(obj);
      obj.value = e.target.value;
      cloneFormData[index] = obj;
      dispatch(storeFormData(cloneFormData));
    } catch (error) {
      Log.error(error);
    }
  };
  return (
    <>
      <Row>
        <Col lg={10}>
          <Form.Label className="form-builder-label mb-0 mt-2">{state?.label}</Form.Label> <br></br>
          <Form.Label className="form-builder-label help-text">{state?.help_text}</Form.Label>
        </Col>
        <Col lg={2}>
          <div className="labeled-rating mb-2">
            <div>
              <Form.Label
                className="form-check-label fs-6"
                htmlFor="radio"
              >
                Yes
              </Form.Label>
              <Form.Check className="checkbox-border">
                <Form.Check.Input
                  className={`form-check-input`}
                  type="radio"
                  id={state?.id}
                  name={state?.id}
                  value="yes"
                  onChange={valueControl}
                  checked={state?.value == 'yes'}
                  disabled={mode === 'preview' || mode === 'readonly'}
                />
              </Form.Check>
            </div>
            <div>
              <Form.Label
                className="form-check-label fs-6"
                htmlFor="radio"
              >
                No
              </Form.Label>
              <Form.Check className="checkbox-border">
                <Form.Check.Input
                  className={`form-check-input `}
                  type="radio"
                  id={state?.id}
                  name={state?.id}
                  value="no"
                  onChange={valueControl}
                  checked={state?.value == 'no'}
                  disabled={mode === 'preview' || mode === 'readonly'}
                />
              </Form.Check>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default memo(InputYesNoQuestionBlock);
