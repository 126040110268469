import { memo, useEffect, useState } from 'react';
import SectionElement from './SectionElement';
import { setMode, setActiveEle, setIsDraged } from '../../redux/FormBuilderSlice';
import { useSelector, useDispatch } from 'react-redux';
import FormBuilderEditOption from './edit/FormBuilderEditOption';
import { Spinner } from 'react-myoneui';
import { createFormSection, createFormSubSection, getSections, getAllSavedTemplate, cloneSaveTemplate } from 'api/formBuilder';
import DragElement from './DragElement';
import DropElementsSection from './DropElementsSection';
import DropELementsDot from './DropELementsDot';
import DropElementsBox from './DropElementsBox';
import { useParams } from 'react-router-dom';
import ls from 'localstorage-slim';
import { sortElements } from 'lib/common';
import Log from 'lib/Log';
import { InputPopUpBox } from 'components/InputPopUpBox';
ls.config.encrypt = true;

function FormBuilder({ showSideBar = true }) {
  const [elements, setElements] = useState([]);
  const [dragStartStatus, setDragStartStatus] = useState(false);
  const status = useSelector((state) => state.formbuilder.mode);
  const [dragItem, setDragItem] = useState(null);
  const { formId } = useParams();
  const [loading, setLoading] = useState('no');
  const isDraged = useSelector((state) => state.formbuilder.isDraged);
  const dispatch = useDispatch();
  const fields = useSelector((state) => state.formbuilder.fields);

  const [loadTemplate, setLoadTemplate] = useState(false);
  const [show, setShow] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(false);

  const dragStart = (e) => {
    try {
      setDragStartStatus(true);
      setDragItem(JSON.parse(e.target.dataset.item));
      e.dataTransfer.setData('text', e.target.dataset.item);
      dispatch(setIsDraged(true));
    } catch (error) {
      Log.error(error);
    }
  };
  const dragEnd = (e) => {
    try {
      setDragStartStatus(false);
      dispatch(setIsDraged(false));
    } catch (error) {
      Log.error(error);
    }
  };

  const fetchSections = async () => {
    try {
      setLoading('loading');
      let result = await getSections({ formId });
      setLoading('loaded');
      let data = [...result.data.data];
      !data.length && setLoadTemplate(true);
      setElements(sortElements(data));
    } catch (error) {
      Log.error(error);
    }
  };

  const createSection = async (data) => {
    try {
      data.parent_id = formId;
      const { heading, sub_heading, parent_id, name, title } = { ...data };
      let createData = { heading, sub_heading, parent_id, name, title };
      if (data.index) {
        createData.index = data.index;
      }
      setLoading('loading');
      return await createFormSection(createData);
    } catch (error) {
      Log.error(error);
    }
  };

  const drop = async (e) => {
    try {
      // Droppable Element
      var data = e.dataTransfer.getData('text');
      data = JSON.parse(data);
      data.id = new Date().getTime().toString();
      if (data.name === 'section') {
        let result = await createSection(data);
        setElements([...elements, { ...result.data.data }]);
        setLoading('loaded');
        dispatch(setActiveEle(result.data.data));
        dispatch(setMode('edit'));
        dispatch(setIsDraged(false));
      }
      e.preventDefault();
    } catch (error) {
      Log.error(error);
    }
  };
  const dragOver = (e) => {
    try {
      e.preventDefault();
    } catch (error) {
      Log.error(error);
    }
  };

  // const arrangeElements = (fields) => {
  //   console.log(fields);
  //   let indexCol = [];
  //   fields.forEach((element) => {
  //     indexCol.push(element.index);
  //   });
  //   let arrangeIndex = indexCol.sort();
  //   console.log(arrangeIndex);
  //   let arrangeFields = [];
  //   var i = 0;
  //   console.log(fields);
  //   while (i < arrangeIndex.length) {
  //     arrangeFields.push(fields.find((field) => parseInt(field.index) === arrangeIndex[i]));
  //     i++;
  //   }
  //   console.log(arrangeFields);
  //   return arrangeFields;
  // };

  const deleteElement = (data) => {
    try {
      setElements([...elements].filter((item) => item.id !== data.id));
    } catch (error) {
      Log.error(error);
    }
  };
  const checkClick = (e) => {
    Log.info('checkClick :', e.target);
  };
  const editElement = (data) => {
    try {
      let cloneElements = [...elements];
      let obj = cloneElements.find((item) => item.id === data.id);
      let index = cloneElements.indexOf(obj);
      cloneElements[index] = { ...data };
      setElements(cloneElements);
    } catch (error) {
      Log.error(error);
    }
  };
  const sectionDrop = async (payload) => {
    try {
      let result = await createSection(payload);
      setLoading('loaded');
      var foundElement = false;
      var colls = [];
      elements.forEach((item) => {
        var myelement = { ...item };
        if (foundElement) {
          myelement.index = myelement.index + 1;
          colls.push(myelement);
        } else if (item.index === payload.index) {
          let newElement = { ...result?.data?.data };

          myelement.index = myelement.index + 1;
          colls = [...colls, newElement, myelement];
          foundElement = true;
        } else {
          colls.push(myelement);
        }
      });
      setElements(colls);
      dispatch(setMode('edit'));
      dispatch(setActiveEle(result?.data?.data));
    } catch (error) {
      Log.error(error);
    }
  };

  // fetch template

  const fetchTemplate = async () => {
    try {
      let result = await getAllSavedTemplate({ formId });
      let data = [...result?.data?.data];
      setTemplateList(data);
      data?.length > 0 && setShow(true);
      data?.length === 0 && createDefault();
    } catch (error) {
      Log.error(error);
    }
  };

  // clone saveed template in form
  const cloneTemplate = async (template_id, index) => {
    try {
      let payload = {
        form_component_id: formId,
        template_id: template_id,
      };
      setLoading(true);
      setActiveIndex(index);
      const result = await cloneSaveTemplate(payload);
      Log.info(result);
      if (result?.data?.message === 'Form component template clone successfully') {
        setShow(false);
        fetchSections();
      }
    } catch (error) {
      Log.error('Error on cloning Form Component Handler', error);
    }
  };

  //  make a default section and subsection if user does not select template
  const createDefault = () => {
    if (templateList.length === 0) {
      createDefaultSection();
    } else if (templateList.length > 0 && elements.length === 0) {
      createDefaultSection();
    }
  };

  const createDefaultSection = async () => {
    try {
      let payload = {
        parent_id: formId,
        name: 'section',
        heading: 'Section 1',
        sub_heading: 'Single line or multiline text area',
        title: 'Section',
      };
      const result = await createSection(payload);
      if (result) {
        const sectionId = result?.data?.data?._id;
        createDefaultSubSection(sectionId);
      }
    } catch (error) {
      Log.error(error);
      setLoading('loaded');
    }
  };

  const createDefaultSubSection = async (sectionId) => {
    try {
      const payload = {
        name: 'sub-section',
        heading: 'Sub Section 1',
        sub_heading: 'Single line or multiline text area',
        title: 'Sub Section',
        parent_id: sectionId,
      };
      const result = await createFormSubSection(payload);
      if (result) {
        fetchSections();
      }
    } catch (error) {
      Log.error(error);
    }
  };

  useEffect(() => {
    fetchSections();
  }, []);

  useEffect(() => {
    loadTemplate && fetchTemplate();
  }, [loadTemplate]);

  return (
    <>
      <InputPopUpBox
        show={show}
        listField={true}
        title={'Choose Any Default Template'}
        list={templateList}
        activeListIndex={activeIndex}
        cancelText={'Cancel'}
        onHide={() => {
          setShow(false);
          createDefault();
        }}
        onListClick={cloneTemplate}
      />
      {showSideBar && (
        <div
          className="form-builder-sidebar"
          onClick={checkClick}
        >
          {(status === 'view' || status === 'active') &&
            fields.map((item) => (
              <div
                key={item.id}
                draggable={true}
                onDragStart={dragStart}
                onDragEnd={dragEnd}
                data-item={JSON.stringify(item)}
              >
                <DragElement
                  field={item}
                  id={item.id}
                />
              </div>
            ))}

          {status === 'edit' && <FormBuilderEditOption />}
        </div>
      )}
      <div className="form-builder-content">
        <div className={`personal-details-form ${isDraged === true ? 'form-dashed-active' : ''}`}>
          {(dragStartStatus && dragItem.name === 'section') || elements.length === 0 ? (
            <div
              onDrop={drop}
              onDragOver={dragOver}
            >
              <DropElementsSection className={`${isDraged === true && dragItem.name != 'section' ? 'drop-elements-not-equal-secondary' : 'drop-elements-secondary'}`}>
                <DropELementsDot />
                <DropElementsBox title="Drag Section Here" />
              </DropElementsSection>
            </div>
          ) : (
            ''
          )}

          {loading === 'loading' ? (
            <div className="spinner-wrapper">
              <Spinner animation="border" />
            </div>
          ) : (
            elements &&
            elements.length > 0 &&
            elements
              .filter((item) => item.parent_id === formId)
              .map((ele) => (
                <SectionElement
                  data={ele}
                  id={ele.id}
                  onChange={(data) => dispatch(setElements(data))}
                  key={ele.id}
                  onDrop={drop}
                  onDragOver={dragOver}
                  dragStatus={dragStartStatus}
                  dragItem={dragItem}
                  onDelete={deleteElement}
                  onEdit={editElement}
                  onSectionDrop={sectionDrop}
                  onDragItem={(item) => setDragItem(item)}
                  onLoadSections={fetchSections}
                />
              ))
          )}
        </div>
      </div>
    </>
  );
}
export default memo(FormBuilder);
