import { memo, useEffect, useState } from 'react';
import { blackArrowLeftIcon, scoreTargetIcon, sendIcon } from 'constants/icons';
import { Button, Badge } from 'react-myoneui';
import DMSUploadBtn from './DMSUploadBtn';
import { ProgressWrap, ProgressValue, ProgressBar } from 'react-myoneui';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import AddComponentScoreOffCanvas from '../AddComponentScore';
import { useSelector, useDispatch } from 'react-redux';
import { updateAchievedComponentScore } from 'api/learner/program';
import { setSelectedProgramComponent } from '../../../../redux';
import ls from 'localstorage-slim';
import Log from 'lib/Log';
import DropdownCommon from 'components/DropdownCommon';
import { getOrgSetting } from 'api';
import { InputPopUpBox } from 'components/InputPopUpBox';
import { sendReminder } from 'api/learner/section';

const ComponentHeader = ({
  backStatus = true,
  role,
  componentName,
  yourHours,
  requiredHours,
  can_manage_apprentices,
  status = null,
  isLearner,
  onBack,
  onMarkAsComplete,
  onChangeStatus = () => {},
}) => {
  const isValidateReviewComponentBtn = () => {
    let status;
    if (role === 'assessor' || role === 'tutor') {
      status = true;
    } else if (role === 'admin' && can_manage_apprentices === true) {
      status = true;
    } else {
      status = false;
    }

    return status;
  };

  // update score start here
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const orgId = ls.get('orgId');
  const memberProgram = useSelector((state) => state.memberProgram);
  const selectedComponent = useSelector((state) => state.sections.active_component);
  const memberSection = selectedComponent?.id;

  //reminder
  const activeRole = JSON.parse(ls.get('activeRole'));
  const token = ls.get('token');
  const [isRemind, setIsRemind] = useState(false);
  const [reminder, setReminder] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [remindLoading, setRemindLoading] = useState(false);
  const [note, setNote] = useState('');

  useEffect(() => {
    const fetchOrgSettings = async () => {
      try {
        const {
          data: { data },
        } = await getOrgSetting({ orgId, token });
        if (data?.enable_reminder && activeRole?.privileges?.can_manage_apprentices) {
          setIsRemind(true);
        }
      } catch (err) {
        Log.error(err);
      }
    };
    fetchOrgSettings();
  }, [activeRole?.privileges?.can_manage_apprentices, orgId, token]);

  const sendRemind = async () => {
    try {
      setRemindLoading(true);
      const payload = {
        member_section: memberSection,
        note,
      };
      await sendReminder({ orgId, payload });
      hideReminderBox();
    } catch (err) {
      Log.error(err);
      setErrMsg(err?.response?.data?.message ?? err?.message);
    } finally {
      setRemindLoading(false);
    }
  };

  const hideReminderBox = () => {
    setReminder(false);
    setNote('');
    setErrMsg('');
  };

  const handleUpdate = () => {
    setShow(true);
  };

  const map_status = {
    'Not Started': [{ id: 1, name: 'In Progress' }],
    'In Progress': [{ id: 1, name: 'Not Started' }],
    Submitted: [
      { id: 1, name: 'Not Started' },
      { id: 2, name: 'In Progress' },
    ],
    Completed: [
      { id: 1, name: 'Not Started' },
      { id: 2, name: 'In Progress' },
      { id: 3, name: 'Submitted' },
    ],
  };

  // const [defaultValue, setDefaultValue] = useState(status ? map_status[status][0].name : '');

  const onUpdate = async (data) => {
    const achieved_score = Number(data);
    try {
      const response = await updateAchievedComponentScore({ orgId, memberSection, achieved_score });
      if (response?.status === 200) {
        dispatch(setSelectedProgramComponent({ ...selectedComponent, achieved_score: achieved_score }));
      }
    } catch (error) {
      Log.error('err..', error);
    }
  };

  return (
    <div className="program-fixed-header">
      <AddComponentScoreOffCanvas
        show={show}
        onClose={() => setShow(false)}
        selectedComponent={selectedComponent}
        component_type={'component'}
        title={selectedComponent?.section?.title}
        achieved_score={selectedComponent?.achieved_score}
        section_score={selectedComponent?.section_score}
        onUpdate={onUpdate}
      />

      {backStatus ? (
        <Button
          variant="text-icon"
          className="p-0 back-btn"
          onClick={() => onBack()}
        >
          {blackArrowLeftIcon} Back
        </Button>
      ) : (
        <div></div>
      )}

      {/* score btn */}
      <div
        className="program-button-wrap"
        // onClick={handleUpdate}
      >
        {can_manage_apprentices && status === 'Completed' && memberProgram?.program?.component_score && selectedComponent?.section_score > 0 && (
          <div
            className="update-score-link"
            onClick={handleUpdate}
          >
            <span className="update-score-icon">{scoreTargetIcon}</span>
            {selectedComponent?.achieved_score} / {selectedComponent?.section_score}
          </div>
        )}
        {status !== 'Completed' &&
          componentName !== 'ReviewComponent' &&
          componentName !== 'QuizComponent' &&
          componentName !== 'ExternalExamComponent' &&
          componentName !== 'EventComponent' &&
          componentName !== 'LtiComponent' && <DMSUploadBtn />}
        {componentName === 'OTJComponent' && status !== null && (
          <div className="progressbar-width-wrap">
            <ProgressWrap>
              <ProgressValue ProgressPercentage={`${!isNaN(yourHours / (yourHours + requiredHours)) ? Math.round((yourHours / (yourHours + requiredHours)) * 100) : 0}%`} />
              <ProgressBar
                variant="info"
                now={(yourHours / (yourHours + requiredHours)) * 100}
              />
            </ProgressWrap>
          </div>
        )}

        {isRemind ? (
          <button
            onClick={() => setReminder(true)}
            type="button"
            className="border border-primary text-primary btn btn-outline"
          >
            Remind {sendIcon}
          </button>
        ) : null}

        {/* {status && can_manage_apprentices && !isLearner ? (
          <DropdownCommon
            name="change_status"
            defaultValue={defaultValue}
            items={map_status[status]}
            label={'Change Status'}
            onChange={(data) => onChangeStatus(data.name)}
          />
        ) : null} */}

        {((componentName === 'OTJComponent' && requiredHours === 0) || (componentName !== 'OTJComponent' && status === 'Submitted')) &&
          can_manage_apprentices &&
          status !== 'Completed' &&
          !isLearner && (
            <Button
              variant="outline"
              className="dark"
              onClick={onMarkAsComplete}
            >
              Mark as Complete
            </Button>
          )}
        {componentName === 'ReviewComponent' && status === 'In Progress' && isValidateReviewComponentBtn() === true && (
          <Button
            variant="outline"
            className="dark"
            onClick={onMarkAsComplete}
          >
            Mark as Complete
          </Button>
        )}
        {status === 'ReSubmission' && <Badge className="resubmission">{status}</Badge>}
        {status === 'Completed' && <Badge className="badge">{status}</Badge>}
        {status === 'Submitted' && <Badge className="badge warning">{status}</Badge>}
      </div>
      <InputPopUpBox
        show={reminder}
        loading={remindLoading}
        title={'Reminder'}
        inputField={true}
        inputType={'reminder'}
        inputLabel={'Please provide note for reminder'}
        inputPlaceholder={''}
        value={note}
        disabled={false}
        onChange={(e) => setNote(e.target.value)}
        onOk={() => sendRemind()}
        okText={'Confirm'}
        onHide={() => hideReminderBox()}
        cancelText={'Cancel'}
        errMsg={errMsg}
      />
    </div>
  );
};
export default memo(ComponentHeader);
