import axios from 'axios';
import { API_URL } from '../../constants/config';
import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;
export const createExternalComponent = async (data) => {
  const { orgId, ...payload } = data;
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/lti/admin/component/create`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const updateExternalComponent = async (data) => {
  try {
    const { orgId, ...payload } = data;
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: orgId,
      },
    };

    return await axios.patch(`${API_URL}/lti/admin/component/update`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const getLtiProviders = async () => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };
    return await axios.get(`${API_URL}/lti/providers`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const launchExternalComponent = async (data) => {
  const { orgId, ...payload } = data;
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: ls.get('orgId') || orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/lti/lunch-resource`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const createDeepLink = async (data) => {
  const { orgId, ...payload } = data;
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: ls.get('orgId') || orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/lti/deepLinking`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};
