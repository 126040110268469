import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { lazyWithRetry } from 'components/lazyWithRetry';
import ls from 'localstorage-slim';
import LearnerPrograms from 'modules/learner/program/learners';
import LearnerProgramsList from 'modules/learner/program/learners/LearnerProgramsList';

const ComponentDetail = lazyWithRetry(() => import('modules/learner/program/ComponentDetail'));
const NoMatch = lazyWithRetry(() => import('modules/NoMatch'));
const LearnerLayout = lazyWithRetry(() => import('layouts/LearnerLayout/'));
const LearnerDashboard = lazyWithRetry(() => import('modules/learner/dashboard'));
const ApprenticesDashboard = lazyWithRetry(() => import('modules/learner/apprentices/dashboard'));
const LearnerProgram = lazyWithRetry(() => import('modules/learner/program'));
const LearnersContacts = lazyWithRetry(() => import('modules/learner/contacts'));
const LectureList = lazyWithRetry(() => import('modules/learner/program/LectureList'));
const CustomFields = lazyWithRetry(() => import('modules/learner/program/CustomFields/'));
const TaskList = lazyWithRetry(() => import('modules/learner/program/TaskList/'));
const Target = lazyWithRetry(() => import('modules/learner/program/Target/'));
const Tutors = lazyWithRetry(() => import('modules/learner/program/Tutors'));
const DocumentBank = lazyWithRetry(() => import('modules/learner/program/DocumentBank'));
const QuickLinks = lazyWithRetry(() => import('modules/learner/program/QuickLinks/'));
const Resubmission = lazyWithRetry(() => import('modules/learner/program/Resubmission/'));
const ActivityDetail = lazyWithRetry(() => import('../../modules/learner/program/ActivityDetail'));
const Profile = lazyWithRetry(() => import('../../modules/admin/profile/Profile'));
const ProfileActivity = lazyWithRetry(() => import('../../modules/admin/profile'));
const Notification = lazyWithRetry(() => import('components/notification'));
const JourneyWorkerRate = lazyWithRetry(() => import('modules/learner/journeyWorkerRate'));
const WageSchedule = lazyWithRetry(() => import('modules/admin/wage'));
const SelfEnrollment = lazyWithRetry(() => import('modules/learner/enrollment/SelfEnrollment'));
const ProgramReport = lazyWithRetry(() => import('modules/learner/program/Reports'));
const Login = lazyWithRetry(() => import('modules/Login'));

export default (
  <Routes>
    <Route
      path="apprentices/dashboard/"
      element={<ApprenticesDashboard />}
    />
    {/* <Route
      path="learner/"
      element={<LearnerLayout />}
    > */}
    <Route
      path="learner/"
      element={ls.get('userid') ? <LearnerLayout /> : <Login />}
    >
      <Route
        path="dashboard"
        element={<LearnerPrograms />}
      />
      <Route
        path="program/"
        element={<LearnerProgram />}
      />

      <Route
        path="programs/programs-list"
        element={<LearnerProgramsList />}
      />

      <Route
        path="self-enroll/program/:jwtId"
        element={<SelfEnrollment />}
      />
      <Route
        path="contacts/"
        element={<LearnersContacts />}
      />
      <Route
        path="wage-dashboard/"
        element={<JourneyWorkerRate />}
      />
      <Route
        path="wage/:memberProgramId"
        element={<WageSchedule />}
      />
      <Route
        path="notification/"
        element={<Notification />}
      />
      <Route
        path="program/:programId/"
        element={<LectureList />}
      />
      <Route
        path="program/:programId/lecture-list"
        element={<LectureList />}
      />
      <Route
        path="program/:programId/lecture-list/:sectionid"
        element={<ComponentDetail backBtnStatus={true} />}
      />
      <Route
        path="program/:programId/custom-fields"
        element={<CustomFields />}
      />
      <Route
        path="/learner/program/:programId/reports"
        element={<ProgramReport />}
      />
      <Route
        path="program/:programId/lecture-list/:lectureDetail/activitityDetail"
        element={<ActivityDetail />}
      />
      <Route
        path="program/:programId/lecture-list/:lectureDetail/activitityDetail/:activityId/:memberSectionId"
        element={<ActivityDetail />}
      />
      <Route
        path="program/:programId/lecture-list/:lectureDetail/:otj_id/activitityDetail/:activityId/:memberSectionId"
        element={<ActivityDetail backBtnStatus={true} />}
      />
      <Route
        path="profile/edit-profile/:id"
        element={<Profile />}
      />
      <Route
        path="program/:programId/task-list/"
        element={<TaskList />}
      />
      <Route
        path="program/:programId/task-list/:lectureDetail/activitityDetail/"
        element={<ActivityDetail />}
      />
      <Route
        path="program/:programId/task-list/:otj_id/activitityDetail/:activityId/:memberSectionId"
        element={<ActivityDetail backBtnStatus={true} />}
      />
      <Route
        path="program/:member_program_id/target/"
        element={<Target />}
      />
      <Route
        path="program/tutors/*"
        element={<Tutors />}
      />
      <Route
        path="program/document-bank/*"
        element={<DocumentBank />}
      />
      <Route
        path="program/:programId/quick-links/*"
        element={<QuickLinks />}
      />
      <Route
        path="program/:programId/resubmission"
        exact
        element={<Resubmission />}
      />
      <Route
        path="program/:programId/resubmission/:otj_id/activitityDetail/:activityId/:memberSectionId"
        element={<ActivityDetail backBtnStatus={true} />}
      />
      <Route
        path="program/:programId/quick-links/:lectureDetail/activitityDetail/"
        element={<ActivityDetail backBtnStatus={true} />}
      />
      <Route
        path="program/:programId/quick-links/:otj_id/activitityDetail/:activityId/:memberSectionId"
        element={<ActivityDetail backBtnStatus={true} />}
      />
      <Route
        path="view-profile/:id"
        element={<ProfileActivity />}
      />
      <Route
        path="*"
        element={<NoMatch />}
      />
    </Route>
  </Routes>
);
