import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [] };

export const ExternalExamDataSlice = createSlice({
  name: 'ExternalExamData',
  initialState,
  reducers: {
    storeExternalExamData: (state, action) => {
      state.data = action.payload;
    },
    addExternalExamData: (state, action) => {
      state.data.push(action.payload);
    },
    flushExternalExamData: () => initialState,
    updateValue: (state, action) => {
      const { id, value } = action.payload;
      const itemIndex = state.data.findIndex((item) => item._id === id);

      if (itemIndex > -1) {
        // Update the value of the specific item
        state.data[itemIndex].value = value;
      }
    },
  },
});

export const activeRole = (state) => state;
export const { storeExternalExamData, addExternalExamData, flushExternalExamData, updateValue } = ExternalExamDataSlice.actions;
export default ExternalExamDataSlice.reducer;
