import { memo, useEffect, useState } from 'react';
import { blackArrowLeftIcon, scoreTargetIcon } from 'constants/icons';
import { Button, Badge } from 'react-myoneui';
import DMSUploadBtn from './DMSUploadBtn';
import { ProgressWrap, ProgressValue, ProgressBar } from 'react-myoneui';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import AddComponentScoreOffCanvas from '../AddComponentScore';
import { useSelector, useDispatch } from 'react-redux';
import { updateAchievedComponentScore } from 'api/learner/program';
import { setSelectedProgramComponent } from '../../../../redux';
import ls from 'localstorage-slim';
import Log from 'lib/Log';

const ComponentHeader = ({ backStatus = true, role, componentName, yourHours, requiredHours, can_manage_apprentices, status = null, isLearner, onBack, onMarkAsComplete }) => {
  const isValidateReviewComponentBtn = () => {
    let status;
    if (role === 'assessor' || role === 'tutor') {
      status = true;
    } else if (role === 'admin' && can_manage_apprentices === true) {
      status = true;
    } else {
      status = false;
    }

    return status;
  };

  // update score start here
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const orgId = ls.get('orgId');
  const memberProgram = useSelector((state) => state.memberProgram);
  const selectedComponent = useSelector((state) => state.sections.active_component);
  const memberSection = selectedComponent?.id;
  const handleUpdate = () => {
    setShow(true);
  };

  const onUpdate = async (data) => {
    const achieved_score = Number(data);
    try {
      const response = await updateAchievedComponentScore({ orgId, memberSection, achieved_score });
      if (response?.status === 200) {
        dispatch(setSelectedProgramComponent({ ...selectedComponent, achieved_score: achieved_score }));
      }
    } catch (error) {
      Log.error('err..', error);
    }
  };

  return (
    <div className="program-fixed-header">
      <AddComponentScoreOffCanvas
        show={show}
        onClose={() => setShow(false)}
        selectedComponent={selectedComponent}
        component_type={'component'}
        title={selectedComponent?.section?.title}
        achieved_score={selectedComponent?.achieved_score}
        section_score={selectedComponent?.section_score}
        onUpdate={onUpdate}
      />

      {backStatus ? (
        <Button
          variant="text-icon"
          className="p-0 back-btn"
          onClick={() => onBack()}
        >
          {blackArrowLeftIcon} Back
        </Button>
      ) : (
        <div></div>
      )}

      {/* score btn */}
      <div
        className="program-button-wrap"
        // onClick={handleUpdate}
      >
        {can_manage_apprentices && status === 'Completed' && memberProgram?.program?.component_score && selectedComponent?.section_score > 0 && (
          <div
            className="update-score-link"
            onClick={handleUpdate}
          >
            <span className="update-score-icon">{scoreTargetIcon}</span>
            {selectedComponent?.achieved_score} / {selectedComponent?.section_score}
          </div>
        )}
        {status !== 'Completed' && componentName !== 'ReviewComponent' && componentName !== 'QuizComponent' && componentName !== 'ExternalExamComponent' && componentName !== 'EventComponent' && (
          <DMSUploadBtn />
        )}
        {componentName === 'OTJComponent' && status !== null && (
          <div className="progressbar-width-wrap">
            <ProgressWrap>
              <ProgressValue ProgressPercentage={`${!isNaN(yourHours / (yourHours + requiredHours)) ? Math.round((yourHours / (yourHours + requiredHours)) * 100) : 0}%`} />
              <ProgressBar
                variant="info"
                now={(yourHours / (yourHours + requiredHours)) * 100}
              />
            </ProgressWrap>
          </div>
        )}
        {((componentName === 'OTJComponent' && requiredHours === 0) || (componentName !== 'OTJComponent' && status === 'Submitted')) &&
          can_manage_apprentices &&
          status !== 'Completed' &&
          !isLearner && (
            <Button
              variant="outline"
              className="dark"
              onClick={onMarkAsComplete}
            >
              Mark as Complete
            </Button>
          )}
        {componentName === 'ReviewComponent' && status === 'In Progress' && isValidateReviewComponentBtn() === true && (
          <Button
            variant="outline"
            className="dark"
            onClick={onMarkAsComplete}
          >
            Mark as Complete
          </Button>
        )}
        {status === 'ReSubmission' && <Badge className="resubmission">{status}</Badge>}
        {status === 'Completed' && <Badge className="badge">{status}</Badge>}
        {status === 'Submitted' && <Badge className="badge warning">{status}</Badge>}
      </div>
    </div>
  );
};
export default memo(ComponentHeader);
