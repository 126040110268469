import { Route } from 'react-router-dom';
import { lazyWithRetry } from 'components/lazyWithRetry';

const ComponentList = lazyWithRetry(() => import('modules/admin/externalExam/componentList'));
const LearnerList = lazyWithRetry(() => import('modules/admin/externalExam/learnerList'));

export default (
  <>
    <Route
      path="external-exam"
      element={<ComponentList />}
    />
    <Route
      path="external-exam/:examId"
      element={<LearnerList />}
    />
  </>
);
