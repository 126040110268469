export default function LearnerHeading(props) {
  const { className, svgIcon, title, children } = props;
  return (
    <div className={`learner-heading ${className || ''}`}>
      <div className="info-wrap">
        {svgIcon && <>{svgIcon}</>}
        {title && <h4>{title}</h4>}
      </div>
      {children}
    </div>
  );
}
