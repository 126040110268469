import { useState, memo } from 'react';
import { Button } from 'react-myoneui';

// import Log from 'lib/Log';

const EventPreview = ({ selectedComponent }) => {
  const [value, setValue] = useState('');

  return (
    <>
      <p className="fw-bold fs-2 mb-0">Mark Attendees</p>
      <p className="help-text">Please verify attendees </p>
      <div className="d-flex gap-4">
        <Button
          variant="default"
          className={`btn border-success ${value === 'present' && 'bg-secondary'}`}
          onClick={() => setValue('present')}
        >
          Present
        </Button>
        <Button
          variant="default"
          className={`btn border-danger ${value === 'absent' && 'bg-danger'}`}
          onClick={() => setValue('absent')}
        >
          Absent
        </Button>
      </div>
    </>
  );
};

export default memo(EventPreview);
