import { useState, useEffect, memo } from 'react';
import SecondaryHeader from 'react-myoneui/lib/SecondaryHeader';
import SecondaryHeaderInfo from 'react-myoneui/lib/SecondaryHeaderInfo';
import { Spinner } from 'react-myoneui';
import LinkWrapper from 'components/LinkWrapper';
import Button from 'react-myoneui/lib/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { getFormComponent, saveFormComponentTemplate } from 'api/formBuilder';
import Log from 'lib/Log';

// Icons
import { previewContentIcon, crossIcon, openIcon } from 'constants/icons';
import { setSidebarData } from 'reducers/sidebarSlice';
import { useDispatch, useSelector } from 'react-redux';
import FormBuilder from 'components/FormBuilder';
import { setToastBoxStatus, setToastBoxDescription } from 'redux/ToastBoxSlice';
import ToastBox from 'components/ToastBox';
import { InputPopUpBox } from 'components/InputPopUpBox';

function AddingNewForm() {
  const dispatch = useDispatch();
  const [showSideBar, setSideBar] = useState(true);
  const enableActiveWrapper = useSelector((state) => state.sidebar.enableActiveWrapper);
  const { formId } = useParams();
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const navigate = useNavigate();

  const fetchFormInfo = async () => {
    let result = await getFormComponent(formId);
    setForm(result.data.data);
  };
  const toggleSideBar = () => {
    setSideBar(!showSideBar);
  };

  // save as draft api
  const saveAsTemplate = async (e) => {
    try {
      e.preventDefault();
      let payload = {
        form_component_id: form?.id,
        name: templateName,
      };
      setLoading(true);
      const result = await saveFormComponentTemplate(payload);
      Log.info(result);
      if (result?.data?.message === 'Form component template successfully Created') {
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription('Successfully Updated'));
        setTemplateName('');
        setShow(false);
        // setLoading(false);
      }
    } catch (error) {
      Log.error('Error on edit Form Component Handler', error);
      // setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(setSidebarData({ enableActiveWrapper: true }));
    return () => {
      dispatch(setSidebarData({ enableActiveWrapper: false }));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!enableActiveWrapper) {
      setTimeout(() => {
        dispatch(setSidebarData({ enableActiveWrapper: true }));
      }, 1);
    }
  }, [enableActiveWrapper, dispatch]);
  useEffect(() => {
    fetchFormInfo();
  }, []);

  return (
    <>
      <ToastBox />
      <InputPopUpBox
        show={show}
        loading={loading}
        title={'Template Name'}
        inputField={true}
        inputLabel={'Template Name'}
        inputPlaceholder={''}
        value={templateName}
        disabled={!templateName}
        onChange={(e) => setTemplateName(e.target.value)}
        onOk={saveAsTemplate}
        okText={'Save'}
        onHide={() => setShow(false)}
        cancelText={'Close'}
      />
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryHeaderTitle={form ? form.name : ''}
            SecondaryHeaderText=""
            SecondaryText
          />
        </div>
        <div className="header-close-wrap res-close-wrap">
          <div className="button-wrap">
            <Button
              variant="outline"
              className="dark"
              // saveAsTemplate
              onClick={() => setShow(true)}
            >
              Save As Template
            </Button>
            <Button
              variant="text-icon"
              className="text-icon"
              onClick={() => navigate(`/admin/form-builder/${formId}/preview`)}
            >
              Preview Content
              {previewContentIcon}
            </Button>
          </div>
          <div className="icon-wrap">
            <LinkWrapper
              href="#top"
              onClick={toggleSideBar}
            >
              {showSideBar ? <span>{crossIcon}</span> : <span className="open-icon">{openIcon}</span>}
            </LinkWrapper>
          </div>
        </div>
      </SecondaryHeader>
      <div className="form-builder-wrapper">
        <FormBuilder showSideBar={showSideBar} />
      </div>
    </>
  );
}
export default memo(AddingNewForm);
