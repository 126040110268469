import { memo, useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-myoneui';

function InputYesNoQuestionBlock({ data }) {
  const [state, setState] = useState({ ...data });
  useEffect(() => {
    setState(data);
  }, [data]);

  return (
    <>
      <Row>
        <Col lg={10}>
          <Form.Label className="form-builder-label mb-0 mt-2">{state?.label}</Form.Label> <br></br>
          <Form.Label className="form-builder-label  help-text">{state?.help_text}</Form.Label>
        </Col>
        <Col lg={2}>
          <div className="labeled-rating mb-2">
            <div>
              <Form.Label
                className="form-check-label fs-6"
                htmlFor="radio"
              >
                Yes
              </Form.Label>
              <Form.Check className="checkbox-border">
                <Form.Check.Input
                  className="form-check-input"
                  type="radio"
                  id={state?.id}
                  name={state?.id}
                  value="1"
                />
              </Form.Check>
            </div>
            <div>
              <Form.Label
                className="form-check-label fs-6"
                htmlFor="radio"
              >
                No
              </Form.Label>
              <Form.Check className="checkbox-border">
                <Form.Check.Input
                  className="form-check-input"
                  type="radio"
                  id={state?.id}
                  name={state?.id}
                  value="0"
                />
              </Form.Check>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default memo(InputYesNoQuestionBlock);
