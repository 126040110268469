import { certificateIcon, deadlineIcon, menuBookIcon, submittedIcon, coursIcon } from 'constants/icons';
import { useState, useEffect, useRef } from 'react';
import { getLearnerActivity } from 'api/learner/dashboard';
import Spinner from 'react-myoneui/lib/Spinner';
import BasicContentLoader from 'components/basicContentLoader';
import { displayMonth, formatUTCTime } from 'utils/formatTime';
import Log from 'lib/Log';

export default function MyActivities() {
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const scrollDiv = useRef();

  const fetchActivities = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      if (hasMore) {
        const result = await getLearnerActivity({ currentPage: page, perPage: 30 });
        const activitiesData = result?.data?.data;
        if (activitiesData) {
          // setActivities((prevActivities) => [...prevActivities, ...activitiesData.activities]);
          setActivities((prevActivities) => [...prevActivities, ...activitiesData.activities].sort((a, b) => new Date(b.date) - new Date(a.date)));
          setHasMore(activitiesData.meta?.hasNextPage);
        }
      }
    } catch (error) {
      Log.error(error);
      setHasMore(false);
    } finally {
      setIsScrolling(false);
      setIsLoading(false);
    }
  };

  const iconHandler = (status) => {
    const icons = {
      Started: { icon: menuBookIcon, bgColor: 'bg-started' },
      Submitted: { icon: submittedIcon, bgColor: 'bg-submitted' },
      ReSubmission: { icon: coursIcon, bgColor: 'bg-live' },
      Completed: { icon: certificateIcon, bgColor: 'bg-completed' },
    };
    return icons[status] || { icon: submittedIcon, bgColor: 'bg-submitted' };
  };

  useEffect(() => {
    fetchActivities();
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollDiv.current.scrollHeight - scrollDiv.current.offsetHeight <= scrollDiv.current.scrollTop + 2) {
        setIsScrolling(true);
        setPage((prev) => prev + 1);
      }
    };
    const sd = scrollDiv.current;
    sd.addEventListener('scroll', handleScroll);
    return () => sd.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="activities-content">
      <ul
        className="activity-content-list"
        ref={scrollDiv}
      >
        {activities.map((activity, index) => {
          const { icon, bgColor } = iconHandler(activity.status);
          return (
            <li key={index}>
              <div className={`icon-wrap ${bgColor}`}>{icon}</div>
              <div className="info-wrap">
                <h4>
                  {activity.status} <span>{activity.title}</span>
                </h4>
                <div className="date-wrap">
                  {deadlineIcon}
                  <span>{displayMonth(activity.date)}</span>
                  <span className="dot"></span>
                  <span>{formatUTCTime(activity.date)}</span>
                </div>
              </div>
            </li>
          );
        })}
        {isScrolling && activities.length > 0 && (
          <div className="text-center my-2">
            <Spinner
              animation="border"
              size="md"
            />
          </div>
        )}
        {isLoading && activities.length === 0 && <BasicContentLoader />}
      </ul>
    </div>
  );
}
