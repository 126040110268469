import * as React from 'react';
import { useEffect } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Card from 'react-myoneui/lib/Card';
import Button from 'react-myoneui/lib/Button';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import { removeIcon } from '../../../constants/icons';
import { useState } from 'react';
import { editReview } from 'api/review';
import { useSelector, useDispatch } from 'react-redux';
import { translate } from '../../../lib';

import Spinner from 'react-myoneui/lib/Spinner';
import { useParams } from 'react-router-dom';
import ls from 'localstorage-slim';
import { decryptData } from '../../../lib';
import Log from '../../../lib/Log';
import AddResourseInfoBlock from 'modules/admin/program/library/components/Resources/AddResourseInfoBlock';
import UploadFileOffCanvas from 'modules/admin/program/library/components/Resources/UploadFileOffCanvas';
import { ConfirmBox } from 'components/ConfirmBox';
import TextEditor from 'components/TextEditor';
import { setToastBoxStatus, setToastBoxDescription } from 'redux/ToastBoxSlice';

ls.config.encrypt = true;
function EditReviewOffCanvas({ reviewId, onClose, show, isValidRole, data }) {
  const handleClose = (clear) => {
    try {
      setLoad(false);
      setLoad(false);
      setBasicInfo({
        title: '',
        description: '',
      });
      setUploadedFiles([]);
      if (clear !== true) {
        onClose();
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const organisationId = ls.get('orgId');

  const offCanvasKeyDown = (e) => e.stopPropagation();

  const taxonomy = useSelector((state) => state.taxonomy.data);
  const [load, setLoad] = useState(false);
  const [basicInfo, setBasicInfo] = useState({
    title: data.subject,
    description: data.description,
  });

  const [uploadedFiles, setUploadedFiles] = useState(data.uploaded_file);
  const [fileshow, setFileshow] = useState(false);
  const [resourceToRemove, setResourceToRemove] = useState(null);
  const [fileToRemove, setFileToRemove] = useState(null);
  const dispatch = useDispatch();
  const [removeUploadedFile, setRemoveUploadedFile] = useState(false);
  const [resourceInputField, setResourceInputField] = useState([]);

  const handleShow = () => setFileshow(true);
  const [addLoading, setAddLoading] = useState(false);
  const { programId, orgId, id, sectionId } = useParams();

  const UpdateReview = async () => {
    try {
      const payload = {
        subject: basicInfo.title,
        description: basicInfo?.description,
        uploaded_file: uploadedFiles,
        orgId: organisationId,
        id: reviewId,
        programId: id,
      };

      setAddLoading(true);
      setLoad(true);

      const result = await editReview(payload);
      setLoad(false);

      setAddLoading(false);
      dispatch(setToastBoxStatus(true));
      dispatch(setToastBoxDescription(`${translate(taxonomy)('review')} has beeen Updated Successfully`));
      handleClose();
    } catch (error) {
      setAddLoading(false);
      Log.error(error);
      dispatch(setToastBoxStatus(true));
      dispatch(setToastBoxDescription(error?.data?.message ? error?.data?.message : `Something went wrong while updating ${translate(taxonomy)('review')} `));
    }
  };

  const removeResource = (index) => {
    try {
      const resources = [...resourceInputField];
      resources.splice(index, 1);
      setResourceInputField(resources);
      setResourceInputField(resources);
      setRemoveUploadedFile(false);
      setResourceToRemove(null);
    } catch (error) {
      Log.error('Error:AddResources/removeResource', error);
    }
  };

  const removeFile = (index) => {
    try {
      const resources = [...uploadedFiles];
      resources.splice(index, 1);
      setUploadedFiles(resources);
      setUploadedFiles(resources);
      setRemoveUploadedFile(false);
      setFileToRemove(null);
    } catch (error) {
      Log.error('Error:AddResources/removeFile', error);
    }
  };
  const saveFiles = (res) => {
    try {
      setUploadedFiles([
        ...uploadedFiles,
        {
          url: res.link,
          title: res.name,
        },
      ]);
      setFileshow(false);
    } catch (error) {
      Log.error('Error:AddResources/saveFiles', error);
    }
  };

  return (
    <>
      {fileshow && (
        <UploadFileOffCanvas
          show={fileshow}
          onClose={() => setFileshow(false)}
          onSave={saveFiles}
        />
      )}
      {setRemoveUploadedFile && (
        <ConfirmBox
          show={removeUploadedFile}
          question={`Are you sure you want to remove this ${fileToRemove ? 'File' : 'Resource'}?`}
          okText="Yes"
          cancelText="No"
          onHide={() => {
            setRemoveUploadedFile(false);
          }}
          onOk={() => (fileToRemove ? removeFile(Number(fileToRemove) - 1) : removeResource(Number(resourceToRemove) - 1))}
        />
      )}
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Header
          closeButton
          backButton
          className="pb-0"
        >
          <Offcanvas.Title>
            Edit <br />
            {`${translate(taxonomy)('review')}`}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mb-4">
            <FloatingLabel
              controlId="floatingInputTitle"
              label="Subject"
              className="mb-3"
            >
              <Form.Control
                tabIndex="1"
                type="text"
                placeholder="Subject"
                value={basicInfo?.title}
                onChange={(e) => setBasicInfo({ ...basicInfo, title: e.target.value })}
              />
            </FloatingLabel>
            <Card className="react-quill-editor">
              <TextEditor
                placeholder="Detailed Description"
                value={basicInfo?.description}
                onChange={(obj) => setBasicInfo({ ...basicInfo, description: obj })}
              />
            </Card>
          </div>
          <span className="card-divider my-4" />
          <h4 className="upload-title-text">Upload {translate(taxonomy)('review')} Documents</h4>
          <div className="information-header mb-4">
            {uploadedFiles.length > 0 && <p>{uploadedFiles.length} files uploaded</p>}
            <Button
              variant="outline"
              className="dark"
              onClick={handleShow}
            >
              Upload File
            </Button>
          </div>
          {uploadedFiles?.length > 0 &&
            uploadedFiles.map((file, index) => {
              return (
                <div key={index}>
                  <AddResourseInfoBlock
                    className="mb-4"
                    type={file.url.substr(file.url.lastIndexOf('.') + 1)}
                    cirleImage
                    name={file.title}
                  >
                    <Button
                      variant="information-white-bg"
                      className="btn information-white-bg btn-sm btn-round"
                      onClick={() => {
                        setResourceToRemove(null);
                        setFileToRemove(index + 1);
                        setRemoveUploadedFile(true);
                      }}
                    >
                      {removeIcon}
                      Remove
                    </Button>
                  </AddResourseInfoBlock>
                </div>
              );
            })}

          {/* <FloatingLabel className="mb-4" controlId="activititySupervisor" label="Supervisor">
            <Form.Control type="number" placeholder="Supervisor" />
          </FloatingLabel> */}
        </Offcanvas.Body>
        <Offcanvas.Footer className="gap-1">
          <div className="d-flex flex-wrap justify-content-end gap-3">
            <Button
              variant="default"
              onClick={handleClose}
              className="p-0"
            >
              Cancel
            </Button>
            {/* <Button variant="glitter" onClick={() => handleClose(true)}>
              Reset
            </Button> */}
            <Button
              disabled={load}
              variant="primary"
              onClick={UpdateReview}
            >
              {load ? (
                <>
                  <div className="spinner-align-center">
                    <Spinner
                      animation="border"
                      size="sm"
                    />
                    {` Update ${translate(taxonomy)('review')}`}
                  </div>
                </>
              ) : (
                `Update ${translate(taxonomy)('review')}`
              )}
            </Button>
          </div>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default EditReviewOffCanvas;
