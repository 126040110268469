import { useEffect, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarData } from '../../../reducers/sidebarSlice';
// import Log from 'lib/Log';
import InputSingleLine from './InputSingleLine';
import InputDate from './InputDate';
import { useLocation } from 'react-router-dom';
import ls from 'localstorage-slim';
import { flushExternalExamData, storeExternalExamData } from 'redux/ExternalExamDataSlice';

ls.config.encrypt = true;

const EditExternalExam = ({ selectedComponent, data, isFromProgram = true }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const role = ls.get('role');
  const {
    identifier,
    privileges: { can_edit_event, can_manage_apprentices },
  } = JSON.parse(ls.get('activeRole'));
  const pathRole = pathname.split('/')[1];
  const [isLoaded, setLoaded] = useState(false);

  const isValidLearner = role === 'learner' && pathRole === 'learner' && identifier === 'apprentice' && can_edit_event;
  const isValidForEdit = isValidLearner || (identifier !== 'apprentice' && can_manage_apprentices);

  isFromProgram && dispatch(setSidebarData({ enableActiveWrapper: true }));

  useEffect(() => {
    dispatch(flushExternalExamData());
    if (data?.length > 0) {
      const formattedData = JSON.parse(data);
      dispatch(storeExternalExamData(formattedData));
    } else {
      dispatch(storeExternalExamData(selectedComponent?.custom_fields));
    }
    setLoaded(true);
  }, [data]);

  return selectedComponent?.custom_fields && selectedComponent?.custom_fields?.length > 0 && isLoaded ? (
    <>
      {selectedComponent?.custom_fields.map((item, index) => {
        switch (item.type) {
          case 'Number':
            return (
              <InputSingleLine
                className="py-2"
                data={item}
                index={index}
                type="number"
                isEditable={isValidForEdit}
              />
            );
          case 'Input Text':
            return (
              <InputSingleLine
                className="py-2"
                data={item}
                index={index}
                type="text"
                isEditable={isValidForEdit}
              />
            );
          case 'Date':
            return (
              <InputDate
                className="py-2"
                data={item}
                index={index}
                isEditable={isValidForEdit}
              />
            );
        }
      })}
    </>
  ) : (
    ''
  );
};

export default memo(EditExternalExam);
