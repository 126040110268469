import { useState } from 'react';
import { Col, ProgressBar, ProgressValue, ProgressWrap, Row, SecondaryHeaderSearchField } from 'react-myoneui';
import IntroCard from './IntroCard';
import IntroCardImage from 'assets/images/intro-card-image.png';
import LearnerHeading from './LearnerHeader';
import LearnerThumbnail from './LearnerThumbnail';
import { products, unitBankIcon } from 'constants/icons';

import thumbnail1 from 'assets/images/thumbnail1.png';
import thumbnail2 from 'assets/images/thumbnail2.png';
import thumbnail3 from 'assets/images/thumbnail3.png';
import thumbnail4 from 'assets/images/thumbnail4.png';
import thumbnail5 from 'assets/images/thumbnail5.png';
import thumbnail6 from 'assets/images/thumbnail6.png';

export default function LearnerProgramsList() {
  const [value, setValue] = useState('');
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const onClear = (e) => {
    setValue('');
  };

  const keyDown = (e) => {};

  const learnerThumbnails = [
    {
      imgSrc: thumbnail1,
      timeText: 'Deadline: Jan 20, 2024',
      title: 'Digital Transformation Summit: Revenue Revolution mastery',
      units: 1,
      progress: 50,
    },
    {
      imgSrc: thumbnail2,
      timeText: 'Deadline: Feb 23, 2024',
      title: 'Completed Chapter 1: Introduction to AI',
      units: 2,
      progress: 1,
    },
    {
      imgSrc: thumbnail3,
      timeText: 'Deadline: Mar 26, 2024',
      title: 'Digital Transformation Summit: Revenue Revolution mastery',
      units: 3,
      progress: 100,
    },
    {
      imgSrc: thumbnail4,
      timeText: 'Deadline: April 30, 2024',
      title: 'Attended Webinar: B2B Commerce Breakthrough: Path to Profitability',
      units: 4,
      progress: 85,
    },
    {
      imgSrc: thumbnail5,
      timeText: 'Deadline: May 10, 2024',
      title: 'Digital Transformation Summit: Revenue Revolution mastery',
      units: 5,
      progress: 30,
    },
    {
      imgSrc: thumbnail6,
      timeText: 'Deadline: June 16, 2024',
      title: 'Submitted assignment of Chapter 1: Introduction to AI',
      units: 6,
      progress: 68,
    },
    {
      imgSrc: thumbnail1,
      timeText: 'Deadline: July 18, 2024',
      title: 'Digital Transformation Summit: Revenue Revolution mastery',
      units: 7,
      progress: 90,
    },
    {
      imgSrc: thumbnail5,
      timeText: 'Deadline: Agu 30, 2024',
      title: 'Started course Digital Transformation Summit: Revenue Revolution',
      units: 8,
      progress: 40,
    },
  ];

  return (
    <div className="learner-container">
      <IntroCard
        title="Resume Learning"
        imgSrc={IntroCardImage}
        deadlineText="Deadline: Oct 23, 2024"
        subTitle="UI/UX Masterclass: Enhancing User Experience"
      />
      <LearnerHeading
        svgIcon={products}
        title="My Programs"
        className="program-search-wrap"
      >
        <SecondaryHeaderSearchField
          placeholder="Search Program..."
          value={value}
          onChange={onChange}
          onClear={onClear}
          onKeyDown={keyDown}
        />
      </LearnerHeading>
      <Row className="learner-thumbnail-row">
        {learnerThumbnails.map((item, index) => (
          <Col
            sm={6}
            lg={3}
            xxl={2}
            key={index}
          >
            <LearnerThumbnail
              imgSrc={item.imgSrc}
              timeText={item.timeText}
              title={item.title}
            >
              <ProgressWrap>
                <ProgressBar
                  variant="success"
                  now={item.progress}
                />
                <ProgressValue ProgressPercentage={`${item.progress}%`} />
              </ProgressWrap>
              <div className="badge-wrap">
                <span className="unit-badge">
                  {unitBankIcon} {item.units} Unit
                </span>
              </div>
            </LearnerThumbnail>
          </Col>
        ))}
      </Row>
    </div>
  );
}
