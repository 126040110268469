import React from 'react';
import { useEffect, useState } from 'react';
import Spinner from 'react-myoneui/lib/Spinner';

// Images
import { tagCloseIcon, chervonDownIcon } from '../../constants/icons';

const TagContent = ({ onTags, keywords, list, disabled = false, tagTitle, className = '', placeholder, loadCategory = false, placeholderTitle }) => {
  const [input, setInput] = useState('');
  const [selectedList, setSelectedList] = useState([]);
  const [showList, setShowList] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const [hoverItem, setHoverItem] = useState(0);
  const ref = React.useRef(null);
  const listRef = React.useRef(null);
  const [scrollHeight, setScrollHeight] = useState(0);
  const onChange = (e) => {
    const { value } = e.target;
    searchList(value);
    setInput(value);
    setHoverItem(0);
  };
  const searchList = (txt) => {
    let searchTxt = txt.toLowerCase();
    if (list.length > 0) {
      let clist = [...list];
      clist = clist.map((item) => {
        return { id: item.id, name: item.name, new: false, cname: item.name.toLowerCase() };
      });

      let searchedList = clist.filter((item) => item.cname.indexOf(searchTxt) > -1);
      if (keywords.length > 0) {
        // Filter keywords from clist
        searchedList = searchedList.filter((item) => keywords.filter((word) => word.name === item.name).length === 0);
      }
      setSelectedList(searchedList);
    }
  };
  const addKeyword = (item) => {
    if (!keywords.find((obj) => obj.name === item.name)) {
      let filterList = selectedList.filter((obj) => obj.name !== item.name);
      setSelectedList(filterList);

      if (filterList.length <= hoverItem) {
        setHoverItem(filterList.length - 1);
      }
      onTags([...keywords, item]);
      setInput('');
    }
  };
  useEffect(() => {
    if (list?.length > 0) {
      let clist = [...list];
      clist = clist.map((item) => {
        return { id: item.id, name: item.name, cname: item.name.toLowerCase() };
      });

      if (keywords?.length > 0) {
        // Filter keywords from clist
        clist = clist.filter((item) => keywords.filter((word) => word.name === item.name).length === 0);
      }

      setSelectedList(clist);
    }
  }, [list, keywords]);
  const displayListHandler = () => {
    setShowList(!showList);
  };
  const onKeyUp = (e) => {
    const { key } = e;
    var trimmedInput = e.target.value;
    let allList = [];
    if (keywords.length > 0) {
      keywords.forEach((element) => {
        allList.push(element);
      });
    }
    if (list.length > 0) {
      list.forEach((element) => {
        allList.push(element);
      });
    }
    allList = allList.map((item) => {
      return item.name.toLowerCase();
    });

    let keywordFilter = trimmedInput.slice(0, -1).toLowerCase();
    if (key === ',' && trimmedInput.length > 0 && !allList.includes(keywordFilter)) {
      if (onTags) {
        onTags([...keywords, { id: new Date().getTime(), new: true, name: keywordFilter }]);
        setInput('');
      }
    } else {
      setInput(trimmedInput);
    }
    if (showList && hoverItem < selectedList.length && hoverItem >= 0) {
      var selecthover = hoverItem;
      var perHight = 40;
      var sheight = scrollHeight;
      if (key === 'ArrowDown') {
        selecthover = hoverItem + 1;
        if (selecthover < selectedList.length) {
          setHoverItem(selecthover);
          sheight += perHight;
          setScrollHeight(sheight);
          listRef.current.scrollTo(0, sheight);
        }
      }
      if (key === 'ArrowUp') {
        selecthover = hoverItem - 1;
        if (selecthover >= 0) {
          setHoverItem(selecthover);
          sheight -= perHight;
          setScrollHeight(sheight);
          listRef.current.scrollTo(0, sheight);
        }
      }
      if (key === 'Enter') {
        addKeyword(selectedList[hoverItem]);
      }
    }
  };

  const deleteTag = (id) => {
    onTags((prevState) => prevState.filter((tag) => tag.id !== id));
  };

  const focusHandler = () => {
    if (!disabled) {
      setShowList(true);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowList(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <div
        className={`tag-content ${className || ''} ${disabled ? 'disabled' : ''}`}
        ref={ref}
      >
        {loadCategory && (
          <Spinner
            animation="border"
            size="sm"
          />
        )}
        <h4 className="">{tagTitle}</h4>
        {showList && selectedList && selectedList.length > 0 && !disabled && (
          <div
            onClick={displayListHandler}
            className={`${showList ? 'up' : 'down'} arrowBtn`}
          >
            {chervonDownIcon}
          </div>
        )}

        <div
          className="input-tag-wrap "
          title={placeholderTitle}
        >
          {keywords &&
            keywords.length > 0 &&
            keywords.map((tag, index) => {
              return (
                <div
                  key={tag?.id}
                  className="tag-badge "
                >
                  <span className="">{tag?.name}</span>
                  <a
                    className="tag-close-icon "
                    onClick={() => deleteTag(tag?.id)}
                  >
                    {tagCloseIcon}
                  </a>
                </div>
              );
            })}

          <div className="tag-control-wrap ">
            {!disabled && (
              <input
                className="form-control "
                type="text"
                placeholder={placeholder}
                value={input}
                onKeyUp={onKeyUp}
                onFocus={focusHandler}
                onChange={onChange}
              />
            )}
            {showList && selectedList && selectedList.length > 0 && !disabled && (
              <ul
                className="category-list"
                ref={listRef}
              >
                {selectedList.map((item, index) => (
                  <li
                    onClick={() => addKeyword(item)}
                    key={item.id}
                    className={hoverItem === index ? 'active' : ''}
                  >
                    {item?.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TagContent;
