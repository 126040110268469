import React from 'react';
import { useState, forwardRef, Children, useEffect } from 'react';
import Dropdown from 'react-myoneui/lib/Dropdown';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    role="button"
    tabIndex={0}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = forwardRef(({ children, style, className, value, 'aria-labelledby': labeledBy }, ref) => {
  // const [value, setValue] = useState('');

  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().includes(value))}</ul>
    </div>
  );
});

const sampleItems = [];

function DropdownCommon({
  label,
  className,
  items = sampleItems,
  defaultValue = 'hgfdsdfghj',
  name = '',
  title = '',
  onChange = () => {},
  onSearch = () => {},
  onKeyUp = () => {},
  isLoading,
  searchText,
  onLoadMore,
  hasMore,
  showMoreBtn,
}) {
  const [menuValue, setMenuValue] = useState('');
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [scrollHeight, setScrollHeight] = useState(0);
  const listRef = React.useRef(null);
  function handleKeyDown(e) {
    var perHight = 42;
    var sheight = scrollHeight;

    if (e.keyCode === 38) {
      // Up arrow key
      e.preventDefault();
      const index = items.indexOf(selected);
      if (index > 0) {
        setSelected(items[index - 1]);
        if (index > 3) {
          sheight -= perHight;
          setScrollHeight(sheight);
          listRef.current.scrollTo(0, sheight);
        }
      }
    } else if (e.keyCode === 40) {
      // Down arrow key
      e.preventDefault();
      const index = items.indexOf(selected);
      if (index < items.length - 1) {
        setSelected(items[index + 1]);
        if (index > 3) {
          sheight += perHight;
          setScrollHeight(sheight);
          listRef.current.scrollTo(0, sheight);
        }
      }
    } else if (e.keyCode === 13) {
      if (typeof selected === 'string' || selected instanceof String) {
        const item = items.filter((item) => item.name.toLowerCase() === selected.toLocaleLowerCase())[0];
        onChange(item);
        setShow(false);
      } else {
        onChange(selected);
        setShow(false);
      }
    }
  }

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, []);

  const onSelect = (index) => {
    setSelected(items[index]);
    onChange(items[index]);
  };
  const onToggle = (show, meta) => {
    setShow(show);
    if (!show) {
      setMenuValue('');
    }
  };
  const onInputChange = (e) => {
    const val = e.target.value ? e.target.value.trim().toLowerCase() : '';
    setSelected(val);
    setMenuValue(val);
    onSearch(val);
    setShow(true);
  };
  const onInputKeyChange = (e) => {
    onInputChange(e);
    onKeyUp(e);
  };

  return (
    <Dropdown
      className={`dropdown-select custom-select-dropdown ${className || ''}`}
      onSelect={onSelect}
      show={show}
      onToggle={onToggle}
      onKeyDown={handleKeyDown}
    >
      <Dropdown.Toggle
        as={CustomToggle}
        id="dropdown-custom-components"
      >
        <FloatingLabel
          controlId="floatingSelection"
          label={label ? label : 'Choose Option'}
          className="w-100"
        >
          <Form.Control
            autoComplete="off"
            type="text"
            placeholder="Events"
            name={name || title}
            onChange={onInputChange}
            // onKeyUp={onInputKeyChange}
            // onKeyDown={(e) => handleKeyDown(e)}
            value={selected.name || selected.title}
          />
          <div className="caret-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6.119"
              height="11.658"
              viewBox="0 0 6.119 11.658"
            >
              <path
                id="Path_21616"
                data-name="Path 21616"
                d="M0,0,4.42,3.71,8.84,0"
                transform="translate(1.409 10.249) rotate(-90)"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </div>
        </FloatingLabel>
      </Dropdown.Toggle>
      <Dropdown.Menu
        value={!searchText ? menuValue : ''}
        as={CustomMenu}
        ref={listRef}
      >
        {isLoading && (
          <Dropdown.Item
            className="text-center"
            disabled
          >
            Loading...
          </Dropdown.Item>
        )}
        {items.length
          ? items.map((item, i) => (
              <Dropdown.Item
                key={i}
                eventKey={i}
                active={selected === item}
              >
                {item.name || item.title}
              </Dropdown.Item>
            ))
          : !isLoading &&
            items.length === 0 && (
              <Dropdown.Item
                className="text-center"
                disabled
              >
                No result found...
              </Dropdown.Item>
            )}
        {showMoreBtn && items.length != 0 && (
          <Dropdown.Item
            disabled={!hasMore}
            className="text-center"
            onClick={(e) => {
              e.stopPropagation();
              onLoadMore();
            }}
          >
            {isLoading ? 'Loading ...' : 'Load More ...'}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownCommon;
