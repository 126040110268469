import { useState, memo } from 'react';
import { Form, Col } from 'react-myoneui';
import DateTimePicker from 'react-datetime-picker';
import { closeIcon } from 'constants/icons';
import { useSelector, useDispatch } from 'react-redux';
import { updateValue } from 'redux/ExternalExamDataSlice';
function InputDate({ data, mode, index, isEditable }) {
  const [activeClass, setActiveClass] = useState('');
  const dispatch = useDispatch();

  const state = useSelector((state) => state?.externalExamData?.data?.find((item) => item?._id === data?._id));

  const handleChange = (e) => {
    dispatch(updateValue({ id: data?._id, value: e }));
  };
  return (
    <>
      <Col
        className="py-2"
        // md={state?.col_width}
        name="formElement"
      >
        <Form.Label
          htmlFor={data?.label}
          className="form-builder-label"
        >
          {index + 1}. {data?.label}
        </Form.Label>
      </Col>
      <DateTimePicker
        className={`block ${activeClass} ${!isEditable || mode === 'preview' || mode === 'readonly' ? 'disabled' : ''} `}
        clearIcon={closeIcon}
        // onClear={handleChange}
        placeholder="Date"
        value={state?.value}
        onChange={handleChange}
        onBlur={() => {
          setActiveClass('');
        }}
        onFocus={() => {
          setActiveClass('target-date-focus');
        }}
        locale="en-US"
      />
      <p className="help-text ms-2">{data?.helper_text}</p>
    </>
  );
}
export default memo(InputDate);
