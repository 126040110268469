import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { lazyWithRetry } from '../../components/lazyWithRetry';
import UnitBankRoute from './unitbank';
import AdminProgramRoute from './program';
import FormBuilderRoute from './formbuilder';
import AdminUsersRoute from './users';
import AdminWorkFlowRoute from './workflow';
import JourneyWorkerRateRoute from './journeyWorkerRate';
import mcqBank from './quizBank';
import externalExam from './ExternalExam';
import event from './Event';
import CommonRoute from '../common';

const NoMatch = lazyWithRetry(() => import('modules/NoMatch'));
const AdminLayout = lazyWithRetry(() => import('../../layouts/AdminLayout'));
const Profile = lazyWithRetry(() => import('../../modules/admin/profile/Profile'));
const AddResourses = lazyWithRetry(() => import('../../modules/admin/program/library/components/Resources/AddResourses'));
const FormComponent = lazyWithRetry(() => import('../../modules/admin/program/library/components/FormComponent'));
const Messages = lazyWithRetry(() => import('../../modules/admin/messages'));
const Taxonomy = lazyWithRetry(() => import('../../modules/superAdmin/taxonomy'));
const DashboardCourse = lazyWithRetry(() => import('../../modules/admin/dashboard/DashboardCourse'));
const AddingNewForm = lazyWithRetry(() => import('../../modules/admin/formBuilder'));
const Learner = lazyWithRetry(() => import('../../modules/superAdmin/learner'));
const RolesPrivilege = lazyWithRetry(() => import('../../modules/admin/rolesPrivilege'));
const PortfolioEvidence = lazyWithRetry(() => import('../../modules/admin/PortfolioEvidence/index'));
const EditPortfolioEvidenceComponent = lazyWithRetry(() => import('../../modules/admin/PortfolioEvidence/EditPortfolioEvidenceComponent'));
const UserManagement = lazyWithRetry(() => import('../../modules/admin/users'));
const ProfileActivity = lazyWithRetry(() => import('../../modules/admin/profile'));
const Masterclass = lazyWithRetry(() => import('../../modules/admin/masterClass/index'));
const WageSchedule = lazyWithRetry(() => import('modules/admin/wage'));

/* Super Admin */
const Reports = lazyWithRetry(() => import('../../modules/superAdmin/reports'));
const Dashboard = lazyWithRetry(() => import('../../modules/admin/dashboard'));
const Security = lazyWithRetry(() => import('../../modules/superAdmin/security'));
const Organization = lazyWithRetry(() => import('../../modules/superAdmin/organization'));

const Notification = lazyWithRetry(() => import('components/notification'));
const Review = lazyWithRetry(() => import('modules/iqv/assessors/Review'));

export default (
  <Routes>
    <Route
      path="admin/"
      element={<AdminLayout />}
    >
      <Route
        index
        element={<Dashboard />}
      />
      <Route
        path="dashboard/"
        element={<Dashboard />}
      />
      <Route
        path="dashboard/course"
        element={<DashboardCourse />}
      />
      <Route
        path="notification"
        element={<Notification />}
      />
      {CommonRoute}
      <Route
        path="reports/*"
        element={<Reports />}
      />
      <Route
        path="security/*"
        element={<Security />}
      />
      <Route
        path="organization/*"
        element={<Organization />}
      />
      <Route
        path="organization/learner/*"
        element={<Learner />}
      />
      <Route
        path="roles-and-privilege/*"
        element={<RolesPrivilege />}
      />
      <Route
        path="form/"
        element={<FormComponent />}
      />
      <Route
        path="portfolio-evidence/"
        element={<PortfolioEvidence />}
      />
      <Route
        path="portfolio-evidence/edit/:id"
        element={<EditPortfolioEvidenceComponent />}
      />
      {UnitBankRoute}
      {FormBuilderRoute}
      {JourneyWorkerRateRoute}
      {mcqBank}
      {externalExam}
      {event}
      <Route
        path="wage/:memberProgramId"
        element={<WageSchedule />}
      />

      {/* TODO: inprogress for section bank */}
      {AdminProgramRoute}
      {AdminUsersRoute}
      <Route
        path="taxonomy/*"
        element={<Taxonomy />}
      />
      <Route
        path="profile/edit-profile/:id"
        element={<Profile />}
      />
      <Route
        path="add-resourses"
        element={<AddResourses />}
      />
      <Route
        path="messages"
        element={<Messages />}
      />
      <Route
        path="orgusers"
        exact
        element={<UserManagement />}
      />
      <Route
        path="orgusers/view-profile/:id"
        exact
        element={<ProfileActivity />}
      />
      <Route
        path="view-profile/:id"
        element={<ProfileActivity />}
      />
      <Route
        path="adding-new-form/"
        element={<AddingNewForm />}
      />
      <Route
        path="courses/*"
        element={<Masterclass />}
      />

      {AdminWorkFlowRoute}
      <Route
        path="*"
        element={<NoMatch />}
      />
    </Route>
    <Route
      path="users/assessors/report/:id/review/"
      element={<Review />}
    />
  </Routes>
);
