import { memo, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveEle, setElements, setMode, updateElement } from '../../../redux/FormBuilderSlice';
import { Form, FloatingLabel, Button, Spinner, Card, ListGroup, Row, Col } from 'react-myoneui';
import { editFormField } from 'api/formBuilder';
import DropdownCheckboxMultiSelect from 'components/DropdownCheckboxMultiSelect';
import { fileUploader } from 'api';
import Log from 'lib/Log';
import ls from 'localstorage-slim';
ls.config.encrypt = true;

function EditImageCommentBlock({ data = {}, onSave, rolesList, displayRoles }) {
  const [state, setState] = useState({});
  const dispatch = useDispatch(useDispatch);
  const elements = useSelector((state) => state.formbuilder.elements);
  const activeEle = useSelector((state) => state.formbuilder.activeEle);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadloading, setUploadloading] = useState(false);
  const imageInput = useRef(null);
  const orgId = ls.get('orgId');
  const defaultImg =
    'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22252%22%20height%3D%22130%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20252%20130%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_18171259392%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3Avar(--bs-font-sans-serif)%2C%20monospace%3Bfont-size%3A13pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_18171259392%22%3E%3Crect%20width%3D%22252%22%20height%3D%22130%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2290.1640625%22%20y%3D%2271.3%22%3E252x130%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E';

  const editHandler = (e) => {
    let cloneState = JSON.parse(JSON.stringify(state));
    cloneState[e.target.name] = e.target.value;
    setState(cloneState);
  };

  const onPeriodItemsChange = (item) => {
    try {
      setRoles((roles) => {
        const prevItems = [...roles];
        const index = prevItems.indexOf(item);
        if (index === -1) {
          return [...prevItems, item];
        } else {
          prevItems.splice(index, 1);
          return prevItems;
        }
      });
    } catch (err) {
      Log.error(err);
    }
  };

  const uploadHandler = async () => {
    imageInput.current.click();
  };
  const uploadImage = async (e) => {
    try {
      setUploadloading(true);
      let result = await fileUploader(e.target.files[0]);
      if (result) {
        let cloneState = JSON.parse(JSON.stringify(state));
        cloneState[e.target.name] = result?.data?.data[0]?.fileToSend?.Location;
        setState(cloneState);
      }
      setUploadloading(false);
    } catch (err) {
      Log.info('Error : ProgramDetails/uploadBannerImage ', err);
    }
  };

  const saveHander = async () => {
    let cloneElements = [...elements];
    let element = cloneElements.find((item) => item.id === state.id);
    let index = cloneElements.indexOf(element);
    cloneElements[index] = { ...state };
    setLoading('loading');
    await editFormField({ ...state });
    dispatch(setActiveEle({ ...state }));
    // dispatch(updateElement({ ...state }));
    // dispatch(setElements(cloneElements));
    dispatch(setMode('view'));
  };

  useEffect(() => {
    if (roles.length > 0) {
      setState({ ...state, editable_by: rolesList.filter((item) => roles.includes(item.name)).map((item) => item.id) });
    }
  }, [roles]);

  useEffect(() => {
    setState(activeEle);
    setRoles(rolesList.filter((item) => activeEle?.editable_by.includes(item.id)).map((item) => item.name));
  }, [activeEle, rolesList]);

  return (
    <div className="form-builder-edit-option">
      <FloatingLabel
        controlId="labelId"
        label="Label *"
        className="mb-28"
      >
        <Form.Control
          type="text"
          value={state.label}
          onChange={editHandler}
          name="label"
        />
      </FloatingLabel>
      <FloatingLabel
        controlId="labelId"
        label="Description Placeholder"
        className="mb-28"
      >
        <Form.Control
          type="text"
          value={state.placeholder}
          onChange={editHandler}
          name="placeholder"
        />
      </FloatingLabel>
      {/* image block */}

      <Card className="border-0 mb-4">
        <Card.Body>
          <Card.Title as="h5">Upload Image</Card.Title>
          <Row className="align-items-center">
            <Col sm={12}>
              <Card.Img
                src={state?.profile_img_url ? state?.profile_img_url : defaultImg}
                className="rounded w-100 course-image"
                alt="Course Image"
              />
            </Col>
          </Row>
        </Card.Body>
        <span className="card-divider my-0 mx-3" />
        <Card.Footer className="d-flex justify-content-center align-items-center">
          <Button
            onClick={uploadHandler}
            variant="outline"
            className="border border-primary text-primary fs-6"
          >
            {uploadloading ? (
              <>
                <div className="spinner-align-center">
                  <Spinner
                    animation="border"
                    size="sm"
                  />
                  Upload Image
                </div>
              </>
            ) : (
              'Upload Image'
            )}
          </Button>
          <input
            type="file"
            accept="image/*"
            className="d-none"
            ref={imageInput}
            name="profile_img_url"
            onChange={(e) => uploadImage(e)}
          />
        </Card.Footer>
      </Card>

      <DropdownCheckboxMultiSelect
        className="mb-4 user-max-content-dropdown"
        label="Editable By"
        name="editable_by"
        autoComplete="off"
        items={displayRoles}
        selectedItems={roles}
        onChange={onPeriodItemsChange}
      />
      <div className="button-wrap">
        <Button
          variant="secondary"
          onClick={saveHander}
          disabled={roles?.length < 1}
        >
          {loading === 'loading' && (
            <Spinner
              animation="border"
              size="sm"
            />
          )}
          Save
        </Button>
      </div>
    </div>
  );
}
export default memo(EditImageCommentBlock);
