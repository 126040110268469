import { Row, Col } from 'react-myoneui';
import ProfileMember from './activity/ProfileMember';
import AssignedProgram from './activity/AssignedProgram';
import AboutInfoBlock from './activity/AboutInfoBlock';

import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getUser } from '../../../api/members';
import { Spinner } from 'react-myoneui';
import { getLearnerPrograms } from '../../../api/learner/program';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarData } from '../../../reducers/sidebarSlice';

import ProfileFilterSidebar from './ProfileFilterSidebar';
import HorizontalNavLinkList from '../../learner/components/HorizontalNavLinkList';
import { getTutorLearnerPrograms } from '../../../api/tutor/learner';
import { getAssessorLearnerPrograms, getIQVLearnerPrograms } from '../../../api';
import ls from 'localstorage-slim';
import { decryptData } from '../../../lib/common';
import Log from '../../../lib/Log';
import DataNotFound from 'components/DataNotFound';
import { getEQVLearnerPrograms } from 'api/eqv';
// enable global encryption
ls.config.encrypt = true;
function ProfileActivity({ name = '' }) {
  const [user, setUser] = useState({});
  const [assignedPrograms, setAssignedPrograms] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id, programId, tutorId, userTab } = useParams();
  const [notFound, setNotFound] = useState(false);
  const userId = ls.get('userid');
  const role = ls.get('role');
  const { pathname } = useLocation();
  const activeRole = JSON.parse(ls.get('activeRole'));
  const { can_view_only_assigned_program } = activeRole?.privileges;
  const pathSplit = pathname.split('/');
  const isTutor = role === 'tutor' && pathSplit[1] === 'tutor' && activeRole.identifier === 'tutor';
  const isAssessor = role === 'assessor' && pathSplit[1] === 'assessor' && activeRole.identifier === 'assessor';
  const isAdmin = role === 'admin' && pathSplit[1] === 'admin' && activeRole.identifier === 'admin';
  const isLearner = role === 'learner' && pathSplit[1] === 'learner' && activeRole.identifier === 'apprentice';
  const isIQV = role === 'iqv' && pathSplit[1] === 'iqv' && activeRole.identifier === 'iqv';
  const isEQV = role === 'eqv' && pathSplit[1] === 'eqv' && activeRole.identifier === 'eqv';

  const isAdminViewTutor = isAdmin && pathSplit[3] === 'tutors';
  const roles = JSON.parse(ls.get('roles'));
  const tutorRoleId = roles.filter((role) => role.identifier === 'tutor')[0]?.id;
  const assessorRoleId = roles.filter((role) => role.identifier === 'assessor')[0]?.id;
  const assignedRoles = roles.map((role) => role.identifier);
  const displayMemberPrograms = userId !== decryptData(id) || (userId === decryptData(id) && assignedRoles.includes('apprentice'));
  let idStatus = userId === decryptData(id); //check if the user is viewing own profile

  useEffect(() => {
    fetchRequiredData();
  }, []);

  // const userorApprentice = window.location.href.split('/')[5];
  const userorApprentice = pathSplit[pathSplit.indexOf('view-profile') - 1];

  const noMemberProgramOf = ['orgusers'];
  const fetchAssignedPrograms = displayMemberPrograms && !noMemberProgramOf.includes(userorApprentice) && !isAdminViewTutor && !(tutorId && programId);

  const fetchRequiredData = () => {
    setLoading(true);
    let assignedPrograms;
    let assignedDelegates;
    let profileId;

    if (role === 'learner') {
      profileId = decryptData(id);
    } else if (id) {
      profileId = decryptData(id);
    } else if (tutorId) {
      profileId = tutorId;
    }

    const userDetail = new Promise((resolve, reject) => resolve(getUser(profileId)));

    if (fetchAssignedPrograms) {
      if (isAssessor) {
        assignedPrograms = new Promise((resolve, reject) => resolve(getAssessorLearnerPrograms({ assessor: userId, role: assessorRoleId, apprentice: decryptData(id) })));
      } else if (isTutor && can_view_only_assigned_program) {
        assignedPrograms = new Promise((resolve, reject) => resolve(getTutorLearnerPrograms({ member: userId, role: tutorRoleId, apprentice: decryptData(id) })));
      } else if (isIQV) {
        assignedPrograms = new Promise((resolve, reject) => resolve(getIQVLearnerPrograms({ memberId: decryptData(id) })));
      } else if (isEQV) {
        assignedPrograms = new Promise((resolve, reject) => resolve(getEQVLearnerPrograms({ memberId: decryptData(id) })));
      } else {
        assignedPrograms = new Promise((resolve, reject) => resolve(getLearnerPrograms({ memberId: decryptData(id) })));
      }
    }
    const promises = tutorId && programId ? [userDetail, assignedDelegates] : fetchAssignedPrograms ? [userDetail, assignedPrograms] : [userDetail];
    setAssignedPrograms([]);
    Promise.allSettled(promises)
      .then((results) => {
        const rejected = results.map((result) => result.status).includes('rejected');
        setNotFound(rejected);
        if (rejected) {
          throw new Error("member doesn't exist");
        }
        results.forEach((result) => {
          if (result.value.data.member) {
            let member = { ...result.value.data.member };
            let hasTutor = member.roles.find((role) => role.identifier === 'tutor');
            setUser(result.value.data.member);
          } else if (userDetail) {
            const memberPrograms = result?.value.data.data.memberPrograms || result.value.data.data.programs;
            const programs =
              memberPrograms &&
              memberPrograms.map((data) => {
                return {
                  ...data.program,
                  memberProgramId: data.id,
                  enrolledAt: data.createdAt,
                  obtained_score: data.score,
                  started_at: data.started_at,
                  last_assessed_at: data.last_assessed_at,
                  completed_progress: data.completed_progress,
                  submitted_progress: data.submitted_progress,
                  certificate_url: data?.certificate_url,
                  status: data?.status,
                  refecth: fetchRequiredData,
                };
              });

            setAssignedPrograms(programs);
          }

          setLoading(false);
        });
      })
      .catch((err) => {
        // Log.error(err);
        setLoading(false);
      });
  };

  // Sidebar Trigger Components Start

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSidebarData({ enableActiveWrapper: true }));

    return () => {
      dispatch(setSidebarData({ enableActiveWrapper: false }));
    };
  }, []);

  const enableActiveWrapper = useSelector((state) => state.sidebar.enableActiveWrapper);

  useEffect(() => {
    if (!enableActiveWrapper) {
      setTimeout(() => {
        dispatch(setSidebarData({ enableActiveWrapper: true }));
      }, 100);
    }
  }, [enableActiveWrapper]);

  // Sidebar Trigger Components End

  return (
    <>
      {!notFound && <ProfileFilterSidebar userorApprentice={userorApprentice} />}
      <div className={`${tutorId && programId ? '' : 'content-wrapper'} ${isLearner ? 'p-0' : ''}`}>
        {notFound ? (
          <DataNotFound />
        ) : (
          <>
            {loading ? (
              <div className="spinner-middle">
                <Spinner animation="border" />
              </div>
            ) : (
              <div>
                {isLearner && <HorizontalNavLinkList />}
                <div className="content-block bg-transparent">
                  <Row className="justify-content-center">
                    <Col xl={13}>
                      <ProfileMember
                        className="mb-4"
                        user={user}
                        idStatus={idStatus}
                      />

                      {(userTab === 'apprentices' || name === 'apprentices') && (
                        <AssignedProgram
                          programs={assignedPrograms}
                          user={user}
                          onReload={fetchRequiredData}
                          loading={loading}
                        />
                      )}
                      {user && assignedPrograms && (
                        <AboutInfoBlock
                          user={user}
                          assignedPrograms={assignedPrograms}
                          idStatus={idStatus}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ProfileActivity;
