import { useState, useEffect } from 'react';
import { Row, Col } from 'react-myoneui';
import SecondaryHeader from 'react-myoneui/lib/SecondaryHeader';
import Badge from 'react-myoneui/lib/Badge';
import { Card } from 'react-myoneui';
import Button from 'react-myoneui/lib/Button';
import { translate } from '../../../../lib/';
import { getTaxonomy, getTokenForDMS } from '../../../../api/';
import LearnerRolesFilterSidebar from '../../../admin/roles/LearnerRolesFilterSidebar';
import { useDispatch, useSelector } from 'react-redux';
import { storeSections, changeActiveFirst, changeActiveSecond, changeActiveThird, setSelectedProgramComponent, setActiveColumn, setTaxonomy } from '../../../../redux';
import { addIcon } from '../../../../constants/icons';
import ProgramList from '../../../../components/learners/ProgramList';
import PreviewLectureDetails from './PreviewLectureDetails';
import PreviewAddTarget from './PreviewAddTarget';
import { MessageBox } from '../../../../components/MessageBox';
import PreviewObservationOffCanvas from './previewObservationOffCanvas';
import { Toast, ToastInfo, ToastContainer } from 'react-myoneui';
import ls from 'localstorage-slim';
import Log from '../../../../lib/Log';
import { getPlainTextFromHtmlText } from 'lib/common';
import DescriptionSeeMore from 'components/DescriptionSeeMore';

// enable global encryption
ls.config.encrypt = true;
function PreviewLectureList({ programDetail, programName, weightageStatus, programInfo, from }) {
  const sections = useSelector((state) => state?.sections?.sections);
  const column = useSelector((state) => state.sections.active_column);
  const [subPage, setSubPage] = useState('leactureList');
  const [displayMsg, setDisplayMsg] = useState(false);

  const active_first = useSelector((state) => state.sections.active_first);
  const active_second = useSelector((state) => state.sections.active_second);
  const [addTargetShow, setAddTargetShow] = useState(false);
  const [sectionTitle, setSectionTitle] = useState('');
  const [addProgramTargetShow, setAddProgramTargetShow] = useState(false);

  const taxonomy = useSelector((state) => state?.taxonomy?.data);
  const dispatch = useDispatch();

  const active_Role = JSON.parse(ls.get('activeRole'));
  const { can_create_smart_target } = active_Role?.privileges;

  const [lectureObservationShow, setLectureObservationShow] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [showToast, setShowToast] = useState(programInfo?.notes ? true : false);

  const toggleToast = () => setShowToast(!showToast);

  const fetchDMSToken = async () => {
    try {
      let result = await getTokenForDMS(ls.get('usercode'));

      if (result) {
        ls.set('dmstoken', result?.data?.data?.token);
      }
    } catch (error) {
      Log.error(error);
    }
  };

  useEffect(() => {
    setTaxonomyData();
    if (!ls.get('dmstoken')) {
      fetchDMSToken();
    }
    Log.info('programInto', programInfo);
  }, []);

  const setTaxonomyData = async () => {
    try {
      let result = await getTaxonomy();
      dispatch(setTaxonomy({ ...taxonomy, ...result.data }));
    } catch (error) {
      Log.error(error);
    }
  };

  useEffect(() => {
    storeProgramDetails();
  }, [programDetail]);

  const storeProgramDetails = async () => {
    try {
      dispatch(storeSections(programDetail));
      dispatch(changeActiveFirst(0));
      if (programDetail[0]?.child_sections.length > 0) {
        dispatch(changeActiveSecond(0));
      }
    } catch (error) {
      Log.error(error);
    }
  };

  const calculateSecondLevelSection = (data) => {
    let subSection = 0;
    let component = 0;
    data.forEach((element) => {
      element.component ? component++ : subSection++;
    });
    if (subSection > 0 && component > 0) {
      return `${subSection} ${subSection > 1 ? translate(taxonomy)('subSection') + 's' : translate(taxonomy)('subSection')}, ${component} ${
        component > 1 ? translate(taxonomy)('component') + 's' : translate(taxonomy)('component')
      }`;
    } else if (subSection > 0 && component === 0) {
      return `${subSection} ${subSection > 1 ? translate(taxonomy)('subSection') + 's' : translate(taxonomy)('subSection')}`;
    } else if (subSection === 0 && component > 0) {
      return `${component} ${component > 1 ? translate(taxonomy)('component') + 's' : translate(taxonomy)('component')}`;
    } else {
      return '';
    }
  };

  const calculateTotalComponents = (data) => {
    try {
      let count = 0;
      for (let cmp of data) {
        if (cmp.component) {
          count++;
        } else {
          count = cmp.child_sections.length + count;
        }
      }
      return count;
    } catch (error) {
      Log.error(error);
    }
  };

  const loadSubPageHandler = (data) => {
    try {
      if (column === 1) {
        if (sections[active_first]?.member_component) {
          dispatch(changeActiveFirst(0));
          dispatch(changeActiveSecond(0));
        }
      }
      if (column === 2) {
        if (sections[active_first]?.child_sections[active_second].member_component) {
          dispatch(changeActiveSecond(0));
        }
      }
      if (column === 3) {
        dispatch(setActiveColumn(2));
      }
      setSubPage(data);
    } catch (error) {
      Log.error(error);
    }
  };

  const selectedComponentHandler = (obj) => {
    try {
      dispatch(setSelectedProgramComponent(obj));
    } catch (error) {
      Log.error(error);
    }
  };
  const firstColHandler = (index) => {
    try {
      dispatch(setActiveColumn(1));
      if (!sections[index].component) {
        dispatch(changeActiveFirst(index));
        if (sections[index].child_sections.length > 0) {
          dispatch(changeActiveSecond(0));
        } else {
          dispatch(changeActiveSecond(null));
        }
      } else {
        setSubPage('lectureDetail');
        dispatch(changeActiveFirst(index));

        selectedComponentHandler(sections[index]);
      }
    } catch (error) {
      Log.error(error);
    }
  };

  const setSecondColIndex = (index) => {
    try {
      dispatch(setActiveColumn(2));
      if (!sections[active_first].child_sections[index].component) {
        dispatch(changeActiveSecond(index));
      } else {
        setSubPage('lectureDetail');

        selectedComponentHandler(sections[active_first].child_sections[index]);
        dispatch(changeActiveSecond(index));
      }
    } catch (error) {
      Log.error(error);
    }
  };

  const thirdColHandler = (index) => {
    try {
      dispatch(setActiveColumn(3));
      dispatch(changeActiveThird(index));
      setSubPage('lectureDetail', sections[active_first].child_sections[active_second].child_sections[index]);
      selectedComponentHandler(sections[active_first].child_sections[active_second].child_sections[index]);
    } catch (error) {
      Log.error(error);
    }
  };
  const columnHandler = (index) => {
    try {
      setSubPage('leactureList');
      switch (column) {
        case 1:
          dispatch(changeActiveFirst(index));
          dispatch(changeActiveSecond(0));
          break;
        case 2:
          dispatch(changeActiveSecond(index));
          break;
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const setFirstColClass = (index) => {
    try {
      if (column === 1 && index === active_first) {
        return 'active target-active';
      } else if (column != 1 && index === active_first) {
        return 'active';
      } else {
        return '';
      }
    } catch (error) {
      Log.error(error);
    }
  };

  const observationHandler = (item) => {
    setSelectedSection(item);
    setLectureObservationShow(true);
  };

  const taxonomyMatch = {
    CourseComponent: 'course',
    UnitComponent: 'assignment',
    ResourceComponent: 'resources',
    OTJComponent: 'otj',
    FormComponent: 'forms',
    ReviewComponent: 'review',
    QuizComponent: 'quizBank',
    ExternalExamComponent: 'externalExam',
    EventComponent: 'event',
  };

  const sectionTypeHandler = (sectionType) => {
    return translate(taxonomy)(taxonomyMatch[sectionType]);
  };

  return (
    <>
      <div>
        {programName && programInfo && (
          <>
            <SecondaryHeader>
              <div className="intro-section p-0 text-ellipsis">{programInfo?.description && <DescriptionSeeMore content={programInfo?.description} />}</div>
              <div className="button-wrap gap-4">
                <Badge className="badge dark">Not Started</Badge>
                {can_create_smart_target && (
                  <Button
                    variant="leading-icon"
                    className="leading-icon add-target-btn m-0"
                    type="button"
                    onClick={() => {
                      setAddProgramTargetShow(true);
                      setAddTargetShow(false);
                    }}
                  >
                    {addIcon} {`Add ${translate(taxonomy)('target')}`}
                  </Button>
                )}
              </div>
            </SecondaryHeader>

            <LearnerRolesFilterSidebar preview={true} />
          </>
        )}

        <div className="content-block bg-transparent p-0 mb-0">
          <ToastContainer className="p-3 preview-toast-wrap">
            <Toast
              variant="primary"
              show={showToast}
              onClose={toggleToast}
            >
              <Toast.Body>
                <ToastInfo toastDescription={getPlainTextFromHtmlText(programInfo?.notes)} />
                <div className="action-wrapper">
                  <Button
                    variant="primary"
                    type="button"
                    size="sm"
                    className="btn-round bg-primary-fade"
                    onClick={toggleToast}
                    aria-label="Close"
                  >
                    Close
                  </Button>
                </div>
              </Toast.Body>
            </Toast>
          </ToastContainer>
          <MessageBox
            show={displayMsg}
            onClose={() => setDisplayMsg(false)}
            title="Information"
            description={`Data Can't Be Saved Since this is Preview Of the Selected ${from ?? 'Program'}`}
          />
          <Row>
            {subPage === 'leactureList' && sections.length > 0 && (
              <>
                {/* section */}
                <Col
                  xl={4}
                  lg={6}
                  className="mb-4"
                >
                  <Card className="border-0 h-100">
                    <Card.Body>
                      {sections.map((section, index) => {
                        return (
                          <div key={index}>
                            <ProgramList
                              column={1}
                              activeId={section.id}
                              data={{ section: { ...section } }}
                              programInfo={programInfo}
                              preview={true}
                              className={setFirstColClass(index)}
                              dotCircle={column == 1 && index === active_first}
                              iconName={section.component ? section.section_type : section.parent_section ? 'subSection' : 'Section'}
                              // title={section.component ? translate(taxonomy)('component') + ': ' + section.title : translate(taxonomy)('section') + ': ' + section.title}
                              title={section.title}
                              sub_title={section?.sub_title}
                              // subSection={!section.component ? calculateSecondLevelSection(section.child_sections) : translate(taxonomy)('component')}
                              subSection={
                                section.component
                                  ? section?.sub_title && section?.sub_title.trim() !== ''
                                    ? section?.sub_title
                                    : sectionTypeHandler(section.section_type)
                                  : calculateTotalComponents(section.child_sections) +
                                    ' ' +
                                    (section?.sub_title && section?.sub_title.trim() !== '' ? section?.sub_title : translate(taxonomy)('component') + 's')
                              }
                              component={section.section_type}
                              weightagePercentage={section?.weightage}
                              weightageStatus={weightageStatus}
                              addTargetButton={`Add ${translate(taxonomy)('target')}`}
                              progressValue="Not Started"
                              // moreContent
                              observationButton="Observation"
                              onObservationClick={() => {
                                observationHandler(section);
                              }}
                              onButtonClick={() => {
                                setAddTargetShow(true);
                                setAddProgramTargetShow(false);
                                setSectionTitle(section.title);
                              }}
                              onClick={() => {
                                firstColHandler(index);
                              }}
                              programName={programName}
                              name="unit-bank"
                            />
                            <hr className="program-list-divider" />
                          </div>
                        );
                      })}
                    </Card.Body>
                  </Card>
                </Col>
                {/* sub-section */}
                <Col
                  xl={4}
                  lg={6}
                  className="mb-4"
                >
                  <Card className="border-0 h-100">
                    <Card.Body>
                      {sections[active_first].child_sections.length > 0 &&
                        sections[active_first].child_sections.map((section, index) => {
                          return (
                            <div key={index}>
                              <ProgramList
                                activeId={section.id}
                                preview={true}
                                className={index === active_second ? 'active target-active' : ''}
                                dotCircle={column == 1 && index === active_second}
                                iconName={section.component ? section.section_type : 'subSection'}
                                // title={section.component ? translate(taxonomy)('component') + ': ' + section.title : translate(taxonomy)('subSection') + ': ' + section.title}
                                title={section.title}
                                sub_title={section?.sub_title}
                                // subSection={
                                //   !section.component
                                //     ? section.child_sections.length > 0
                                //       ? section.child_sections.length + ' ' + (section.child_sections.length > 1 ? translate(taxonomy)('component') + 's' : translate(taxonomy)('component'))
                                //       : ''
                                //     : ''
                                // }
                                subSection={
                                  !section.component
                                    ? section.child_sections.length + ' ' + (section?.sub_title && section?.sub_title.trim() !== '' ? section?.sub_title : translate(taxonomy)('component'))
                                    : sectionTypeHandler(section.section_type)
                                }
                                progressValue="Not Started"
                                component={section.section_type}
                                weightagePercentage={section?.weightage}
                                weightageStatus={weightageStatus}
                                addTargetButton={`Add ${translate(taxonomy)('target')}`}
                                // moreContent
                                observationButton="Observation"
                                onObservationClick={() => {
                                  observationHandler(section);
                                }}
                                onButtonClick={() => {
                                  setAddTargetShow(true);
                                  setAddProgramTargetShow(false);
                                  setSectionTitle(section.title);
                                }}
                                onClick={() => setSecondColIndex(index)}
                                programName={programName}
                              />
                              <hr className="program-list-divider" />
                            </div>
                          );
                        })}
                    </Card.Body>
                  </Card>
                </Col>
                {/* component */}
                <Col
                  xl={4}
                  lg={6}
                  className="mb-4"
                >
                  <Card className="border-0 h-100">
                    <Card.Body>
                      {sections[active_first]?.child_sections[active_second]?.child_sections.map((component, index) => {
                        return (
                          <div key={index}>
                            <ProgramList
                              activeId={component.id}
                              preview={true}
                              progressValue="Not Started"
                              onClick={() => thirdColHandler(index)}
                              iconName={component.section_type}
                              // title={translate(taxonomy)('component') + ': ' + component?.title || component?.name}
                              title={component?.title || component?.name}
                              sub_title={component?.sub_title}
                              subSection={sectionTypeHandler(component.section_type)}
                              dotCircle
                              weightagePercentage={component?.weightage}
                              weightageStatus={weightageStatus}
                              addTargetButton="Add Target"
                              programName={programName}
                              component={component.section_type}
                            />
                            <hr className="program-list-divider" />
                          </div>
                        );
                      })}
                    </Card.Body>
                  </Card>
                </Col>
              </>
            )}
            {subPage === 'lectureDetail' && (
              <PreviewLectureDetails
                from={from}
                onLoadColumnData={columnHandler}
                column={column}
                onLoadSubPage={(data) => loadSubPageHandler(data)}
                calculateSecondLevelSection={calculateSecondLevelSection}
                calculateTotalComponents={calculateTotalComponents}
                programInfo={programInfo}
              />
            )}
          </Row>
        </div>
      </div>
      <PreviewObservationOffCanvas
        onChange={(data) => Log.info('onchange:', data)}
        onSaveObservation={() => {
          setLectureObservationShow(false);
          setDisplayMsg(true);
        }}
        data={selectedSection}
        show={lectureObservationShow}
        onClose={() => setLectureObservationShow(false)}
      />
      {addTargetShow && (
        <PreviewAddTarget
          sectionTitle={sectionTitle}
          show={addTargetShow}
          onSave={() => {
            setAddTargetShow(false);
            setDisplayMsg(true);
          }}
          onClose={() => setAddTargetShow(false)}
        />
      )}
      {addProgramTargetShow && (
        <PreviewAddTarget
          sectionTitle={programName}
          show={addProgramTargetShow}
          onSave={() => {
            setAddTargetShow(false);
            setDisplayMsg(true);
          }}
          onClose={() => setAddProgramTargetShow(false)}
        />
      )}
    </>
  );
}

export default PreviewLectureList;
