import { memo } from 'react';
import { Form, Col } from 'react-myoneui';
function InputSingleLine({ data, mode, number, type }) {
  return (
    <>
      <Col
        className="py-2"
        // md={state?.col_width}
        name="formElement"
      >
        <Form.Label
          htmlFor={data?.title}
          className="form-builder-label"
        >
          {number}. {data?.label}
        </Form.Label>
        <Form.Control
          type={type}
          aria-describedby="passwordHelpBlock"
          placeholder={data?.label}
          value={data?.value}
        />
        <p className="help-text ms-2">{data?.helper_text}</p>
      </Col>
    </>
  );
}
export default memo(InputSingleLine);
