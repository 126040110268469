import { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setElements, setMode, updateElement, setActiveEle } from '../../../redux/FormBuilderSlice';
import { Form, FloatingLabel, Button, Spinner } from 'react-myoneui';
import DropdownCollection from '../../../components/DropdownCollection';
import DropdownCheckboxMultiSelect from 'components/DropdownCheckboxMultiSelect';
import { editFormField } from 'api/formBuilder';
import Log from 'lib/Log';
import ls from 'localstorage-slim';
ls.config.encrypt = true;
function EditYesNoQuestionBlock({ data = {}, onSave, rolesList, displayRoles }) {
  const orgId = ls.get('orgId');
  const [state, setState] = useState({});
  const dispatch = useDispatch(useDispatch);
  const [loading, setLoading] = useState(false);
  const fieldWidthOptions = [
    { id: 1, name: 'Full width', value: 12 },
    { id: 2, name: 'Half width', value: 6 },
    { id: 3, name: '1/3 width', value: 4 },
    { id: 4, name: '3/4 width', value: 8 },
  ];
  const elements = useSelector((state) => state.formbuilder.elements);
  const activeEle = useSelector((state) => state.formbuilder.activeEle);
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    setState(activeEle);
    setRoles(rolesList.filter((item) => activeEle?.editable_by.includes(item.id)).map((item) => item.name));
  }, [activeEle, rolesList]);

  const editHandler = (e) => {
    try {
      let cloneState = JSON.parse(JSON.stringify(state));
      cloneState[e.target.name] = e.target.value;
      setState(cloneState);
    } catch (error) {
      Log.error(error);
    }
  };

  const saveHander = async () => {
    try {
      let cloneElements = [...elements];
      let element = cloneElements.find((item) => item.id === state.id);
      let index = cloneElements.indexOf(element);
      cloneElements[index] = { ...state };
      setLoading('loading');
      await editFormField({ ...state });
      dispatch(setActiveEle({ ...state }));
      setLoading(true);
      dispatch(setMode('view'));
    } catch (error) {
      Log.error(error);
      setLoading(true);
    }
  };
  const changeSize = (obj) => {
    setState({ ...state, col_width: obj.value });
  };

  const onPeriodItemsChange = (item) => {
    try {
      setRoles((roles) => {
        const prevItems = [...roles];
        const index = prevItems.indexOf(item);
        if (index === -1) {
          return [...prevItems, item];
        } else {
          prevItems.splice(index, 1);
          return prevItems;
        }
      });
    } catch (err) {
      Log.error(err);
    }
  };

  useEffect(() => {
    if (roles.length > 0) {
      setState({ ...state, editable_by: rolesList.filter((item) => roles.includes(item.name)).map((item) => item.id) });
    }
  }, [roles]);

  return (
    <div className="form-builder-edit-option">
      <FloatingLabel
        controlId="labelId"
        label="Label *"
        className="mb-28"
      >
        <Form.Control
          type="text"
          placeholder="Label *"
          value={state.label}
          onChange={editHandler}
          name="label"
        />
      </FloatingLabel>
      <FloatingLabel
        controlId="labelId"
        label="Description *"
        className="mb-28"
      >
        <Form.Control
          as="textarea"
          placeholder="Description *"
          value={state.help_text}
          onChange={editHandler}
          name="help_text"
        />
      </FloatingLabel>
      <DropdownCheckboxMultiSelect
        className="mb-4 user-max-content-dropdown"
        label="Editable By"
        name="editable_by"
        autoComplete="off"
        items={displayRoles}
        selectedItems={roles}
        onChange={onPeriodItemsChange}
      />
      <div className="button-wrap">
        <Button
          variant="secondary"
          onClick={saveHander}
          disabled={roles?.length < 1}
        >
          {loading === 'loading' && (
            <Spinner
              animation="border"
              size="sm"
            />
          )}
          Save
        </Button>
      </div>
    </div>
  );
}
export default memo(EditYesNoQuestionBlock);
