import { useState, useEffect, memo, useRef } from 'react';
import { Row, Col } from 'react-myoneui';
import SecondaryHeader from 'react-myoneui/lib/SecondaryHeader';
import SecondaryHeaderInfo from 'react-myoneui/lib/SecondaryHeaderInfo';
import Badge from 'react-myoneui/lib/Badge';
import { Card } from 'react-myoneui';
import { ProgressWrap, ProgressValue, ProgressBar } from 'react-myoneui';
import Button from 'react-myoneui/lib/Button';
import HorizontalNavLinkList from '../components/HorizontalNavLinkList';
import LearnerMainSidebar from '../../../components/LearnerMainSidebar';
import ProgramList from '../../../components/learners/ProgramList';
import { enrollSubscription } from 'api/component/course';
import ActivityDetail from './ActivityDetail';
import { decryptData, translate, encryptData } from 'lib/';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { updateProgramScore, calculateMemberProgramProgress } from 'api/learner/program';
import { getParentData } from 'api/learner/program';
import { getTaxonomy, getUser, getTokenForDMS, getAllFeedBacks, getFirstColSection, getMemberSection } from 'api/';
import { updateLastAssedAt, saveObservation, setStatusComplete } from 'api/learner/section';
import { Spinner } from 'react-myoneui';
import LearnerRolesFilterSidebar from '../../admin/roles/LearnerRolesFilterSidebar';
import DelegateFilterSidebar from '../../../components/filterSidebar/DelegateFilterSidebar';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarData } from '../../../reducers/sidebarSlice';
import LectureObservationOffCanvas from './LectureObservationOffCanvas';
import { changeActiveFirst, changeActiveSecond, changeActiveThird, setActiveColumn, setTaxonomy, storeMemberProgram, setFeedbacks, setActiveSection, storeSections } from '../../../redux';
import { viewMemberProgram } from 'api/learner/program';
import { setPercentageVariant } from '../../../lib';
import AddScoreOffCanvas from './AddScoreOffCanvas';
// import AddTargetOffCanvas from './AddTargetOffCanvas';
import AddSectionTargetOffCanvas from './Target/AddSectionTargetOffCanvas';
import AddProgramTargetOffCanvas from './Target/AddProgramTargetOffCanvas';
import { setToastBoxDescription, setToastBoxStatus, setFirstCol, setSelectedProgramComponent } from '../../../redux';
import ToastBox from '../../../components/ToastBox';
import { addIcon, scoreTargetIcon } from '../../../constants/icons';
import { upCommingTargets } from '../../../utils/upCommingTargets';
import ls from 'localstorage-slim';
import CircleImg from '../../../components/CircleImg';
import Log from '../../../lib/Log';
import { getType } from '@reduxjs/toolkit';
import { Toast, ToastInfo, ToastContainer } from 'react-myoneui';
import { getPlainTextFromHtmlText, getUserIconFromRoleIdentifier } from 'lib/common';
import DescriptionSeeMore from 'components/DescriptionSeeMore';
import { iqvVerifyMemberProgram } from 'api/iqv';
import { eqvVerifyMemberProgram } from 'api/eqv';
import { lockCompleteMemberProgram, releaseCertificateToMemberProgram } from 'api/program';
import SelectedComponent from './SelectedComponent';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';

// enable global encryption
ls.config.encrypt = true;
function ComponentDetail({ backBtnStatus = false }) {
  const sections = useSelector((state) => state?.sections?.sections);
  const dispatch = useDispatch();
  const { id, programId, backpage, sectionid } = useParams();
  if (programId) {
    ls.set('activeMemberProgramId', programId);
  }
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [componentLoading, setComponentLoading] = useState(false);
  const [hideToast, setHideToast] = useState(state?.hideToast ? state.hideToast : false);
  const orgId = ls.get('orgId');
  const roles = JSON.parse(ls.get('roles'));
  const column = useSelector((state) => state.sections.active_column);
  const [subPage, setSubPage] = useState('lectureDetail');
  const [firstColLoading, setFirstColLoading] = useState(false);
  const [updateScoreShow, setUpdateScoreShow] = useState(false);
  const active_first = useSelector((state) => state.sections.active_first);
  const active_second = useSelector((state) => state.sections.active_second);
  const active_third = useSelector((state) => state.sections.active_third);
  const [addTargetShow, setAddTargetShow] = useState(false);
  const [addProgramTargetShow, setAddProgramTargetShow] = useState(false);
  const [member, setMember] = useState({});
  const taxonomy = useSelector((state) => state?.taxonomy?.data);
  const active_Role = JSON.parse(ls.get('activeRole'));
  const { can_create_smart_target } = active_Role?.privileges;
  const memberProgram = useSelector((state) => state.memberProgram);
  const [selectedSection, setSelectedSection] = useState(null);
  const [componentId, setComponentId] = useState(null);
  const [lectureObservationShow, setLectureObservationShow] = useState(false);
  const firstCol = useSelector((state) => state.sections.firstCol);
  const [showToast, setShowToast] = useState(false);
  const [iqvVerifyLoading, setIqvVerifyLoading] = useState(false);
  const [certificateLoading, setCertificateLoading] = useState(false);
  const iqvName = roles.filter((role) => role.identifier === 'iqv')[0]?.name;
  const eqvName = roles.filter((role) => role.identifier === 'eqv')[0]?.name;
  const [loading, setLoading] = useState('');
  const showToastRef = useRef(true);
  const [parentId, setParentId] = useState('');

  const toggleToast = () => setShowToast(!showToast);

  const displayToaster = (msg) => {
    try {
      dispatch(setToastBoxStatus(true));
      dispatch(setToastBoxDescription(msg));
    } catch (error) {
      Log.error('Error : LectureList/displayToaster :', error);
    }
  };

  const handleUpdateScore = () => {
    try {
      if (memberProgram.program?.score && memberProgram.program?.score_type === 'manual') {
        setUpdateScoreShow(true);
      }
    } catch (error) {
      Log.error('Error : LectureList/handleUpdateScore', error);
    }
  };
  const fetchDMSToken = async () => {
    try {
      let result = await getTokenForDMS(ls.get('usercode'));
      if (result) {
        ls.set('dmstoken', result?.data?.data?.token);
      }
    } catch (error) {
      Log.error('Error : LectureList/fetchDMSToken :', error);
    }
  };
  // for viewing from admin side
  const learnerListCheck = 'learner-list' === pathname.split('/')[4];
  const role = ls.get('role');
  const pathRole = pathname.split('/')[1];
  const activeRole = JSON.parse(ls.get('activeRole'));
  const isLearner = role === 'learner' && pathRole === 'learner' && activeRole.identifier === 'apprentice';
  const isIQV = role === 'iqv' && pathRole === 'iqv' && activeRole.identifier === 'iqv';
  const isEQV = role === 'eqv' && pathRole === 'eqv' && activeRole.identifier === 'eqv';
  const isAssessor = role === 'assessor' && pathRole === 'assessor' && activeRole.identifier === 'assessor';
  const isTutor = role === 'tutor' && pathRole === 'tutor' && activeRole.identifier === 'tutor';
  const isAdmin = role === 'admin' && pathRole === 'admin' && activeRole.identifier === 'admin';
  const selectedComponent = useSelector((state) => state.sections.active_component);
  const hasCompletedSection = firstCol?.find((section) => section?.status === 'Completed');

  const lockMemberProgram =
    activeRole?.privileges?.can_manage_apprentices && hasCompletedSection && memberProgram.status !== 'Completed' && !memberProgram.is_locked && (isAssessor || isTutor || isAdmin);
  const releaseCertificate =
    (isAdmin || isAssessor || isTutor) &&
    activeRole?.privileges?.can_manage_apprentices &&
    memberProgram?.status === 'Completed' &&
    !memberProgram?.certificate_url &&
    memberProgram?.program?.has_certificate &&
    memberProgram?.program?.release_type === 'manual';
  const enableActiveWrapper = useSelector((state) => state.sidebar.enableActiveWrapper);
  //for admin to know whose user's data is viewng
  const fetchMemberData = async () => {
    try {
      Log.info(id);
      const member = await getUser(decryptData(id));
      setMember(member.data.member);
    } catch (error) {
      Log.error('Error : LectureList/fetchMemberData :', error);
    }
  };
  const fetchSectionDetail = async (id) => {
    // getMemberSection
    try {
      setComponentLoading(true);
      dispatch(setSelectedProgramComponent(null));
      let result = await getMemberSection(id);
      if (result) {
        setComponentLoading(false);
        ls.set('selectedComponent', JSON.stringify(selectedComponent));

        if (role === 'learner') {
          updateLastAssed(JSON.parse(JSON.stringify(result?.data?.data)));
        } else {
          dispatch(setSelectedProgramComponent({ ...result?.data?.data }));
        }
      }
    } catch (err) {
      setComponentLoading(false);
      Log.error(err);
    }
  };
  const updateLastAssed = async (data) => {
    try {
      if (data) {
        const payload = { member_section_id: data?.member_section?.id };
        const result = await updateLastAssedAt({ payload, orgId });

        if (result) {
          data.member_section.last_assessed_at = result?.data?.data;
          dispatch(setSelectedProgramComponent(data));
        }
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const setTaxonomyData = async () => {
    try {
      let result = await getTaxonomy();
      dispatch(setTaxonomy({ ...taxonomy, ...result.data }));
    } catch (error) {
      Log.error('Error : LectureList/setTaxonomyData :', error);
    }
  };
  const fetchAllFeedbacks = async () => {
    try {
      let result = await getAllFeedBacks({ member_program: programId });
      dispatch(setFeedbacks({ ...result.data.data }));
    } catch (error) {
      Log.error('Error : LectureList/fetchAllFeedbacks :', error);
    }
  };

  const calculateProgress = async () => {
    try {
      const payload = {
        orgId,
        memberProgramId: programId,
      };
      await calculateMemberProgramProgress(payload);
    } catch (error) {
      Log.error('Error : LectureList/calculateProgress :', error);
    }
  };

  const loadSubPageHandler = (data) => {
    try {
      setSubPage(data);
      calculateProgress();
      //fetchBackData();
      fetchAllFeedbacks();
    } catch (error) {
      Log.error('Error : LectureList/loadSubPageHandler :', error);
    }
  };

  const firstColHandler = async (index, item) => {
    try {
      //  let fullUrl = window.location.href;
      // console.log('firstColHandler', item);
      let restUrl = pathname.split('lecture-list');
      let encryptId = encryptData(item.id);
      dispatch(setActiveSection(item));

      var obj = { ...item };
      let componentName = obj?.section?.section_type;
      var enrollSubscriptionStatus = false;
      if (componentName === 'CourseComponent') {
        let component = obj?.section?.component;
        let member = obj?.member;
        enrollSubscriptionStatus = true;
        try {
          enrollSubscription({ member, component });
        } catch (error) {
          Log.error('error oklkjlk');
        }
      } else {
        enrollSubscriptionStatus = true;
      }
      //  window.location.href = restUrl[0] + 'lecture-list/' + encryptId;
      if (enrollSubscriptionStatus) {
        switch (column) {
          case 1:
            dispatch(changeActiveFirst(index));
            break;
          case 2:
            dispatch(changeActiveSecond(index));
            break;
          case 3:
            dispatch(changeActiveThird(index));
            break;
        }
        if (item.section.section_type) {
          navigate(restUrl[0] + 'lecture-list/' + encryptId);
          // setComponentId(item.id);
        } else {
          navigate(restUrl[0] + 'lecture-list/', { state: { update: { member_section: item, index }, hideToast } });
        }
      }

      //return result;
    } catch (error) {
      //setSecondColLoading(false);
      Log.error('Error : LectureList/firstColHandler :', error);
    }
  };
  const updateScore = async (score) => {
    try {
      const payload = {
        orgId,
        memberProgramId: programId,
        score,
      };
      await updateProgramScore(payload);
      const memberProgramClone = JSON.parse(JSON.stringify(memberProgram));
      memberProgramClone.score = parseInt(score);
      dispatch(storeMemberProgram(memberProgramClone));
      setUpdateScoreShow(false);
    } catch (error) {
      Log.error('Error : LectureList/updateScore :', error);
    }
  };

  const setFirstColClass = (index) => {
    try {
      if (column === 1 && index === active_first) {
        return 'active target-active';
      } else if (column === 2 && index === active_second) {
        return 'active target-active';
      } else if (column === 3 && index === active_third) {
        return 'active target-active';
      }
      /*
      if (column === 1 && index === active_first) {
        return 'active target-active';
      } else if (column != 1 && index === active_first) {
        return 'active';
      } else {
        return '';
      }
      */
    } catch (error) {
      Log.error('Error : LectureList/setFirstColClass :', error);
    }
  };

  const observationHandler = (item) => {
    try {
      setLectureObservationShow(true);
      setSelectedSection(item);
    } catch (error) {
      Log.error('Error : LectureList/observationHandler :', error);
    }
  };
  const init = async () => {
    try {
      let apiStatus = true;
      let memberDetail = await viewMemberProgram({ memberProgramId: programId });

      dispatch(storeMemberProgram({ ...memberDetail.data.data }));

      if (apiStatus) {
        //resetIndex();
        setComponentId(decryptData(sectionid));
        calculateProgress();
        fetchAllFeedbacks();

        if (!ls.get('dmstoken') || ls.get('dmstoken') === 'undefined') {
          fetchDMSToken();
        }

        if (isLearner) {
          const element = document.querySelector('main');
          element.classList.remove('main-wrapper');
        } else {
          fetchMemberData();
        }
      }
    } catch (err) {
      Log.error('Error : LectureList/init :', err);
    }
  };
  useEffect(() => {
    init();
  }, [id, programId, sectionid]);

  useEffect(() => {
    if (!isLearner) {
      dispatch(setSidebarData({ enableActiveWrapper: true }));
      return () => {
        dispatch(setSidebarData({ enableActiveWrapper: false }));
      };
    }
  }, [dispatch, !isLearner]);
  useEffect(() => {
    if (!isLearner) {
      if (!enableActiveWrapper) {
        setTimeout(() => {
          dispatch(setSidebarData({ enableActiveWrapper: true }));
        }, 1);
      }
    }
  }, [enableActiveWrapper]);

  useEffect(() => {
    if (Object.entries(taxonomy).length === 0) {
      setTaxonomyData();
    }
  }, [taxonomy]);

  useEffect(() => {
    if (showToastRef.current && memberProgram?.program?.notes) {
      setShowToast(true);
      showToastRef.current = false;
    }
  }, [memberProgram]);

  const verifyMeberProgramIQV = async () => {
    try {
      setIqvVerifyLoading(true);
      const result = await iqvVerifyMemberProgram(programId);
      if (result) {
        dispatch(storeMemberProgram(result?.data?.data));
        dispatch(setToastBoxDescription(result?.data?.message));
        dispatch(setToastBoxStatus(true));
      }
    } catch (error) {
      Log.error('Error', error);
    } finally {
      setIqvVerifyLoading(false);
    }
  };

  const verifyMeberProgramEQV = async () => {
    try {
      setIqvVerifyLoading(true);
      const result = await eqvVerifyMemberProgram(programId);
      if (result) {
        dispatch(storeMemberProgram(result?.data?.data));
        dispatch(setToastBoxDescription(result?.data?.message));
        dispatch(setToastBoxStatus(true));
      }
    } catch (error) {
      Log.error('Error', error);
    } finally {
      setIqvVerifyLoading(false);
    }
  };

  const lockandCompleteMemberProgram = async () => {
    try {
      setIqvVerifyLoading(true);
      const result = await lockCompleteMemberProgram(programId);
      if (result) {
        dispatch(storeMemberProgram(result?.data?.data));
        dispatch(setToastBoxDescription(result?.data?.message));
        dispatch(setToastBoxStatus(true));
      }
    } catch (error) {
      Log.error('Error', error);
    } finally {
      setIqvVerifyLoading(false);
    }
  };

  const releaseCertificateMemberProgram = async () => {
    try {
      setCertificateLoading(true);
      const result = await releaseCertificateToMemberProgram(programId);
      if (result) {
        dispatch(storeMemberProgram(result?.data?.data));
        dispatch(setToastBoxDescription(result?.data?.message));
        dispatch(setToastBoxStatus(true));
      }
    } catch (error) {
      Log.error('Error', error);
    } finally {
      setCertificateLoading(false);
    }
  };

  const rowHighlight = (componentId) => {
    try {
      let allCell = document.querySelectorAll('div.rgt-cell');
      // Remove all active class
      let j = 0;
      while (j < allCell.length) {
        allCell[j].classList.remove('grid-row-link-active');
        j++;
      }
      // Set active class

      let selectedCell = document.querySelectorAll('div[data-row-id="' + componentId + '"]');

      let i = 0;
      while (i < selectedCell.length) {
        selectedCell[i].classList.add('grid-row-link-active');
        i++;
      }
    } catch (err) {
      Log.error(err);
    }
  };
  const updateFirstCol = (member_section) => {
    try {
      let cloneFirstCol = JSON.parse(JSON.stringify(firstCol));
      let obj = cloneFirstCol.find((item) => item.id === member_section.id);
      let index = cloneFirstCol.indexOf(obj);
      cloneFirstCol[index].status = member_section.status;
      dispatch(setFirstCol(cloneFirstCol));
    } catch (error) {
      Error.log('Error on updateFirstCol :', error);
    }
  };
  const updateComponent = (changedData) => {
    try {
      let member_section = changedData?.member_section ? changedData.member_section : changedData;
      let cloneActiveComponent = JSON.parse(JSON.stringify(selectedComponent));
      cloneActiveComponent.status = member_section.status;
      dispatch(setActiveSection(cloneActiveComponent));
      // let dataClone = JSON.parse(JSON.stringify(sections));
      // let id = changedData?.id ? changedData.id : changedData?.member_section?.id;
      // let obj = dataClone.find((item) => item?.id === id);
      // let index = dataClone.indexOf(obj);
      // obj.status = changedData.status ? changedData.status : changedData?.member_section?.status;
      // dataClone[index] = obj;
      updateFirstCol(member_section);

      // dispatch(storeSections([]));
      // setTimeout(() => {
      //   dispatch(storeSections(dataClone));
      // }, 100);

      setTimeout(() => {
        rowHighlight(member_section?.id);
      }, 500);
    } catch (err) {
      Log.error(err);
    }
  };
  const updateSections = (data) => {
    dispatch(storeSections([]));
    setTimeout(() => {
      dispatch(storeSections([...data]));
    }, 200);

    setTimeout(() => {
      rowHighlight();
    }, 500);
  };

  const fetchParentData = async (data) => {
    try {
      dispatch(setFirstCol([]));
      if (data.section?.parent_section) {
        var result = await getParentData({ member_section: data?.id, parent_section: data.section?.parent_section });
        setLoading('loaded');
        dispatch(setFirstCol(result.data.data.member_sections));
      } else {
        const payload = {
          memberProgramId: programId,
        };
        var result = await getFirstColSection(payload);
        setLoading('loaded');
        dispatch(setFirstCol(result.data.data.member_sections));
      }
    } catch (error) {
      setLoading('loaded');
      Log.error('Error on fetchParentData :', error);
    }
  };
  const goBack = () => {
    try {
      let toplocation = window.location.pathname;
      let txt = 'lecture-list';
      let lectureSplit = toplocation.split(txt);
      navigate(lectureSplit[0] + txt, { state: { update: true, hideToast: state?.hideToast } });
    } catch (error) {
      Log.error('Error on goBack :', error);
    }
  };
  const fetchParent = (data) => {
    try {
      let parentid = data?.section?.parent_section;
      if (parentid !== parentId) {
        setParentId(data.section.parent_section);
        fetchParentData(data);
      }
    } catch (error) {
      Log.error('Error on fetchParent :', error);
    }
  };
  // useEffect(() => {
  //   if (sectionid) {
  //     console.log('I got componetn ID');
  //     fetchSectionDetail(decryptData(sectionid));
  //   }
  //   // decryptData(sectionid)
  // }, [sectionid]);
  return (
    <>
      {isLearner && (
        <>
          <HorizontalNavLinkList navKey="link-2" />

          {
            <SecondaryHeader>
              <div className="program-title-ellipsis d-flex flex-wrap justify-content-end w-100">
                <div className="secondary-header-wrap lecture-header-wrap">
                  <div className="info-wrap">
                    <h3 title={memberProgram?.program?.name ? memberProgram?.program?.name : ''}>{memberProgram?.program?.name ? memberProgram?.program?.name : ''}</h3>
                  </div>
                </div>

                <div className="button-wrap gap-4">
                  {memberProgram?.program && (
                    <>
                      {memberProgram?.program?.score > 0 && (
                        <div className="update-score-link">
                          <span className="update-score-icon">{scoreTargetIcon}</span>
                          {`${memberProgram?.score}/${memberProgram.program?.score}`}
                        </div>
                      )}
                      <span className="separator"></span>
                      <div className="progressbar-holder">
                        {(memberProgram.completed_progress >= 0 || memberProgram.submitted_progress >= 0) && (
                          <ProgressWrap>
                            {' '}
                            <ProgressValue
                              ProgressPercentage={`${memberProgram.program?.progress_calculated_on === 'completion' ? memberProgram.completed_progress : memberProgram.submitted_progress}%`}
                            />{' '}
                            &nbsp;
                            <ProgressBar
                              variant={setPercentageVariant(memberProgram.program?.progress_calculated_on === 'completion' ? memberProgram.completed_progress : memberProgram.submitted_progress)}
                              now={memberProgram.program?.progress_calculated_on === 'completion' ? memberProgram.completed_progress : memberProgram.submitted_progress}
                            />{' '}
                          </ProgressWrap>
                        )}
                      </div>
                      <span className="separator"></span>
                      {memberProgram?.status === 'Not Started' && <Badge className="badge dark">{memberProgram?.status}</Badge>}
                      {memberProgram?.status === 'In Progress' && <Badge className="badge blue">{memberProgram?.status}</Badge>}
                      {memberProgram?.status === 'Completed' && memberProgram?.is_verified_by_iqv?.is_verified && !memberProgram?.is_verified_by_eqv?.is_verified && (
                        <Badge className="badge">{iqvName} Verified</Badge>
                      )}
                      {memberProgram?.status === 'Completed' && memberProgram?.is_verified_by_eqv?.is_verified && <Badge className="badge">{eqvName} Verified</Badge>}
                      {memberProgram?.status === 'Completed' && !memberProgram?.is_verified_by_iqv?.is_verified && <Badge className="badge">{memberProgram?.status}</Badge>}
                      {memberProgram?.status === 'Submitted' && <Badge className="badge warning">{memberProgram?.status}</Badge>}
                      <span className="separator"></span>
                      {memberProgram?.status !== 'Completed' && (
                        <Button
                          variant="leading-icon"
                          className="leading-icon add-target-btn m-0"
                          type="button"
                          onClick={() => {
                            setAddProgramTargetShow(true);
                            setAddTargetShow(false);
                          }}
                        >
                          {addIcon} {`Add ${translate(taxonomy)('target')}`}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </SecondaryHeader>
          }
          <LearnerMainSidebar />
          {hideToast === false && (
            <ToastContainer className="p-3 preview-toast-wrap">
              <Toast
                variant="primary"
                show={showToast}
                onClose={toggleToast}
              >
                <Toast.Body>
                  <ToastInfo toastDescription={getPlainTextFromHtmlText(memberProgram?.program?.notes)} />
                  <div className="action-wrapper">
                    <Button
                      variant="primary"
                      type="button"
                      size="sm"
                      className="btn-round bg-primary-fade"
                      onClick={() => setHideToast(true)}
                      aria-label="Close"
                    >
                      Close
                    </Button>
                  </div>
                </Toast.Body>
              </Toast>
            </ToastContainer>
          )}
        </>
      )}
      <div className="content-wrapper pb-0">
        {isLearner && memberProgram?.program?.description ? (
          <div className="intro-section">
            <DescriptionSeeMore content={memberProgram?.program?.description} />
          </div>
        ) : (
          ''
        )}
        {!isLearner && (
          <>
            {
              <SecondaryHeader>
                <div className="secondary-header-wrap align-items-center">
                  <CircleImg
                    src={member?.profile_image}
                    defaultIcon={getUserIconFromRoleIdentifier('learner')}
                    title={member?.name}
                  />

                  <SecondaryHeaderInfo
                    SecondaryHeaderTitle={`${member?.first_name ? member?.first_name : ''} ${member?.last_name ? member?.last_name : ''}`}
                    SecondaryHeaderText={member.decrypted_email}
                    SecondaryText
                  />
                </div>

                <div className="button-wrap gap-4">
                  {memberProgram && (
                    <>
                      {lockMemberProgram && (
                        <Button
                          variant="outline"
                          className="dark"
                          onClick={lockandCompleteMemberProgram}
                        >
                          {iqvVerifyLoading && (
                            <>
                              <Spinner
                                animation="border"
                                size="sm"
                              />{' '}
                              &nbsp; &nbsp;
                            </>
                          )}
                          Lock and Mark as Complete
                        </Button>
                      )}
                      {releaseCertificate && (
                        <Button
                          variant="outline"
                          className="dark"
                          onClick={releaseCertificateMemberProgram}
                        >
                          {certificateLoading && (
                            <>
                              <Spinner
                                animation="border"
                                size="sm"
                              />{' '}
                              &nbsp; &nbsp;
                            </>
                          )}
                          Release Certificate
                        </Button>
                      )}
                      {isEQV && memberProgram?.is_verified_by_iqv?.is_verified && !memberProgram?.is_verified_by_eqv?.is_verified && memberProgram?.status === 'Completed' && (
                        <Button
                          variant="outline"
                          className="dark"
                          onClick={verifyMeberProgramEQV}
                        >
                          {iqvVerifyLoading && (
                            <>
                              <Spinner
                                animation="border"
                                size="sm"
                              />{' '}
                              &nbsp; &nbsp;
                            </>
                          )}
                          {eqvName} Verify
                        </Button>
                      )}
                      {isIQV && !memberProgram?.is_verified_by_iqv?.is_verified && memberProgram?.status === 'Completed' && (
                        <Button
                          variant="outline"
                          className="dark"
                          onClick={verifyMeberProgramIQV}
                        >
                          {iqvVerifyLoading && (
                            <>
                              <Spinner
                                animation="border"
                                size="sm"
                              />{' '}
                              &nbsp; &nbsp;
                            </>
                          )}
                          {iqvName} Verify
                        </Button>
                      )}
                      {memberProgram.program?.score > 0 && (
                        <span
                          className="update-score-link"
                          onClick={handleUpdateScore}
                        >
                          {`${memberProgram?.score}/${memberProgram.program?.score}`}
                        </span>
                      )}
                      {(memberProgram.completed_progress >= 0 || memberProgram.submitted_progress >= 0) && (
                        <ProgressWrap>
                          <ProgressValue
                            ProgressPercentage={`${memberProgram.program?.progress_calculated_on === 'completion' ? memberProgram.completed_progress : memberProgram.submitted_progress}%`}
                          />
                          &nbsp;
                          <ProgressBar
                            variant={setPercentageVariant(memberProgram.program?.progress_calculated_on === 'completion' ? memberProgram.completed_progress : memberProgram.submitted_progress)}
                            now={memberProgram.program?.progress_calculated_on === 'completion' ? memberProgram.completed_progress : memberProgram.submitted_progress}
                          />
                        </ProgressWrap>
                      )}

                      <AddScoreOffCanvas
                        show={updateScoreShow}
                        onClose={() => setUpdateScoreShow(false)}
                        memberProgram={memberProgram}
                        onUpdate={updateScore}
                      />
                    </>
                  )}
                  {memberProgram?.status === 'Not Started' && <Badge className="badge dark">{memberProgram?.status}</Badge>}
                  {memberProgram?.status === 'In Progress' && <Badge className="badge blue">{memberProgram?.status}</Badge>}
                  {memberProgram?.status === 'Completed' && memberProgram?.is_verified_by_iqv?.is_verified && !memberProgram?.is_verified_by_eqv?.is_verified && (
                    <Badge className="badge">{iqvName} Verified</Badge>
                  )}
                  {memberProgram?.status === 'Completed' && memberProgram?.is_verified_by_eqv?.is_verified && <Badge className="badge">{eqvName} Verified</Badge>}
                  {memberProgram?.status === 'Completed' && !memberProgram?.is_verified_by_iqv?.is_verified && <Badge className="badge">{memberProgram?.status}</Badge>}
                  {memberProgram?.status === 'Submitted' && <Badge className="badge warning">{memberProgram?.status}</Badge>}

                  {((!isLearner && can_create_smart_target) || isLearner) && memberProgram?.status !== 'Completed' && (
                    <Button
                      variant="leading-icon"
                      className="leading-icon add-target-btn m-0"
                      type="button"
                      onClick={() => {
                        setAddProgramTargetShow(true);
                        setAddTargetShow(false);
                      }}
                    >
                      {addIcon} {`Add ${translate(taxonomy)('target')}`}
                    </Button>
                  )}
                </div>
              </SecondaryHeader>
            }
            {learnerListCheck ? <DelegateFilterSidebar /> : <LearnerRolesFilterSidebar backpage={backpage} />}
          </>
        )}
        {
          <div className="content-block bg-transparent p-0 mb-0">
            <div className="program-title-ellipsis">
              {!isLearner && (
                <h2
                  className="program-list-title"
                  title={memberProgram?.program?.name}
                >
                  {memberProgram?.program?.name}
                </h2>
              )}
            </div>
            <Row>
              <Col
                xl={4}
                lg={6}
                className="mb-4"
              >
                <Card className="border-0 lecture-list-overflow-scroll">
                  <Card.Body>
                    {loading === 'loading' && (
                      <div className="spinner-middle">
                        <Spinner animation="border" />
                      </div>
                    )}
                    {firstCol.length > 0 &&
                      firstCol.map((member_section, index) => {
                        return (
                          <div key={index}>
                            <ProgramList
                              activeId={componentId}
                              data={member_section}
                              programInfo={memberProgram?.program}
                              column={column}
                              className={setFirstColClass(index)}
                              dotCircle
                              id={member_section.id}
                              iconName={member_section?.section.section_type ? member_section?.section.section_type : 'Section'}
                              title={member_section?.section?.title}
                              sub_title={member_section?.section?.sub_title}
                              type={member_section?.section.section_type}
                              component={member_section?.section.section_type}
                              weightagePercentage={member_section?.section?.weightage}
                              weightageStatus={memberProgram?.program?.weightage}
                              progressValue={member_section.status}
                              target={upCommingTargets(member_section.targets)}
                              programName={memberProgram?.program?.name}
                              observationButton="Observation"
                              onObservationClick={() => {
                                observationHandler(member_section);
                              }}
                              addTargetButton={`Add ${translate(taxonomy)('target')}`}
                              onButtonClick={() => {
                                setAddTargetShow(true);
                                setAddProgramTargetShow(false);
                              }}
                              onClick={() => {
                                firstColHandler(index, member_section);
                              }}
                            />
                            <hr className="program-list-divider" />
                          </div>
                        );
                      })}
                  </Card.Body>
                </Card>
              </Col>
              {subPage === 'lectureDetail' && (
                <>
                  {componentId && (
                    <>
                      <Col
                        md={8}
                        xl={8}
                        lg={6}
                      >
                        <SelectedComponent
                          onChange={updateComponent}
                          onUpdateSections={updateSections}
                          id={componentId}
                          backStatus={backBtnStatus}
                          onBack={() => goBack()}
                          onSectionDetail={fetchParent}
                        />
                      </Col>
                    </>
                  )}
                </>
              )}

              {subPage === 'activityDetail' && <ActivityDetail onLoadSubPage={(data) => loadSubPageHandler(data)} />}
            </Row>
          </div>
        }
      </div>

      {lectureObservationShow && (
        <LectureObservationOffCanvas
          onChange={(data) => Log.info('onchange:', data)}
          data={selectedSection}
          show={lectureObservationShow}
          onClose={() => setLectureObservationShow(false)}
          column={column}
        />
      )}
      {addTargetShow && (
        <AddSectionTargetOffCanvas
          show={addTargetShow}
          onClose={() => setAddTargetShow(false)}
          displayMsg={(msg) => displayToaster(msg)}
          programName={memberProgram?.program?.name}
        />
      )}

      {addProgramTargetShow && (
        <AddProgramTargetOffCanvas
          show={addProgramTargetShow}
          onClose={() => setAddProgramTargetShow(false)}
          displayMsg={(msg) => displayToaster(msg)}
          programDetails={memberProgram}
          updatedTargets={(program) => dispatch(storeMemberProgram(program))}
        />
      )}

      <ToastBox />
    </>
  );
}

export default memo(ComponentDetail);
