import { memo } from 'react';
// import Log from 'lib/Log';
import InputSingleLine from './InputSingleLine';
import InputDate from './InputDate';

const ExamPreview = ({ selectedComponent }) => {
  return selectedComponent?.custom_fields && selectedComponent?.custom_fields?.length > 0 ? (
    <>
      {selectedComponent?.custom_fields.map((item, index) => {
        switch (item.type) {
          case 'Number':
            return (
              <InputSingleLine
                className="py-2"
                data={item}
                number={index + 1}
                type="number"
              />
            );
          case 'Input Text':
            return (
              <InputSingleLine
                className="py-2"
                data={item}
                number={index + 1}
                type="text"
              />
            );
          case 'Date':
            return (
              <InputDate
                className="py-2"
                data={item}
                number={index + 1}
              />
            );
        }
      })}
    </>
  ) : (
    ''
  );
};

export default memo(ExamPreview);
