/*
Use the StatusLabel component for the following statuses:
- "Disabled"
- "Approved"
- "Rejected"
- "Awaiting"
- "Pending"
*/

import React from 'react';

function StatusLabel({ status, labelText }) {
  const statusClass = `status-label status-${status.toLowerCase()}`;

  return <span className={statusClass}>{labelText}</span>;
}

export default StatusLabel;
