import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { setSidebarData } from '../../../reducers/sidebarSlice';
// import Log from 'lib/Log';
import { useLocation } from 'react-router-dom';
import ls from 'localstorage-slim';
import { Button } from 'react-myoneui';
import StatusLabel from 'modules/admin/wage/components/statusLabel';

ls.config.encrypt = true;

const EditEvent = ({ memberEventStatus, setMemberEventStatus, component_status }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const role = ls.get('role');
  const {
    identifier,
    privileges: { can_edit_event, can_manage_apprentices },
  } = JSON.parse(ls.get('activeRole'));
  const pathRole = pathname.split('/')[1];

  const isValidLearner = role === 'learner' && pathRole === 'learner' && identifier === 'apprentice' && can_edit_event;
  const isValidForEdit = isValidLearner || (identifier !== 'apprentice' && can_manage_apprentices);

  dispatch(setSidebarData({ enableActiveWrapper: true }));

  return (
    <>
      {component_status !== 'Completed' ? (
        <div>
          <p className="fw-bold fs-2 mb-0">Mark Attendees</p>
          <p className="help-text">Please verify attendees </p>
          <div className={`d-flex gap-4 ${!isValidForEdit && 'disabled'}`}>
            <Button
              variant="default"
              size="sm"
              className={`btn border-success ${memberEventStatus === 'present' && 'bg-secondary'}`}
              onClick={() => setMemberEventStatus('present')}
            >
              Present
            </Button>
            <Button
              variant="default"
              size="sm"
              className={`btn border-danger ${memberEventStatus === 'absent' && 'bg-danger'}`}
              onClick={() => setMemberEventStatus('absent')}
            >
              Absent
            </Button>
          </div>
        </div>
      ) : // (
      //   <StatusCol value={memberEventStatus} />
      // )
      memberEventStatus === 'present' ? (
        <StatusLabel
          status="approved"
          labelText="Present"
        />
      ) : (
        <StatusLabel
          status="rejected"
          labelText="Absent"
        />
      )}
    </>
  );
};

export default memo(EditEvent);
