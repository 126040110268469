import React, { forwardRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import Reminders from './Reminders';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getLearnerProgramUpcomingTargetsAndDeadlines } from 'api/learner/dashboard';
import Spinner from 'react-myoneui/lib/Spinner';
import Log from 'lib/Log';
import BasicContentLoader from 'components/basicContentLoader';
const RemindersSlider = forwardRef(({ onSlideChange, onTotalSlidesUpdate, setReminderCount }, ref) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    try {
      setIsLoading(true);
      const result = await getLearnerProgramUpcomingTargetsAndDeadlines();
      if (result?.data?.data) {
        setReminderCount(result?.data?.data?.length);
        const transformedData = result?.data?.data.map((item) => ({
          imgSrc: item.programImage,
          deadline: item.targetDate,
          description: item.targetDescription.replace(/<\/?[^>]+(>|$)/g, ''),
          viewDetailsLink: `/learner/program/${item.memberProgramId}/lecture-list`,
        }));
        setData(transformedData);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Log.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    afterChange: (current) => onSlideChange(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    onTotalSlidesUpdate(data.length);
  }, [data.length, onTotalSlidesUpdate]);

  return (
    <Slider
      ref={ref}
      {...settings}
      className="reminders-slider"
    >
      {isLoading ? (
        // <div className="spinner-middle w-100 h-25">
        //   <Spinner animation="border" />
        // </div>
        <BasicContentLoader />
      ) : (
        data.map((reminder, index) => (
          <div key={index}>
            <Reminders
              imgSrc={reminder.imgSrc}
              deadline={reminder.deadline}
              description={reminder.description}
              viewDetailsLink={reminder.viewDetailsLink}
            />
          </div>
        ))
      )}
    </Slider>
  );
});

export default RemindersSlider;
