import { memo } from 'react';
import { Form, Col } from 'react-myoneui';
import { useSelector, useDispatch } from 'react-redux';
import { updateValue } from 'redux/ExternalExamDataSlice';

function InputSingleLine({ data, mode, index, type, isLearner }) {
  const dispatch = useDispatch();

  const state = useSelector((state) => state?.externalExamData?.data?.find((item) => item?._id === data?._id));

  const handleChange = (e) => {
    dispatch(updateValue({ id: data?._id, value: e.target.value }));
  };

  return (
    <>
      <Col
        className={`py-2 ${isLearner || mode === 'preview' || mode === 'readonly' ? 'disabled' : ''} `}
        // md={state?.col_width}
        name="formElement"
      >
        <Form.Label
          htmlFor={data?.title}
          className="form-builder-label"
        >
          {index + 1}. {data?.label}
        </Form.Label>
        <Form.Control
          type={type}
          aria-describedby="inputBlock"
          placeholder={data?.label}
          value={state?.value}
          onChange={handleChange}
        />
        <p className="help-text ms-2">{data?.helper_text}</p>
      </Col>
    </>
  );
}
export default memo(InputSingleLine);
