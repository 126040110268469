import CircleRadiusChart from './CircleRadiusChart';
import { useState, useEffect } from 'react';
import { getLearnerProgramEnrollmentStatus } from 'api/learner/dashboard';
import Spinner from 'react-myoneui/lib/Spinner';
import BasicContentLoader from 'components/basicContentLoader';
import Log from 'lib/Log';

export default function LearningProgress() {
  const [activeProgram, setActiveProgram] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    try {
      setIsLoading(true);
      const result = await getLearnerProgramEnrollmentStatus();
      if (result.data.data) {
        setActiveProgram(result.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      Log.error(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="learning-progress-content">
      {isLoading ? (
        // <div className="spinner-middle w-100 h-25">
        //   <Spinner animation="border" />
        // </div>
        <BasicContentLoader />
      ) : (
        <CircleRadiusChart
          requestedMonths={6}
          data={activeProgram}
        />
      )}
    </div>
  );
}
